import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router';
import ProductCreatingPage from './ProductCreatingPage';
import ProductDetailPage from './ProductDetailPage';
import ProductsPage from './ProductsPage';
import ProductUpdatingPage from './ProductUpdatingPage';

export default function ProductRoutingPage() {

    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <ProductsPage />
            </Route>
            <Route exact path={`${path}/new`}>
                <ProductCreatingPage />
            </Route>
            <Route exact path={`${path}/edit/:id`}>
                <ProductUpdatingPage />
            </Route>
            <Route path={`${path}/:id`}>
                <ProductDetailPage />
            </Route>
        </Switch>
    )
}
