import React, { useEffect, useState } from 'react';
import { getCustomers } from '../../api/customer';
import { CustomerOutputDto, GetCustomersResult } from '../../models/customer';
import { UserAuthDto } from '../../models/user';
import CustomerQueryParameters from '../../queryParameters/CustomerQueryParameters';
import useInfiniteScroll from '../useInfiniteScroll';

export interface UseCustomerSearchingReturn {
	customers: CustomerOutputDto[];
	errorMessage?: string;
	isInit: boolean;
	isLoading: boolean;
	hasMore: boolean;
	lastRef: any;
}

export default function useCustomersSearching(
	userAuth: UserAuthDto | undefined,
	queryParams: CustomerQueryParameters,
	pageSize: number = 16,
) {
	const [isLoading, setIsLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState<string | undefined>();
	const [customers, setCustomers] = useState<CustomerOutputDto[]>([]);
	const [isInit, setIsInit] = useState<boolean>(false);
	const [hasMore, setHasMore] = useState<boolean>(false);
	const [pageNumber, setPageNumber] = useState<number>(1);

	useEffect(() => {
		// Refresh
		if (isInit) {
			setIsInit(false);
			setCustomers([]);
			setPageNumber(1);
			loadData();
		}
	}, [queryParams]);

	useEffect(() => {
		// Pagination
		if (isInit) {
			loadData();
		}
	}, [pageNumber, pageSize]);

	useEffect(() => {
		// OnLoad
		loadData();
	}, []);

	const loadData = async () => {
		// Need the user to login to perform this.
		if (!userAuth) {
			setErrorMessage('必須先登入才可執行此操作!');
			return;
		}

		try {
			setIsLoading(true);
			setErrorMessage(undefined);

			const result: GetCustomersResult = await getCustomers(
				userAuth,
				queryParams,
				pageNumber,
				pageSize,
			);

			setCustomers((prev) => {
				return [
					...new Set([
						...prev,
						...result.customers.filter(
							(i) => !prev.some((prev_i) => prev_i.id == i.id),
						),
					]),
				];
			});

			setHasMore(result.pagination.hasNext);
			setIsLoading(false);
			setIsInit(true);
		} catch (e) {
			setErrorMessage((e as Error).message);
		}
	};

	const lastRef = useInfiniteScroll(
		() => setPageNumber((prev) => prev + 1),
		isLoading,
		hasMore,
	);

	// Q: should we pass the has more to outside?
	return {
		isLoading,
		errorMessage,
		customers,
		hasMore,
		isInit,
		lastRef,
	} as UseCustomerSearchingReturn;
}
