import {
	faBoxOpen,
	faEdit,
	faInfoCircle,
	faMoneyBillWave,
	faShippingFast,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Collapse, List, message, Row, Space, Switch } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { updateOrderStatus } from '../../api/order';
import ErrorMessageAlert from '../../components/common/ErrorMessageAlert';
import OrderSearchingBar from '../../components/order/OrderSearchingBar';
import { useAppSelector } from '../../hooks/app';
import useOrdersInfoLoad from '../../hooks/order/useOrdersInfoLoad';
import useOrdersSearching from '../../hooks/order/useOrdersSearching';
import {
	OrderOutputDto,
	OrderStatusColors,
	OrderStatusUpdateDto,
} from '../../models/order';
import { ProductOutputDto } from '../../models/product';
import OrderQueryParameters, {
	OrderSortingOptions,
} from '../../queryParameters/OrderQueryParameters';
import user, { selectUserAuth } from '../../store/user';

const initQueryParams = new OrderQueryParameters({
	orderBy: { option: OrderSortingOptions.OrderNum, desc: true },
});

export default function OrdersPage() {
	const location = useLocation();

	const locationState = location.state as OrderQueryParameters;

	const [errorMessage, setErrorMessage] = useState<string | undefined>();

	const [productFloat, setProductFloat] = useState<boolean>(true);

	const [showDetailInfo, setShowDetailInfo] = useState<boolean>(false);

	const history = useHistory();

	const screen = useBreakpoint();

	const userAuth = useAppSelector(selectUserAuth);

	const [queryParams, setQueryParams] = useState<OrderQueryParameters>(
		new OrderQueryParameters({
			...initQueryParams,
			...locationState,
		}),
	);

	const ordersResult = useOrdersSearching(userAuth, queryParams);
	const ordersInfo = useOrdersInfoLoad(userAuth, queryParams);


	useEffect(() => {
		console.log(queryParams);
	}, [queryParams])

	const updateStatus = async (
		statusUpdatingDto: OrderStatusUpdateDto,
		orderId: string,
	): Promise<OrderOutputDto | null> => {
		setErrorMessage(undefined);

		if (!userAuth) {
			setErrorMessage('必須登入才可執行此操作');
			return null;
		}

		if (!ordersResult.orders.find((o) => o.id == orderId)) {
			setErrorMessage('查無此訂單');
			return null;
		}

		try {
			const updatedOrder = await updateOrderStatus(
				userAuth,
				orderId,
				statusUpdatingDto,
			);
			ordersResult.orderStatusUpdate(updatedOrder);
			return updatedOrder;
		} catch (e: any) {
			console.log(e.detail);
			setErrorMessage(e.message);
			return null;
		}
	};

	// User need to login to see this page. (Before the userAuth has been used.)
	if (!userAuth) {
		return <Redirect to={'/login'} />;
	}

	const tableColumns: ColumnsType<OrderOutputDto> = [
		{
			title: '期',
			dataIndex: 'orderNum',
			key: 'orderNum',
			render: (text: string, record: OrderOutputDto, idx: number) => {
				return (
					<div
						ref={
							idx + 1 === ordersResult.orders.length
								? ordersResult.lastRef
								: undefined
						}
						key={record.id}
					>
						{text}
					</div>
				);
			},
			// ellipsis: true,
			width: '60px',
			align: 'center',
			fixed: productFloat ? 'left' : false,
		},

		{
			title: '買家 / 商品',
			key: 'belongCustomer.name',
			render: (text: string, record: OrderOutputDto, idx: number) => {
				return (
					<div>
						<div>
							<a
								onClick={() => {
									history.push({
										pathname: `/customers/${record.belongCustomerId}`,
									});
								}}
							>
								{record.belongCustomer?.name ?? '未知買家'}
							</a>
						</div>
						<div style={{ height: '15px' }}></div>
						<div>
							<a
								onClick={() => {
									history.push({
										pathname: `/products/${record.belongProductId}`,
									});
								}}
							>
								{record.belongProduct?.name ?? '未知商品'}
							</a>
						</div>
					</div>
				);
			},
			// ellipsis: true,
			// width: '140px',
			align: 'center',
			fixed: productFloat ? 'left' : false,
		},
		// {
		// 	title: '商品',
		// 	key: 'belongProduct.name',
		// 	render: (text: string, record: OrderOutputDto, idx: number) => {
		// 		return (
		// 			<a
		// 				onClick={() => {
		// 					history.push({
		// 						pathname: `/products/${record.belongProductId}`,
		// 					});
		// 				}}
		// 			>
		// 				{record.belongProduct?.name ?? '未知商品'}
		// 			</a>
		// 		);
		// 	},
		// 	ellipsis: true,
		// 	align: 'center',
		// },

		{
			title: `單價 (${userAuth.user.sellCurrency}) / 數量`,
			key: 'quantity&SellPrice',
			// ellipsis: true,
			width: '110px',
			align: 'center',
			render: (text: string, record: OrderOutputDto, idx: number) => {
				return (
					<div>
						<div>{record.sellPrice}</div>
						<div>{record.quantity}</div>
					</div>
				);
			},
		},
		// {
		// 	title: `單價 (${userAuth.user.sellCurrency})`,
		// 	dataIndex: 'sellPrice',
		// 	key: 'sellPrice',
		// 	ellipsis: true,
		// 	width: '120px',
		// 	align: 'center',
		// },
		// {
		// 	title: `總價 (${userAuth.user.sellCurrency})`,
		// 	ellipsis: true,
		// 	width: '120px',
		// 	align: 'center',
		// 	render: (text: string, record: OrderOutputDto, index: number) => {
		// 		return <div>{record.sellPrice * record.quantity}</div>;
		// 	},
		// },
		{
			title: '狀態',
			key: 'status',
			render: (text: string, record: OrderOutputDto, index: number) => {
				return (
					<div>
						<Space direction="horizontal">
							<Button
								icon={
									<FontAwesomeIcon
										icon={faBoxOpen}
										color={
											record.prepared
												? OrderStatusColors.green
												: (record.belongProduct
														?.inStockNum ?? 0) >=
												  record.quantity
												? OrderStatusColors.yellow
												: OrderStatusColors.red
										}
									/>
								}
								onClick={async () => {
									if (
										record.prepared != true &&
										(record.belongProduct?.inStockNum ??
											0) < record.quantity
									) {
										message.error(
											`庫存不足: ${
												record?.belongProduct?.name ??
												'未知商品'
											}`,
										);
										return;
									}

									const updatedOrder = await updateStatus(
										{
											prepared: !record.prepared,
										} as OrderStatusUpdateDto,
										record.id,
									);
									message.success(
										`準備狀態更新為: ${
											updatedOrder?.prepared
												? '已準備'
												: '未準備'
										}!`,
									);
								}}
							/>
							<Button
								icon={
									<FontAwesomeIcon
										icon={faMoneyBillWave}
										color={
											record.paid
												? OrderStatusColors.green
												: OrderStatusColors.red
										}
									/>
								}
								onClick={async () => {
									const updatedOrder = await updateStatus(
										{
											paid: !record.paid,
										} as OrderStatusUpdateDto,
										record.id,
									);
									message.success(
										`付款狀態更新為: ${
											updatedOrder?.paid
												? '已付款'
												: '未付款'
										}!`,
									);
								}}
							/>
							<Button
								icon={
									<FontAwesomeIcon
										icon={faShippingFast}
										color={
											record.delivered
												? OrderStatusColors.green
												: OrderStatusColors.red
										}
									/>
								}
								onClick={async () => {
									const updatedOrder = await updateStatus(
										{
											delivered: !record.delivered,
										} as OrderStatusUpdateDto,
										record.id,
									);
									message.success(
										`配送狀態更新為: ${
											updatedOrder?.delivered
												? '已配送'
												: '未配送'
										}!`,
									);
								}}
							/>
						</Space>
					</div>
				);
			},
			// ellipsis: true,
			width: '140px',
			align: 'center',
		},
		{
			title: '詳',
			render: (text: string, record: OrderOutputDto, idx: number) => {
				return (
					<Button
						icon={
							<FontAwesomeIcon
								icon={faInfoCircle}
								color="#5ac8db"
							/>
						}
						onClick={() => {
							history.push(`/orders/${record.id}`);
						}}
					/>
				);
			},
			width: '60px',
			align: 'center',
		},
	];

	const copyAllProductList = () => {
		var orderProductList = ordersResult.orders.reduce(
			(prev: Object, o: OrderOutputDto) => {
				if (!o.belongProductId) {
					return prev;
				}

				if (prev.hasOwnProperty(o.belongProductId)) {
					(prev as any)[o.belongProductId].qty += 1;
				} else {
					(prev as any)[o.belongProductId] = {
						product: o.belongProduct,
						qty: 1,
					};
				}

				return prev;
			},
			{},
		);

		console.log(orderProductList);

		var allItemsString: string = '';

		Object.keys(orderProductList).forEach((k) => {
			allItemsString += `${
				((orderProductList as any)[k].product as ProductOutputDto).name
			} * ${(orderProductList as any)[k].qty}, `;
		});

		navigator.clipboard.writeText(allItemsString);
		message.success('訂單訊息已複製');
	};

	return (
		<div
			style={{
				margin: 'auto',
				width: screen.md ? '95%' : '95vw',
				marginTop: '8px',
			}}
		>
			<div
				style={{
					fontFamily: 'Noto Sans TC',
					fontWeight: 'bold',
					fontSize: '2em',
					textAlign: 'center',
					margin: '8px',
				}}
			>
				訂單
			</div>
			<div>
				<Row align="middle" justify="space-between">
					<Button
						style={{ fontWeight: 'bold', marginBottom: '8px' }}
						onClick={() => history.push('/orders/new')}
						icon={
							<FontAwesomeIcon
								icon={faEdit}
								style={{
									paddingRight: '2px',
								}}
							/>
						}
					>
						創建
					</Button>
					<Button
						style={{ fontWeight: 'bold', marginBottom: '8px' }}
						onClick={() => {
							setProductFloat((prev) => !prev);
						}}
					>
						{productFloat ? '取消浮動' : '浮動商品'}
					</Button>
				</Row>
				<div style={{ marginBottom: '8px' }}>
					<OrderSearchingBar
						setQueryParams={setQueryParams}
						initQueryParams={initQueryParams}
						queryParams={queryParams}
					/>
				</div>
			</div>

			<ErrorMessageAlert errorMessage={errorMessage} />
			<ErrorMessageAlert errorMessage={ordersResult.errorMessage} />
			<div>
				<Collapse
					defaultActiveKey={queryParams.belongCustomerId && ['1']}
					style={{ marginBottom: '8px' }}
				>
					<Collapse.Panel header="資訊" key="1">
						<List>
							<List.Item>
								<Row
									style={{ width: '100%' }}
									justify="end"
									align="middle"
								>
									<Switch
										onChange={(checked: boolean) =>
											setShowDetailInfo(checked)
										}
									/>
								</Row>
							</List.Item>
							<List.Item>
								<Row
									style={{ width: '100%' }}
									justify="space-between"
									align="middle"
								>
									<Col>
										<div>{`未付訂單數量`}</div>
									</Col>
									<Col>
										<div>
											{
												ordersInfo.ordersInfo
													?.unpaidOderCount
											}
										</div>
									</Col>
								</Row>
							</List.Item>
							<List.Item>
								<Row
									style={{ width: '100%' }}
									justify="space-between"
									align="middle"
								>
									<Col>
										<div>未付金額</div>
									</Col>
									<Col>
										<div>
											{
												ordersInfo.ordersInfo
													?.unpaidAmount
											}
										</div>
									</Col>
								</Row>
							</List.Item>
							{showDetailInfo && [
								<List.Item>
									<Row
										style={{ width: '100%' }}
										justify="space-between"
										align="middle"
									>
										<Col>
											<div>訂單數量</div>
										</Col>
										<Col>
											<div>
												{
													ordersInfo.ordersInfo
														?.totalOrderCount
												}
											</div>
										</Col>
									</Row>
								</List.Item>,
								<List.Item>
									<Row
										style={{ width: '100%' }}
										justify="space-between"
										align="middle"
									>
										<Col>
											<div>商品數量</div>
										</Col>
										<Col>
											<div>
												{
													ordersInfo.ordersInfo
														?.totalProductCount
												}
											</div>
										</Col>
									</Row>
								</List.Item>,
								<List.Item>
									<Row
										style={{ width: '100%' }}
										justify="space-between"
										align="middle"
									>
										<Col>
											<div>{`營收 (${userAuth.user.sellCurrency})`}</div>
										</Col>
										<Col>
											<div>
												{ordersInfo.ordersInfo?.totalSell?.toFixed(
													2,
												)}
											</div>
										</Col>
									</Row>
								</List.Item>,
								<List.Item>
									<Row
										style={{ width: '100%' }}
										justify="space-between"
										align="middle"
									>
										<Col>
											<div>{`營收 (${userAuth.user.buyCurrency})`}</div>
										</Col>
										<Col>
											<div>
												{(
													(ordersInfo.ordersInfo
														?.totalSell ?? 0) /
													userAuth.user
														.buyToSellExchangeRate
												).toFixed(2)}
											</div>
										</Col>
									</Row>
								</List.Item>,
								<List.Item>
									<Row
										style={{ width: '100%' }}
										justify="space-between"
										align="middle"
									>
										<Col>
											<div>{`估計營利 (${userAuth.user.sellCurrency})`}</div>
										</Col>
										<Col>
											<div>
												{ordersInfo.ordersInfo?.estimateProfit.toFixed(
													2,
												)}
											</div>
										</Col>
									</Row>
								</List.Item>,
								<List.Item>
									<Row
										style={{ width: '100%' }}
										justify="space-between"
										align="middle"
									>
										<Col>
											<div>{`估計營利 (${userAuth.user.buyCurrency})`}</div>
										</Col>
										<Col>
											<div>
												{(
													(ordersInfo.ordersInfo
														?.estimateProfit ?? 0) /
													userAuth.user
														.buyToSellExchangeRate
												).toFixed(2)}
											</div>
										</Col>
									</Row>
								</List.Item>,
								<List.Item>
									<Row
										style={{ width: '100%' }}
										justify="space-between"
										align="middle"
									>
										<Col>
											<div>{`未配送訂單數量`}</div>
										</Col>
										<Col>
											<div>
												{
													ordersInfo.ordersInfo
														?.undeliveredOrderCount
												}
											</div>
										</Col>
									</Row>
								</List.Item>,
								<List.Item>
									<Row
										style={{ width: '100%' }}
										justify="space-between"
										align="middle"
									>
										<Col>
											<div>{`未準備訂單數量`}</div>
										</Col>
										<Col>
											<div>
												{
													ordersInfo.ordersInfo
														?.unpreparedOrderCount
												}
											</div>
										</Col>
									</Row>
								</List.Item>,
								<List.Item>
									<Row
										style={{ width: '100%' }}
										justify="space-between"
										align="middle"
									>
										<Col>
											<div>{`估計未準備金額`}</div>
										</Col>
										<Col>
											<div>
												{
													ordersInfo.ordersInfo
														?.estimateUnpreparedAmount
												}
											</div>
										</Col>
									</Row>
								</List.Item>,
							]}
						</List>
					</Collapse.Panel>
				</Collapse>
				<Table
					scroll={{ x: 500 }}
					columns={tableColumns}
					dataSource={ordersResult.orders}
					pagination={false}
					loading={!ordersResult.isInit}
					rowKey={(o) => o.id}
				/>

				<div style={{ textAlign: 'center', marginTop: '16px' }}>
					<Button onClick={copyAllProductList}>複製訂單資訊</Button>
				</div>

				<div style={{ margin: '20px' }}></div>
			</div>
		</div>
	);
}
