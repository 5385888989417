import { faBoxOpen, faCopy, faEdit, faMoneyBillWave, faShippingFast, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, List, message, Popover, Row, Skeleton, Space } from 'antd';
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { deleteOrder, updateOrderStatus } from '../../api/order';
import AuthShow from '../../components/auth/AuthShow';
import ErrorMessageAlert from '../../components/common/ErrorMessageAlert';
import LoadingContainerShowingWrapper from '../../components/common/LoadingContainerShowingWrapper';
import LoadingShowSpinWrap from '../../components/common/LoadingShowSpinWrap';
import { useAppSelector } from '../../hooks/app';
import useOrderLoad from '../../hooks/order/useOrderLoad';
import { OrderOutputDto, OrderStatusColors, OrderStatusUpdateDto } from '../../models/order';
import { selectUserAuth } from '../../store/user';

export interface OrderDetailParams {
    id: string;
}




export default function OrderDetailPage() {
    const { id } = useParams<OrderDetailParams>();

    const history = useHistory();

    const userAuth = useAppSelector(selectUserAuth);

    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [deletingErrorMessage, setDeletingErrorMessage] = useState<string | undefined>();
    const orderResult = useOrderLoad(userAuth, id);
    const { order } = orderResult;

    const updateStatus = async (statusUpdatingDto: OrderStatusUpdateDto,): Promise<OrderOutputDto | null> => {
        if (!userAuth) {
            message.error("必須登入才可執行此操作");
            return null;
        }

        if (!order) {
            message.error("查無此訂單!")
            return null;
        }

        try {
            const updatedOrder = await updateOrderStatus(userAuth, order!.id, statusUpdatingDto);
            orderResult.setOrder(updatedOrder);
            return updatedOrder;
        } catch (e: any) {
            console.log(e.detail);
            message.error(e.message);
            return null;
        }

    }


    const deleteThisOrder = async () => {

        if (userAuth && orderResult.order?.id) {
            try {
                setIsDeleting(true);
                await deleteOrder(userAuth, orderResult.order.id);
                setIsDeleting(false);
                history.push("/orders");
            } catch (e) {
                setDeletingErrorMessage((e as Error).message);
                setIsDeleting(false);
            }
        }
    }
    return (
        <div style={{ padding: "16px" }}>
            <LoadingContainerShowingWrapper
                isInit={orderResult.isInit}
                isLoading={orderResult.isLoading}
                errorMessage={orderResult.errorMessage}
                loadingComponent={<Skeleton active paragraph={{ rows: 5, }} title={{ width: "80%" }} />}
            >
                <div >
                    <AuthShow>
                        <div >
                            <Row justify="end">
                                <Button icon={<FontAwesomeIcon icon={faEdit} />} type="default" onClick={() => history.push(`/orders/edit/${order!.id}`)} >編輯</Button>
                                <LoadingShowSpinWrap isLoading={isDeleting}>
                                    <Popover title="刪除此訂單?" trigger="click" placement="bottomLeft" content={<Button onClick={deleteThisOrder} >Yes</Button>} >
                                        <Button danger style={{ margin: "0 20px" }} icon={<FontAwesomeIcon icon={faTrash} />}>刪除</Button>
                                    </Popover>
                                </LoadingShowSpinWrap>
                            </Row>
                        </div>
                    </AuthShow>

                    <ErrorMessageAlert errorMessage={deletingErrorMessage} />
                    {
                        order &&

                        <List
                            style={{ marginTop: "16px", backgroundColor: "#ffffff" }}
                            bordered

                        >
                            <List.Item>
                                <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                    <Col>
                                        <div>期數</div>
                                    </Col>
                                    <Col>
                                        <div>
                                            {order?.orderNum}
                                        </div>
                                    </Col>
                                </Row>
                            </List.Item>

                            <List.Item
                            >
                                <Row align="middle" justify="space-between" style={{ width: "100%" }} >
                                    <Col>
                                        <div>訂單編號</div>
                                    </Col>
                                    <Col>
                                        <Space direction="horizontal" >
                                            <div>
                                                {order?.id}
                                            </div>
                                            <Button onClick={() => {
                                                navigator.clipboard.writeText(order!.id)
                                                message.success("複製到剪貼簿!")
                                            }} icon={<FontAwesomeIcon icon={faCopy} />} />
                                        </Space>
                                    </Col>
                                </Row>
                            </List.Item>
                            <List.Item>
                                <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                    <Col>
                                        <div>買家</div>
                                    </Col>
                                    <Col>
                                        <Link to={`/customers/${order?.belongCustomerId}`}>
                                            {order?.belongCustomer?.name ?? "未知買家"}
                                        </Link>
                                    </Col>
                                </Row>
                            </List.Item>

                            <List.Item>
                                <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                    <Col>
                                        <div>商品</div>
                                    </Col>
                                    <Col>
                                        <Link to={`/products/${order?.belongProductId}`}>
                                            {order?.belongProduct?.name ?? "未知商品"}
                                        </Link>
                                    </Col>
                                </Row>
                            </List.Item>

                            <List.Item>
                                <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                    <Col>
                                        <div>{`售出價 (${userAuth?.user.sellCurrency})`}</div>
                                    </Col>
                                    <Col>
                                        {order?.sellPrice}
                                    </Col>
                                </Row>
                            </List.Item>
                            <List.Item>
                                <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                    <Col>
                                        <div>數量</div>
                                    </Col>
                                    <Col>
                                        {order?.quantity}
                                    </Col>
                                </Row>
                            </List.Item>

                            <List.Item>
                                <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                    <Col>
                                        <div>{`訂單金額 (${userAuth?.user.sellCurrency})`}</div>
                                    </Col>
                                    <Col>
                                        {(order!.quantity * order!.sellPrice)}
                                    </Col>
                                </Row>
                            </List.Item>


                            <List.Item>
                                <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                    <Col>
                                        <div>準備狀態</div>
                                    </Col>
                                    <Col>
                                        <Button
                                            icon={<FontAwesomeIcon style={{ marginRight: "6px" }} icon={faBoxOpen} color={order?.prepared ? OrderStatusColors.green : ((order?.belongProduct?.inStockNum ?? 0) > order.quantity ? OrderStatusColors.yellow : OrderStatusColors.red)} />}
                                            onClick={async () => {
                                                if (order.prepared != true && (order.belongProduct?.inStockNum ?? 0) < order.quantity) {
                                                    message.error(`庫存不足: ${order.belongProduct?.name ?? "未知商品"}`)
                                                    return;
                                                }

                                                const updatedOrder = await updateStatus({
                                                    prepared: !order.prepared,
                                                } as OrderStatusUpdateDto)
                                                message.success(`準備狀態更新為: ${updatedOrder?.prepared ? "已準備" : "未準備"}!`);
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: order?.prepared ? OrderStatusColors.green : (((order?.belongProduct?.inStockNum ?? 0) ?? 0) > order.quantity ? OrderStatusColors.yellow : OrderStatusColors.red)
                                                }}
                                            >
                                                {
                                                    order?.prepared ? "已準備" : ((order?.belongProduct?.inStockNum ?? 0) > order.quantity ? "可準備" : "未準備")
                                                }
                                            </span>

                                        </Button>
                                    </Col>
                                </Row>
                            </List.Item>
                            <List.Item>
                                <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                    <Col>
                                        <div>付款狀態</div>
                                    </Col>
                                    <Col>
                                        <Button
                                            icon={<FontAwesomeIcon style={{ marginRight: "6px" }} icon={faMoneyBillWave} color={order.paid ? OrderStatusColors.green : OrderStatusColors.red} />}
                                            onClick={async () => {
                                                const updatedOrder = await updateStatus({
                                                    paid: !order.paid,
                                                } as OrderStatusUpdateDto)
                                                message.success(`付款狀態更新為: ${updatedOrder?.paid ? "已付款" : "未付款"}!`);
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: order.paid ? OrderStatusColors.green : OrderStatusColors.red
                                                }}
                                            >
                                                {
                                                    order.paid ? "已付款" : "未付款"
                                                }
                                            </span>

                                        </Button>
                                    </Col>
                                </Row>
                            </List.Item>

                            <List.Item>
                                <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                    <Col>
                                        <div>配送狀態</div>
                                    </Col>
                                    <Col>
                                        <Button
                                            icon={<FontAwesomeIcon style={{ marginRight: "6px" }} icon={faShippingFast} color={order.delivered ? OrderStatusColors.green : OrderStatusColors.red} />}
                                            onClick={async () => {
                                                const updatedOrder = await updateStatus({
                                                    delivered: !order.delivered,
                                                } as OrderStatusUpdateDto)
                                                message.success(`配送狀態更新為: ${updatedOrder?.delivered ? "已配送" : "未配送"}!`);
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: order.delivered ? OrderStatusColors.green : OrderStatusColors.red
                                                }}
                                            >
                                                {
                                                    order.delivered ? "已配送" : "未配送"
                                                }
                                            </span>

                                        </Button>
                                    </Col>
                                </Row>
                            </List.Item>

                            <List.Item>
                                <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                    <Col>
                                        <div>創建時間</div>
                                    </Col>
                                    <Col>
                                        {
                                            `${new Date(order?.createdAt).toLocaleString()}`
                                        }
                                    </Col>
                                </Row>
                            </List.Item>

                            <List.Item>
                                <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                    <Col>
                                        <div>更新時間</div>
                                    </Col>
                                    <Col>
                                        {
                                            `${new Date(order?.updatedAt).toLocaleString()}`
                                        }
                                    </Col>
                                </Row>
                            </List.Item>
                        </List>
                    }
                </div>
            </LoadingContainerShowingWrapper>
        </div>
    )
}
