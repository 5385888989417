import { faFilter, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, InputNumber, Modal, Radio, Row, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../hooks/app';
import useProductsSearching from '../../hooks/product/useProductsSearching';
import { ProductOutputDto } from '../../models/product';
import ProductQueryParameters, { ProductSortingOptions } from '../../queryParameters/ProductQueryParameters';
import PurchaseQueryParameters, { PurchaseSortingOptions } from '../../queryParameters/PurchaseQueryParameters'
import { selectUserAuth } from '../../store/user';

export interface PurchaseSearchingBarProps {
    setQueryParams: React.Dispatch<React.SetStateAction<PurchaseQueryParameters>>;
    initQueryParams: PurchaseQueryParameters;
    queryParams: PurchaseQueryParameters;
}


const initProductQueryParams = new ProductQueryParameters({
    orderBy: { option: ProductSortingOptions.CreatedAt, desc: true },
});


export default function PurchaseSearchingBar(props: PurchaseSearchingBarProps) {
    const { setQueryParams, queryParams, initQueryParams } = props;

    const [isFilterModalVisible, setIsFilterModalVisible] = useState<boolean>(false);
    const [isSortModalVisible, setIsSortModalVisible] = useState<boolean>(false);

    const [productQueryParams, setProductQueryParams] = useState<ProductQueryParameters>(
        new ProductQueryParameters({ ...initProductQueryParams })
    );

    const userAuth = useAppSelector(selectUserAuth);

    const productsResult = useProductsSearching(userAuth, productQueryParams);

    const [selectedProduct, setSelectedProduct] = useState<ProductOutputDto | undefined>();

    useEffect(() => {
        setQueryParams((prev) => {
            return new PurchaseQueryParameters({
                ...prev,
                belongProductId: selectedProduct?.id,
            });
        });
    }, [selectedProduct])

    return (
        <div>
            {/* Searching bar here */}
            {/* Filtering and sorting options */}
            <Space direction="horizontal">
                <InputNumber
                    placeholder="期數"
                    onChange={(v: number) => {
                        setQueryParams(prev =>
                            new PurchaseQueryParameters({
                                ...prev,
                                orderNum: v,
                            }));
                    }}
                />
                <Input style={{ maxWidth: "600px", }} placeholder="搜尋..." onChange={(e) => {
                    setQueryParams(prev =>
                        new PurchaseQueryParameters({
                            ...prev,
                            searchingString: e.target.value,
                        })
                    )
                }}></Input>
                <Button icon={<FontAwesomeIcon icon={faFilter} />} type="default" onClick={() => setIsFilterModalVisible(true)}></Button>
                <Button icon={<FontAwesomeIcon icon={faSortAmountDown} />} type="default" onClick={() => setIsSortModalVisible(true)}></Button>
            </Space>
            {/* Filtering Modal here */}
            <Modal visible={isFilterModalVisible} onOk={() => setIsFilterModalVisible(false)} onCancel={() => setIsFilterModalVisible(false)} footer={null}>
                <div style={{ padding: "40px" }}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Row justify="end">
                            <Button onClick={() => {
                                setQueryParams(new PurchaseQueryParameters({ ...initQueryParams }));
                            }}>清除</Button>
                        </Row>
                        <Row justify="space-between">
                            <div>
                                商品
                            </div>
                            <Select
                                style={{ width: "160px" }}
                                dropdownMatchSelectWidth
                                loading={productsResult.products == null}
                                showSearch
                                showArrow={false}
                                allowClear
                                filterOption={false}
                                onSearch={(v) => {
                                    setProductQueryParams((prev) => {
                                        return new ProductQueryParameters({
                                            ...prev,
                                            searchingString: v,
                                        });
                                    })
                                }}
                                onChange={(id) => {
                                    setSelectedProduct(productsResult.products.find(p => p.id == id));
                                }}
                            >
                                {
                                    (() => {
                                        if (!productsResult?.products) {
                                            return null;
                                        }

                                        const productList = [...productsResult?.products];

                                        if (selectedProduct && productList.findIndex(p => selectedProduct?.id == p.id) == -1) {
                                            productList.push({
                                                ...selectedProduct,
                                            } as ProductOutputDto);
                                        }

                                        return productList.map((p, idx) => {
                                            return (<Select.Option value={p.id} key={p.id}>
                                                <div ref={idx + 1 === productList.length ? productsResult.lastRef : undefined}>
                                                    {p.name}
                                                </div>
                                            </Select.Option>)
                                        })
                                    })()
                                }
                            </Select>
                        </Row>
                    </Space>
                </div>
            </Modal>

            {/* Sorting Modal here */}
            <Modal visible={isSortModalVisible} onOk={() => setIsSortModalVisible(false)} onCancel={() => setIsSortModalVisible(false)} footer={null}>
                <div style={{ padding: "40px" }}>
                    <Row justify="space-between">
                        <div>排序</div>
                        {/* Solve the porblem of select */}
                        <Select
                            style={{ width: "120px" }}
                            value={queryParams.orderBy.desc ? 1 : 0}
                            onChange={(v) => {
                                setQueryParams((prev) => new PurchaseQueryParameters({
                                    ...prev,
                                    orderBy: {
                                        ...prev.orderBy,
                                        desc: !!v
                                    }
                                }))
                            }}>
                            <Select.Option value={1}>降序</Select.Option>
                            <Select.Option value={0}>升序</Select.Option>
                        </Select>
                    </Row>
                    <Radio.Group value={queryParams.orderBy.option} onChange={(e) => {
                        setQueryParams((prev) =>
                            new PurchaseQueryParameters({
                                ...prev,
                                orderBy: {
                                    ...prev.orderBy,
                                    option: e.target.value
                                }
                            })
                        )
                    }}>
                        <Space direction="vertical">
                            <Radio value={PurchaseSortingOptions.CreatedAt}>創建時間</Radio>
                            <Radio value={PurchaseSortingOptions.UpdatedAt}>更新時間</Radio>
                            <Radio value={PurchaseSortingOptions.OrderNum}>期數</Radio>
                            <Radio value={PurchaseSortingOptions.Quantity}>商品數量</Radio>
                            <Radio value={PurchaseSortingOptions.BuyPrice}>購買價格</Radio>
                            <Radio value={PurchaseSortingOptions.TotalPrice}>訂單價格</Radio>
                        </Space>
                    </Radio.Group>
                </div>
            </Modal>
        </div>);
}
