import { faClipboardList, faTrash, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Col, Form, InputNumber, Row, Select, Space, Spin } from 'antd';
import TextArea from 'rc-textarea';
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { createOrders } from '../../api/order';
import ErrorMessageAlert from '../../components/common/ErrorMessageAlert';
import { useAppSelector } from '../../hooks/app';
import useCustomersSearching from '../../hooks/customer/useCustomersSearching';
import useProductsSearching from '../../hooks/product/useProductsSearching';
import { CustomerOutputDto } from '../../models/customer';
import { OrdersCreatingDto } from '../../models/order';
import { ProductOutputDto } from '../../models/product';
import CustomerQueryParameters, { CustomerSortingOptions } from '../../queryParameters/CustomerQueryParameters';
import ProductQueryParameters, { ProductSortingOptions } from '../../queryParameters/ProductQueryParameters';
import { selectUserAuth } from '../../store/user';

const initCustomerQueryParams = new CustomerQueryParameters({
    orderBy: { option: CustomerSortingOptions.CreatedAt, desc: true },
});

const initProductQueryParams = new ProductQueryParameters({
    orderBy: { option: ProductSortingOptions.CreatedAt, desc: true },
});


export interface OrderCreatingPageLocationState {
    product?: ProductOutputDto;
    nextAddress?: string;
}


export default function OrderCreatingPage() {

    const location = useLocation();

    const locationState = (location.state as OrderCreatingPageLocationState);

    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [customerOrderMap, setCustomerOrderMap] = useState({});
    const [currentCustomer, setCurrentCustomer] = useState<CustomerOutputDto | undefined>();
    const [sellPrice, setSellPrice] = useState<number>(0);
    const userAuth = useAppSelector(selectUserAuth);
    const history = useHistory();

    const [customerQueryParams, setCustomerQueryParams] = useState<CustomerQueryParameters>(
        new CustomerQueryParameters({ ...initCustomerQueryParams })
    );

    const [productQueryParams, setProductQueryParams] = useState<ProductQueryParameters>(
        new ProductQueryParameters({ ...initProductQueryParams })
    )

    async function onFinish(creatingDto: OrdersCreatingDto): Promise<void> {
        // Checknig if customer is selected.

        if (Object.keys(customerOrderMap).length == 0) {
            setErrorMessage("請選擇至少一個買家");
            return;
        }

        var customerOrderMapToSend = {}
        for (const k in customerOrderMap) {

            (customerOrderMapToSend as any)[k] = (customerOrderMap as any)[k].qty
        }

        console.log("send map")
        console.log(customerOrderMapToSend)

        creatingDto.customerOrderMap = customerOrderMapToSend;
        creatingDto.sellPrice = sellPrice;

        setErrorMessage(undefined);
        setIsSubmitting(true);

        if (!userAuth) {
            setErrorMessage("必須登入才可執行此操作");
            return;
        }

        try {
            const createdOrders = await createOrders(userAuth, creatingDto);
            history.push(locationState?.nextAddress ?? `/orders`)
        } catch (e: any) {
            console.log(e.detail);
            setErrorMessage(e.message);
        }

        setIsSubmitting(false)
    }

    const customersResult = useCustomersSearching(userAuth, customerQueryParams);
    const productsResult = useProductsSearching(userAuth, productQueryParams);

    const addCustomerToOrderMap = () => {
        if (currentCustomer == null) return;

        setCustomerOrderMap((prev) => {
            return {
                ...prev,
                [currentCustomer.id]: {
                    customer: { ...currentCustomer } as CustomerOutputDto,
                    qty: 1,
                }
            };
        });

        setCurrentCustomer(undefined);
    }

    useEffect(() => {
        console.log(customerOrderMap)
    }, [customerOrderMap])

    useEffect(() => {
        if (locationState?.product?.defaultSellPrice) {
            setSellPrice(locationState?.product?.defaultSellPrice)
        }
    }, [locationState])

    return (
        <div style={{ margin: "auto", textAlign: "center", padding: "40px 20px" }}>
            <div style={{ margin: "40px 0" }}>
                <Avatar size={125} style={{ backgroundColor: "#63acc7" }}><FontAwesomeIcon icon={faClipboardList} size={"lg"} color={"#ffffff"} style={{ margin: "auto" }} /></Avatar>
            </div>

            <div style={{ maxWidth: "100%" }}>
                <Space direction="vertical">
                    <ErrorMessageAlert errorMessage={errorMessage} />
                </Space>
            </div>

            <div style={{ maxWidth: "600px", textAlign: "center", margin: "auto" }}>
                <Form
                    name="basic"
                    labelCol={{ offset: 0, span: 6 }}
                    wrapperCol={{ offset: 0, span: 18 }}
                    labelAlign='left'
                    onFinish={onFinish}
                    autoComplete="off"
                >

                    <Form.Item
                        label="訂單 (期)"
                        name="orderNum"
                        rules={[
                            { required: true, message: '請輸入訂單期數!' },
                            { type: "number", message: "請輸入有效的數字!" },
                        ]}
                        style={{ textAlign: "right" }}

                    >
                        <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item
                        required
                        label="買家"
                    >
                        <Row style={{ width: "100%", marginBottom: "6px" }} align="middle" gutter={16} justify="space-between">
                            <Col span={21}>
                                <Select
                                    dropdownMatchSelectWidth
                                    loading={customersResult.customers == null}
                                    showSearch
                                    showArrow={false}
                                    allowClear
                                    filterOption={false}
                                    value={currentCustomer?.id}
                                    onSearch={(v) => {
                                        setCustomerQueryParams((prev) => {
                                            return new CustomerQueryParameters({
                                                ...prev,
                                                searchingString: v,
                                            });
                                        })
                                    }}
                                    onChange={(v) => setCurrentCustomer(customersResult.customers.find(c => c.id == v))}
                                >
                                    {
                                        customersResult.customers && customersResult.customers.filter(c => !customerOrderMap.hasOwnProperty(c.id)).map((c, idx) => {
                                            return (<Select.Option value={c.id} key={c.id}>
                                                <div ref={idx + 1 === customersResult.customers.length ? customersResult.lastRef : undefined}>
                                                    {c.name}
                                                </div>
                                            </Select.Option>)
                                        })
                                    }
                                </Select>
                            </Col>


                            <Col span={2} style={{ marginLeft: "4px" }}>
                                <Button disabled={!(currentCustomer != null)} icon={<FontAwesomeIcon icon={faUserPlus}
                                    onClick={addCustomerToOrderMap}
                                />} />
                            </Col>
                        </Row>

                        {
                            Object.keys(customerOrderMap).map(id => {

                                const { customer, qty } = (customerOrderMap as any)[id];

                                return <Row style={{ width: "100%", marginBottom: "6px" }} align="middle" gutter={16} justify="space-between" key={id}>
                                    <Col span={13} style={{ textAlign: "center" }}>
                                        <div style={{ paddingLeft: "2px", paddingRight: "2px", paddingTop: "4px", paddingBottom: "4px", borderRadius: "12px", backgroundColor: "#ffffff", boxShadow: "2px 2px #dddddd" }}>
                                            {customer.name}
                                        </div>
                                    </Col>

                                    <Col span={8} style={{ textAlign: "center" }}>
                                        <Space direction="horizontal">

                                            <InputNumber min={1} placeholder={"個"} value={qty} onChange={(v) => setCustomerOrderMap(prev => {
                                                delete (prev as any)[id]
                                                return {
                                                    ...prev,
                                                    [id]: {
                                                        customer: customer,
                                                        qty: v,
                                                    },
                                                };
                                            })} />
                                            <div>
                                                個
                                            </div>
                                        </Space>
                                    </Col>

                                    <Col span={2} style={{ marginLeft: "4px" }}>
                                        <Button icon={<FontAwesomeIcon icon={faTrash}
                                            onClick={() => {
                                                setCustomerOrderMap(prev => {
                                                    delete (prev as any)[id]
                                                    return { ...prev };
                                                })
                                            }}
                                        />} />
                                    </Col>
                                </Row>
                            }
                            )
                        }
                    </Form.Item>

                    <Form.Item
                        label="商品"
                        name="belongProductId"
                        rules={[
                            { required: true, message: '請選擇商品!' },
                        ]}
                        initialValue={locationState?.product?.id}
                    >
                        <Select
                            dropdownMatchSelectWidth
                            loading={productsResult.products == null}
                            showSearch
                            showArrow={false}
                            allowClear
                            filterOption={false}
                            onSearch={(v) => {
                                setProductQueryParams((prev) => {
                                    return new ProductQueryParameters({
                                        ...prev,
                                        searchingString: v,
                                    });
                                })
                            }}
                            onSelect={
                                (id) => {
                                    // Find the product
                                    const setProduct = productsResult.products.find(p => p.id == id);

                                    if (setProduct != null) {

                                        // Set sell price as default.
                                        setSellPrice(setProduct?.defaultSellPrice);
                                    }
                                }
                            }
                        >
                            {
                                (() => {

                                    if (!productsResult?.products) {
                                        return null;
                                    }

                                    const productList = [...productsResult?.products];

                                    if (locationState?.product && productList.findIndex(p => locationState?.product?.id == p.id) == -1) {
                                        productList.push({
                                            ...locationState?.product,
                                        } as ProductOutputDto);
                                    }

                                    return productList.map((p, idx) => {
                                        return (<Select.Option value={p.id}>
                                            <div ref={idx + 1 === productList.length ? productsResult.lastRef : undefined}>
                                                {p.name}
                                            </div>
                                        </Select.Option>)
                                    })
                                })()
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={`售出價 (${userAuth?.user.sellCurrency})`}
                        required
                    >
                        <Form.Item
                            rules={[{ required: true, message: '請輸入售出價格!' }]}
                        >
                            <InputNumber style={{ width: "100%" }} value={sellPrice} onChange={(v) => setSellPrice(v)} />
                        </Form.Item>
                        <div style={{ borderRadius: "16px", marginTop: "12px", padding: "6px", backgroundColor: "#ffffff", fontWeight: "bold", boxShadow: "3px 3px #eeeeee" }}>
                            {userAuth?.user.buyToSellExchangeRate == null ? <div></div> : `${(sellPrice / userAuth?.user.buyToSellExchangeRate).toFixed(2)} ${userAuth.user.buyCurrency}`}
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="備註"
                        name="note"
                        rules={[
                            {
                                max: 1000,
                                message: "最多字數為1000字"
                            }
                        ]}
                    >
                        <TextArea rows={5} style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Row justify="end">
                            {
                                isSubmitting ?
                                    <Spin /> :
                                    <Button type="primary" htmlType="submit">
                                        創建
                                    </Button>
                            }
                        </Row>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
