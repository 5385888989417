import React, { useState } from 'react'
import { Avatar, Button, Form, Input, Row, Space, Spin } from 'antd';
import { useAppDispatch, useAppSelector } from '../../hooks/app';
import { sendRegisisteringVerificationEmailToUser, userVerifyEmail } from '../../api/user';
import { logOutAuth, selectUserAuth, setUserAuth } from '../../store/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import ErrorMessageAlert from '../../components/common/ErrorMessageAlert';
import { EmailVerifyDto } from '../../models/user';

export default function EmailVerifyPage() {

    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [emailSendTimer, setEmailSendTimer] = useState<number>(0);
    const [emailRequesting, setEmailRequesting] = useState<boolean>(false);
    const waitingTime: number = 15;

    const dispatch = useAppDispatch();
    const userAuth = useAppSelector(selectUserAuth)

    async function logOut() {
        dispatch(logOutAuth())
    }

    async function onFinish(emailVerify: EmailVerifyDto): Promise<void> {
        if (!userAuth) {
            setErrorMessage("您需登入後, 才可進行信箱認證")
            return;
        }

        setErrorMessage(undefined);
        setIsSubmitting(true);

        try {
            const newAuth = await userVerifyEmail(userAuth, emailVerify);
            console.log("Setting new user auth")
            console.log(newAuth.user);
            dispatch(setUserAuth(newAuth));
        } catch (e: any) {
            console.log(e.detail);
            setErrorMessage(e.message);
        }

        setIsSubmitting(false)
    }

    async function sendVerificationEmailAgain(): Promise<void> {
        if (!userAuth) {
            setErrorMessage("您需登入後, 才可進行信箱認證")
            return;
        }

        setEmailRequesting(true);

        try {
            await sendRegisisteringVerificationEmailToUser(userAuth);
            setEmailSendTimer(waitingTime)

            setInterval(() => {
                if (emailSendTimer > 0) {
                    setEmailSendTimer(emailSendTimer - 1);
                } else {
                    setEmailSendTimer(0)
                }
            }, waitingTime * 1000);

        } catch (e: any) {
            console.log(e.detail);
            setErrorMessage(e.message);
        }

        setEmailRequesting(false);
    }

    return (
        <div style={{ margin: "auto", textAlign: "center", padding: "40px 20px" }}>
            <div style={{ margin: "40px 0" }}>
                <Avatar size={125} style={{ backgroundColor: "#63acc7" }}><FontAwesomeIcon icon={faEnvelope} size={"lg"} color={"#ffffff"} style={{ margin: "auto" }} /></Avatar>
            </div>

            <div style={{ maxWidth: "100%" }}>
                <Space direction="vertical">
                    <ErrorMessageAlert errorMessage={errorMessage} />
                </Space>
            </div>

            <div style={{ maxWidth: "300px", textAlign: "center", margin: "auto" }}>

                <Form
                    name="verifyEmailForm"
                    labelCol={{ offset: 0, span: 6 }}
                    wrapperCol={{ offset: 0, span: 18 }}
                    labelAlign='left'
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        label="驗證碼"
                        name="code"
                        rules={[
                            { required: true, message: '請填寫您的驗證碼!' },
                            { len: 6, message: "驗證碼為6位數" }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
                        <Row justify="space-between">
                            {
                                emailRequesting ?
                                    <Spin /> :
                                    (
                                        <Button type="primary" onClick={sendVerificationEmailAgain} disabled={emailSendTimer > 0}>
                                            再次寄送{`${emailSendTimer > 0 ? ` ${emailSendTimer}` : ""}`}
                                        </Button>
                                    )
                            }
                            {
                                isSubmitting ?
                                    <Spin /> :
                                    <Button type="primary" htmlType="submit">
                                        驗證
                                    </Button>
                            }

                        </Row>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
                        <Row justify="end">
                            <Button onClick={logOut}>
                                登出
                            </Button>
                        </Row>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
