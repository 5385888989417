export enum PurchaseSortingOptions {
    CreatedAt = "CreatedAt",
    UpdatedAt = "UpdatedAt",
    OrderNum = "OrderNum",
    Quantity = "Quantity",
    BuyPrice = "BuyPrice",
    TotalPrice = "TotalPrice",
}

export interface PurchaseSortingSet {
    option: PurchaseSortingOptions;
    desc: boolean;
}

export default class PurchaseQueryParameters {
    public orderBy: PurchaseSortingSet = {
        option: PurchaseSortingOptions.OrderNum,
        desc: true,
    };

    public searchingString?: string;

    public belongShopId?: string;
    public belongProductId?: string;

    public orderNum?: number;

    constructor(init: Partial<PurchaseQueryParameters>) {
        Object.assign(this, init);
    }

    getOrderString(): string {
        return `${
			this.orderBy.option != PurchaseSortingOptions.CreatedAt
				? 'createdAt desc,'
				: ''
		}${this.orderBy.option.toString()}${this.orderBy.desc ? ' desc' : ''}`;
    }

    generateQuery(pageNumber?: number, pageSize?: number): string {
        let query = "";

        if (pageNumber) {
            query += `&pageNumber=${pageNumber}`;
        }

        if (pageSize) {
            query += `&pageSize=${pageSize}`;
        }

        if (this.orderBy) {
            query += `&orderBy=${this.getOrderString()}`;
        }

        if (this.searchingString != null && this.searchingString.trim() !== "") {
            query += `&searchingString=${this.searchingString}`;
        }

        if (this.belongShopId != undefined) {
            query += `&belongShopId=${this.belongShopId}`;
        }
        if (this.belongProductId != undefined) {
            query += `&belongProductId=${this.belongProductId}`;
        }
        if (this.orderNum != undefined) {
            query += `&orderNum=${this.orderNum}`;
        }

        return query;
    }
}

