import React from 'react';
import { Spin } from 'antd';
import { Redirect } from 'react-router';
import { useAppDispatch, useAppSelector } from './hooks/app';
import { selectUserAuth, tryAutoLogin } from './store/user';
import { Switch, Route } from 'react-router-dom';
import LoginPage from './pages/auth/LoginPage';
import RegisterPage from './pages/auth/RegisterPage';
import EmailVerifyPage from './pages/auth/EmailVerifyPage';
import DefaultLayout from './layouts/DefaultLayout';
import CustomerRoutingPage from './pages/cutomer/CustomerRoutingPage';
import { UserRole } from './models/user';
import ProductRoutingPage from './pages/product/ProductRoutingPage';
import ShopRoutingPage from './pages/shop/ShopRoutingPage';
import OrderRoutingPage from './pages/order/OrderRoutingPage';
import PurchaseRoutingPage from './pages/purchase/PurchaseRoutingPage';
import SettingRoutingPage from './pages/setting/SettingRoutingPage';

function App() {
	const [hasTryAutoLogin, setHastryingAutoLogin] =
		React.useState<boolean>(false);

	const dispatch = useAppDispatch();
	const userAuth = useAppSelector(selectUserAuth);

	React.useEffect(() => {
		// Try auto login here.
		(async () => {
			try {
				await dispatch(tryAutoLogin());
			} catch (e) {
				console.log('Auto login failed');
				console.log(e);
			}
			setHastryingAutoLogin(true);
		})();
	}, [dispatch]);

	// 1. setting up the layout.
	// 2. creating the login page.

	if (!hasTryAutoLogin) {
		return (
			<div style={{ textAlign: 'center' }}>
				<Spin
					style={{ paddingTop: '30px', margin: 'auto' }}
					size="large"
				/>
			</div>
		);
	}

	if (userAuth === undefined) {
		// If not login to the system;
		return (
			<Switch>
				<Route exact path="/register">
					<RegisterPage />
				</Route>
				<Route path="/login">
					<LoginPage />
				</Route>
				<Redirect to="/login" />
			</Switch>
		);
	}

	if (userAuth?.user.userRole === UserRole.EmailNotVerified) {
		return (
			<Switch>
				<Route exact path="/email-verify">
					<EmailVerifyPage />
				</Route>
				<Redirect to="/email-verify" />
			</Switch>
		);
	}

	return (
		<DefaultLayout>
			<Switch>
				<Route path="/customers">
					<CustomerRoutingPage />
				</Route>
				<Route path="/products">
					<ProductRoutingPage />
				</Route>
				<Route path="/shops">
					<ShopRoutingPage />
				</Route>
				<Route path="/orders">
					<OrderRoutingPage />
				</Route>
				<Route path="/purchases">
					<PurchaseRoutingPage />
				</Route>
				<Route path="/settings">
					<SettingRoutingPage />
				</Route>
				<Redirect to="/orders" />
			</Switch>
		</DefaultLayout>
	);
}

export default App;

/**
 * 1. iOS Zooming problem [x]
 * 2. Can't remove purchase. [x]
 * 3. InStock but not show orange icon. [x]
 * 4. Change to ticking for open. => popover [x]
 * 5. Jump back to product list after creating purchase. [x]
 * 6. See orders for all customers. [x]
 */
