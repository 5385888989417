import { CustomerOutputDto } from "./customer";
import { Pagination } from "./pagination";
import { ProductOutputDto } from "./product";

export interface OrderCreatingDto {
    belongCustomerId: string;
    belongProductId: string;
    note: string;
    orderNum: number;
    quantity: number;
    sellPrice: number;
}

export interface OrdersCreatingDto {
    customerOrderMap: Object; // <Id, qty>
    belongProductId: string;
    note: string;
    orderNum: number;
    sellPrice: number;
}

export interface OrderUpdatingDto {
    note: string;
    orderNum: number;
    quantity: number;
    sellPrice: number;
}

export interface OrderOutputDto {
    id: string;
    belongUserId?: string;
    belongCustomer?: CustomerOutputDto;
    belongCustomerId?: string;
    belongProduct?: ProductOutputDto;
    belongProductId?: string;
    note: string;
    orderNum: number;
    quantity: number;
    sellPrice: number;
    prepared: boolean;
    paid: boolean;
    delivered: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export interface OrderStatusUpdateDto {
    prepared?: boolean;
    paid?: boolean;
    delivered?: boolean;
}

export interface OrdersInfo {
    totalOrderCount: number;
    totalProductCount: number;
    estimateProfit: number;
    totalSell: number;
    unpaidAmount: number;
    unpaidOderCount: number;
    undeliveredOrderCount: number;
    estimateUnpreparedAmount: number;
    unpreparedOrderCount: number;
}

export interface GetOrdersResult {
    pagination: Pagination;
    orders: OrderOutputDto[];
}

export const OrderStatusColors = {
    green: "#59ab54",
    red: "#ff5761",
    yellow: "#eb9738",
}