import {
	faBars,
	faBoxOpen,
	faCashRegister,
	faChevronLeft,
	faClipboardList,
	faCog,
	faShoppingBasket,
	faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Drawer, Layout, Menu, Row, Space } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Content, Header } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../hooks/app';
import { logOutAuth } from '../store/user';
import LayoutMenu from './LayoutMenu';

export default function DefaultLayout(props: any) {
	const screen = useBreakpoint();
	const [collapsed, setCollapsed] = useState(screen.md ? false : true);
	const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const history = useHistory();

	const location = useLocation();

	useEffect(() => {
		setCollapsed(screen.md ? false : true);
	}, [screen.md]);

	async function logOut() {
		dispatch(logOutAuth());
	}

	return (
		<Layout
			style={{
				fontSize: screen.md ? '18px' : '14px',
				flexDirection: 'row',
			}}
		>
			{screen.md ? (
				<Sider
					trigger={null}
					theme="dark"
					collapsible
					collapsed={collapsed}
					collapsedWidth={0}
				>
					<LayoutMenu />
				</Sider>
			) : (
				<Drawer
					closable={false}
					placement="left"
					onClose={() => setDrawerVisible(false)}
					visible={drawerVisible}
					bodyStyle={{
						padding: 0,
						backgroundColor: '#001628',
						minHeight: '100vh',
					}}
					style={{ zIndex: 10000 }}
				>
					<LayoutMenu afterPush={() => setDrawerVisible(false)} />
				</Drawer>
			)}
			<Layout style={{ minHeight: '100vh' }}>
				<Header style={{ padding: 0, width: '100%' }}>
					<Row justify="space-between" align="middle">
						<Space align="center" direction="horizontal">
							{screen.md ? (
								<Button
									onClick={() => setCollapsed(!collapsed)}
									type="text"
									style={{ marginLeft: '14px' }}
								>
									<FontAwesomeIcon
										icon={
											collapsed ? faBars : faChevronLeft
										}
										color="white"
										size="lg"
									/>
								</Button>
							) : (
								<Button
									onClick={() => setDrawerVisible(true)}
									icon={<FontAwesomeIcon icon={faBars} />}
									style={{
										position: 'relative',
										left: '10px',
										top: '0px',
										zIndex: 9999,
										color: '#ffffff',
										backgroundColor: '#001628',
									}}
								/>
							)}
							<div
								style={{
									color: 'white',
									fontSize: '48px',
									paddingLeft: '26px',
									fontWeight: 'bold',
									fontFamily: 'Grechen Fuemen',
								}}
							>
								Goes
							</div>
						</Space>
						<div>
							<Button
								onClick={() => logOut()}
								type="default"
								style={{
									color: 'white',
									backgroundColor: 'transparent',
									marginRight: '16px',
									fontWeight: 'bold',
								}}
							>
								登出
							</Button>
						</div>
					</Row>
				</Header>
				<Content>{props.children}</Content>
			</Layout>
		</Layout>
	);
}
