import React, { useEffect, useState } from 'react';
import { getPurchases } from '../../api/purchase';
import { GetPurchasesResult, PurchaseOutputDto } from '../../models/purchase';
import { UserAuthDto } from '../../models/user';
import PurchaseQueryParameters from '../../queryParameters/PurchaseQueryParameters';
import useInfiniteScroll from '../useInfiniteScroll';

export interface usePurchasesSearchingReturn {
	purchases: PurchaseOutputDto[];
	errorMessage?: string;
	isInit: boolean;
	isLoading: boolean;
	hasMore: boolean;
	lastRef: any;
}

export default function usePurchasesSearching(
	userAuth: UserAuthDto | undefined,
	queryParams: PurchaseQueryParameters,
	pageSize: number = 16,
) {
	const [isLoading, setIsLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState<string | undefined>();
	const [purchases, setPurchases] = useState<PurchaseOutputDto[]>([]);
	const [isInit, setIsInit] = useState<boolean>(false);
	const [hasMore, setHasMore] = useState<boolean>(false);
	const [pageNumber, setPageNumber] = useState<number>(1);

	useEffect(() => {
		// Refresh
		if (isInit) {
			setIsInit(false);
			setPurchases([]);
			setPageNumber(1);
			loadData();
		}
	}, [queryParams]);

	useEffect(() => {
		// Pagination
		if (isInit) {
			loadData();
		}
	}, [pageNumber, pageSize]);

	useEffect(() => {
		// OnLoad
		loadData();
	}, []);

	const loadData = async () => {
		if (!userAuth) {
			setErrorMessage('必須先登入才可執行此操作!');
			return;
		}

		try {
			setIsLoading(true);
			setErrorMessage(undefined);

			const result: GetPurchasesResult = await getPurchases(
				userAuth,
				queryParams,
				pageNumber,
				pageSize,
			);

			setPurchases((prev) => {
				return [
					...new Set([
						...prev,
						...result.purchases.filter(
							(i) => !prev.some((prev_i) => prev_i.id == i.id),
						),
					]),
				];
			});

			setHasMore(result.pagination.hasNext);
			setIsLoading(false);
			setIsInit(true);
		} catch (e) {
			setErrorMessage((e as Error).message);
		}
	};

	const lastRef = useInfiniteScroll(
		() => setPageNumber((prev) => prev + 1),
		isLoading,
		hasMore,
	);

	// Q: should we pass the has more to outside?
	return {
		isLoading,
		errorMessage,
		purchases,
		hasMore,
		isInit,
		lastRef,
	} as usePurchasesSearchingReturn;
}
