import { faCogs } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Space, Form, Input, Row, Spin, Button, Col, InputNumber } from 'antd';
import React, { useState } from 'react'
import { Redirect, useHistory } from 'react-router';
import { updateUser } from '../../api/user';
import ErrorMessageAlert from '../../components/common/ErrorMessageAlert';
import { useAppDispatch, useAppSelector } from '../../hooks/app';
import { UserUpdatingDto } from '../../models/user';
import { selectUserAuth, setUserAuth } from '../../store/user';

export default function UserCurrencySettingPage() {

    /**
     * State
     */

    const userAuth = useAppSelector(selectUserAuth);

    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [buyCurrency, setBuyCurrency] = useState<string>(userAuth?.user?.buyCurrency ?? "AUD");
    const [sellCurrency, setSellCurrency] = useState<string>(userAuth?.user?.sellCurrency ?? "TWD");
    const [buyToSellExchangeRate, setBuyToSellExchangeRate] = useState<number>(userAuth?.user?.buyToSellExchangeRate ?? 20.74);

    /**
     * Hooks
     */
    const dispatch = useAppDispatch();
    const history = useHistory();

    async function onFinish() {
        const userUpdating = {
            buyCurrency,
            sellCurrency,
            buyToSellExchangeRate,
        } as UserUpdatingDto;

        setErrorMessage(undefined);
        setIsSubmitting(true);

        if (!userAuth) {
            setErrorMessage("必須登入才可執行此操作");
            return;
        }

        try {
            const updatedUser = await updateUser(userAuth, userUpdating);

            // Override current userAuth.
            dispatch(setUserAuth(
                {
                    ...userAuth,
                    user: updatedUser,
                }
            ));

            // Go back to previous page.
            history.goBack();

        } catch (e: any) {
            console.log(e.detail);
            setErrorMessage(e.message);
        }

        setIsSubmitting(false);
    }

    if (!userAuth) {
        return <Redirect to={'/login'} />
    }

    return (
        <div style={{ margin: "auto", textAlign: "center", padding: "40px  20px" }}>

            <div style={{ margin: "40px 0" }}>
                <Avatar size={125} style={{ backgroundColor: "#63acc7" }}><FontAwesomeIcon icon={faCogs} size={"lg"} color={"#ffffff"} style={{ margin: "auto" }} /></Avatar>
            </div>
            <div style={{ maxWidth: "100%" }}>
                <Space direction="vertical">
                    <ErrorMessageAlert errorMessage={errorMessage} />
                </Space>
            </div>

            {/* Form */}
            <div style={{ maxWidth: "600px", textAlign: "center", margin: "auto" }}>
                <Form
                    name="basic"
                    labelCol={{ offset: 0, span: 6 }}
                    wrapperCol={{ offset: 0, span: 18 }}
                    labelAlign='left'
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    {/* Sell Currency */}
                    <Form.Item
                        label="貨幣 (賣出)"
                        rules={[{
                            required: true, message: '請填寫賣出貨幣名稱'
                        }
                        ]}

                    >
                        <Input placeholder="請填寫賣出貨幣的名稱" value={sellCurrency} onChange={(e) => { setSellCurrency(e.target.value) }} />
                    </Form.Item>

                    {/* Buy Currency */}
                    <Form.Item
                        label="貨幣 (買入)"
                        rules={[{
                            required: true, message: '請填寫買入貨幣名稱'
                        },
                        ]}
                    >
                        <Input placeholder="請填寫買入貨幣的名稱" value={buyCurrency} onChange={(e) => { setBuyCurrency(e.target.value) }} />
                    </Form.Item>

                    {/* Exchange Rate */}
                    <Form.Item
                        label={`1 ${buyCurrency} = `}
                    >
                        <Row style={{ width: "100%" }} justify="space-between">
                            <Col >
                                <Form.Item
                                    name="buyToSellExchangeRate"
                                    initialValue={userAuth?.user.buyToSellExchangeRate}
                                    rules={[{
                                        required: true, message: '請填寫買入貨幣名稱'
                                    },
                                    {
                                        type: "number",
                                        message: "請輸入數字"
                                    }
                                    ]}>

                                    <InputNumber style={{ width: "100%" }} placeholder="請填寫買入貨幣的名稱" value={buyToSellExchangeRate} onChange={(v) => setBuyToSellExchangeRate(v)} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <div style={{ fontWeight: "bold" }}>
                                    {
                                        ` ${sellCurrency}`
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Form.Item>

                    {/* Submit */}
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Row justify="end">
                            {
                                isSubmitting ?
                                    <Spin /> :
                                    <Button type="primary" htmlType="submit">
                                        設置
                                    </Button>
                            }
                        </Row>
                    </Form.Item>
                </Form>
            </div>

        </div>
    )
}
