import React, { useEffect, useState } from 'react'
import { getPurchase } from '../../api/purchase'
import { PurchaseOutputDto } from '../../models/purchase'
import { UserAuthDto } from '../../models/user'

export interface usePurchaseLoadReturn {
    purchase?: PurchaseOutputDto;
    errorMessage?: string;
    isInit: boolean;
    isLoading: boolean;
}

export default function usePurchaseLoad(userAuth: UserAuthDto | undefined, id: string) {
    const [purchase, setPurchase] = useState<PurchaseOutputDto | undefined>()
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [isInit, setisInit] = useState<boolean>(false)
    const [isLoading, setisLoading] = useState<boolean>()

    useEffect(() => {
        if (!userAuth) {
            setErrorMessage("必須先登入才可執行此操作!")
            return;
        }


        (async () => {
            try {
                setisLoading(true)
                let loadedShop: PurchaseOutputDto = await getPurchase(userAuth, id);
                setPurchase(loadedShop);
                setisInit(true);
                setisLoading(false);
            } catch (error) {
                setErrorMessage((error as Error).message);
            }
        })();
    }, [id])

    return { purchase, errorMessage, isInit, isLoading } as usePurchaseLoadReturn;
}
