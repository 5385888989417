import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Avatar,
	Button,
	Form,
	InputNumber,
	Row,
	Select,
	Space,
	Spin,
} from 'antd';
import TextArea from 'rc-textarea';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { createPurchase } from '../../api/purchase';
import ErrorMessageAlert from '../../components/common/ErrorMessageAlert';
import { useAppSelector } from '../../hooks/app';
import useShopsSearching from '../../hooks/shop/useShopsSearching';
import { ProductOutputDto } from '../../models/product';
import { PurchaseCreatingDto, PurchaseOutputDto } from '../../models/purchase';
import ProductQueryParameters, {
	ProductSortingOptions,
} from '../../queryParameters/ProductQueryParameters';
import ShopQueryParameters, {
	ShopSortingOptions,
} from '../../queryParameters/ShopQueryParameters';
import { selectUserAuth } from '../../store/user';
import useProductsSearching from '../../hooks/product/useProductsSearching';

const initShopQueryParams = new ShopQueryParameters({
	orderBy: { option: ShopSortingOptions.CreatedAt, desc: true },
});

const initProductQueryParams = new ProductQueryParameters({
	orderBy: { option: ProductSortingOptions.CreatedAt, desc: true },
});

export interface PurchaseCreatingPageLocationState {
	product?: ProductOutputDto;
	qty?: number;
	nextAddress?: string;
}

export default function PurchaseCreatingPage() {
	const location = useLocation();

	const locationState = location.state as PurchaseCreatingPageLocationState;

	const [errorMessage, setErrorMessage] = useState<string | undefined>();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [buyPrice, setBuyPrice] = useState<number>(0);
	const userAuth = useAppSelector(selectUserAuth);
	const history = useHistory();

	const [shopQueryParams, setShopQueryParams] = useState<ShopQueryParameters>(
		new ShopQueryParameters({ ...initShopQueryParams }),
	);

	const [productQueryParams, setProductQueryParams] =
		useState<ProductQueryParameters>(
			new ProductQueryParameters({ ...initProductQueryParams }),
		);

	async function onFinish(creatingDto: PurchaseCreatingDto): Promise<void> {
		// Checknig if customer is selected.

		setErrorMessage(undefined);
		setIsSubmitting(true);

		if (!userAuth) {
			setErrorMessage('必須登入才可執行此操作');
			return;
		}

		creatingDto.buyPrice = buyPrice;

		try {
			const createdPurchase = await createPurchase(userAuth, creatingDto);
			history.push(locationState?.nextAddress ?? `/purchases`);
		} catch (e: any) {
			console.log(e.detail);
			setErrorMessage(e.message);
		}

		setIsSubmitting(false);
	}

	const shopsResult = useShopsSearching(userAuth, shopQueryParams);
	const productsResult = useProductsSearching(userAuth, productQueryParams);

	useEffect(() => {
		if (locationState?.product?.defaultBuyPrice) {
			setBuyPrice(locationState?.product?.defaultBuyPrice);
		}
	}, [locationState]);

	return (
		<div
			style={{
				margin: 'auto',
				textAlign: 'center',
				padding: '40px 20px',
			}}
		>
			<div style={{ margin: '40px 0' }}>
				<Avatar size={125} style={{ backgroundColor: '#63acc7' }}>
					<FontAwesomeIcon
						icon={faShoppingBasket}
						size={'lg'}
						color={'#ffffff'}
						style={{ margin: 'auto' }}
					/>
				</Avatar>
			</div>

			<div style={{ maxWidth: '100%' }}>
				<Space direction="vertical">
					<ErrorMessageAlert errorMessage={errorMessage} />
				</Space>
			</div>

			<div
				style={{
					maxWidth: '600px',
					textAlign: 'center',
					margin: 'auto',
				}}
			>
				<Form
					name="basic"
					labelCol={{ offset: 0, span: 6 }}
					wrapperCol={{ offset: 0, span: 18 }}
					labelAlign="left"
					onFinish={onFinish}
					autoComplete="off"
				>
					<Form.Item
						label="訂單 (期)"
						name="orderNum"
						rules={[
							{ required: true, message: '請輸入訂單期數!' },
							{ type: 'number', message: '請輸入有效的數字!' },
						]}
						style={{ textAlign: 'right' }}
					>
						<InputNumber style={{ width: '100%' }} />
					</Form.Item>

					<Form.Item
						label="店鋪"
						name="belongShopId"
						rules={[{ required: true, message: '請選擇店鋪!' }]}
					>
						<Select
							dropdownMatchSelectWidth
							loading={productsResult.products == null}
							showSearch
							showArrow={false}
							allowClear
							filterOption={false}
							onSearch={(v) => {
								setShopQueryParams((prev) => {
									return new ShopQueryParameters({
										...prev,
										searchingString: v,
									});
								});
							}}
						>
							{shopsResult.shops
								? shopsResult.shops.map((s, idx) => {
										return (
											<Select.Option value={s.id}>
												<div
													ref={
														idx + 1 ===
														shopsResult.shops.length
															? shopsResult.lastRef
															: undefined
													}
												>
													{s.name}
												</div>
											</Select.Option>
										);
								  })
								: null}
						</Select>
					</Form.Item>
					<Form.Item
						label="商品"
						name="belongProductId"
						rules={[{ required: true, message: '請選擇商品!' }]}
						initialValue={locationState?.product?.id}
					>
						<Select
							dropdownMatchSelectWidth
							loading={productsResult.products == null}
							showSearch
							showArrow={false}
							allowClear
							filterOption={false}
							onSearch={(v) => {
								setProductQueryParams((prev) => {
									return new ProductQueryParameters({
										...prev,
										searchingString: v,
									});
								});
							}}
							onSelect={(id) => {
								// Find the product
								const setProduct = productsResult.products.find(
									(p) => p.id == id,
								);
								if (setProduct != null) {
									setBuyPrice(setProduct?.defaultBuyPrice);
								}
							}}
						>
							{(() => {
								if (!productsResult?.products) {
									return null;
								}

								const productList = [
									...productsResult?.products,
								];

								if (
									locationState?.product &&
									productList.findIndex(
										(p) =>
											locationState?.product?.id == p.id,
									) == -1
								) {
									productList.push({
										...locationState?.product,
									} as ProductOutputDto);
								}

								return productList.map((p, idx) => {
									return (
										<Select.Option value={p.id}>
											<div
												ref={
													idx + 1 ===
													productList.length
														? productsResult.lastRef
														: undefined
												}
											>
												{p.name}
											</div>
										</Select.Option>
									);
								});
							})()}
						</Select>
					</Form.Item>

					<Form.Item
						label={`買入價 (${userAuth?.user.buyCurrency})`}
						required
						initialValue={
							locationState?.product?.defaultBuyPrice ?? 0
						}
					>
						<Form.Item
							rules={[
								{ required: true, message: '請輸入買入價格!' },
							]}
						>
							<InputNumber
								style={{ width: '100%' }}
								value={buyPrice}
								onChange={(v) => setBuyPrice(v)}
							/>
						</Form.Item>
						<div
							style={{
								borderRadius: '16px',
								marginTop: '12px',
								padding: '6px',
								backgroundColor: '#ffffff',
								fontWeight: 'bold',
								boxShadow: '3px 3px #eeeeee',
							}}
						>
							{userAuth?.user.buyToSellExchangeRate == null ? (
								<div></div>
							) : (
								`${(
									buyPrice *
									userAuth?.user.buyToSellExchangeRate
								).toFixed(2)} ${userAuth.user.sellCurrency}`
							)}
						</div>
					</Form.Item>
					<Form.Item
						label={`數量`}
						name="quantity"
						required
						initialValue={locationState?.qty ?? 0}
						rules={[{ required: true, message: '請輸入數量數量!' }]}
					>
						<InputNumber style={{ width: '100%' }} />
					</Form.Item>
					<Form.Item
						label="備註"
						name="note"
						rules={[
							{
								max: 1000,
								message: '最多字數為1000字',
							},
						]}
					>
						<TextArea rows={5} style={{ width: '100%' }} />
					</Form.Item>
					<Form.Item wrapperCol={{ span: 24 }}>
						<Row justify="end">
							{isSubmitting ? (
								<Spin />
							) : (
								<Button type="primary" htmlType="submit">
									創建
								</Button>
							)}
						</Row>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
}
