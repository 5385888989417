import React, { useEffect, useState } from 'react'
import { getShop } from '../../api/shop'
import { ShopOutputDto } from '../../models/shop'
import { UserAuthDto } from '../../models/user'

export interface UseShopLoadReturn {
    shop?: ShopOutputDto;
    errorMessage?: string;
    isInit: boolean;
    isLoading: boolean;
}

export default function useShopLoad(userAuth: UserAuthDto | undefined, id: string) {

    const [shop, setShop] = useState<ShopOutputDto | undefined>()
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [isInit, setisInit] = useState<boolean>(false)
    const [isLoading, setisLoading] = useState<boolean>()

    useEffect(() => {
        if (!userAuth) {
            setErrorMessage("必須先登入才可執行此操作!")
            return;
        }


        (async () => {
            try {
                setisLoading(true)
                let loadedShop: ShopOutputDto = await getShop(userAuth, id);
                setShop(loadedShop);
                setisInit(true);
                setisLoading(false);
            } catch (error) {
                setErrorMessage((error as Error).message);
            }
        })();
    }, [id])

    return { shop, errorMessage, isInit, isLoading } as UseShopLoadReturn;
}
