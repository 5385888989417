import { UserAuthDto } from './../models/user';
import { getAppRequestConfig } from "../utils/auth";
import { apiUrl } from "./apiHandler";
import { GetShopsResult, ShopCreatingDto, ShopOutputDto, ShopUpdatingDto } from '../models/shop';
import axios, { AxiosResponse } from 'axios';
import HttpException from '../exceptions/HttpException';
import ShopQueryParameters from '../queryParameters/ShopQueryParameters';
import { Pagination } from '../models/pagination';

const shopApiUrl = apiUrl + "/shop"


export const createShop = async (userAuth: UserAuthDto, shopCreating: ShopCreatingDto): Promise<ShopOutputDto> => {
    try {
        const config = getAppRequestConfig(userAuth);
        const res: AxiosResponse = await axios.post(shopApiUrl, shopCreating, config);
        return res.data as ShopOutputDto
    } catch (e: any) {
        if (e && e.response) {
            throw new HttpException(e.response.status, "無法創建商店", e.response.data);
        }

        throw new HttpException("UNKNOWN", "無法創建商店", "無細節");
    }
}

export const updateShop = async (userAuth: UserAuthDto, shopId: string, shopUpdating: ShopUpdatingDto): Promise<ShopOutputDto> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.patch(`${shopApiUrl}/${shopId}`, shopUpdating, config);

    if (res.status > 400) {
        throw new HttpException(res.status, `無法更新商店: ${shopId}`, res.data);
    }

    return res.data as ShopOutputDto
}


export const deleteShop = async (userAuth: UserAuthDto, shopId: string): Promise<boolean> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.delete(`${shopApiUrl}/${shopId}`, config);

    if (res.status > 400) {
        throw new HttpException(res.status, `無法刪除商店: ${shopId}`, res.data);
    }

    return res.data as boolean
}

export const getShop = async (userAuth: UserAuthDto, shopId: string): Promise<ShopOutputDto> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.get(`${shopApiUrl}/${shopId}`, config);

    if (res.status > 400) {
        throw new HttpException(res.status, `查詢不到該商店: ${shopId}`, res.data);
    }

    return res.data as ShopOutputDto
}

export const getShops = async (userAuth: UserAuthDto, queryParams: ShopQueryParameters,  pageNumber: number, pageSize: number): Promise<GetShopsResult> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.get(`${shopApiUrl}?${queryParams.generateQuery(pageNumber, pageSize)}`, config);

    if (res.status > 400) {
        throw new HttpException(res.status, `檢索錯誤`, res.data);
    }

    return {
        shops: res.data as ShopOutputDto[],
        pagination: JSON.parse(res.headers['pagination']) as Pagination,
    };
}