import { faFilter, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, Modal, Radio, Row, Select, Space } from 'antd';
import React, { useState } from 'react'
import ShopQueryParameters, { ShopSortingOptions } from '../../queryParameters/ShopQueryParameters'

export interface ShopSearchingBarProps {
    setQueryParams: React.Dispatch<React.SetStateAction<ShopQueryParameters>>;
    initQueryParams: ShopQueryParameters;
    queryParams: ShopQueryParameters
}

export default function ShopSearchingBar(props: ShopSearchingBarProps) {

    const { setQueryParams, queryParams, initQueryParams } = props;

    const [isFilterModalVisible, setIsFilterModalVisible] = useState<boolean>(false);
    const [isSortModalVisible, setIsSortModalVisible] = useState<boolean>(false);

    return (
        <div>
            {/* Searching bar here */}
            {/* Filtering and sorting options */}
            <Space direction="horizontal">
                <Input style={{ maxWidth: "800px", }} placeholder="Search..." onChange={(e) => {
                    setQueryParams(prev =>
                        new ShopQueryParameters({
                            ...prev,
                            searchingString: e.target.value,
                        })
                    )
                }}></Input>
                <Button icon={<FontAwesomeIcon icon={faFilter} />} type="default" onClick={() => setIsFilterModalVisible(true)}></Button>
                <Button icon={<FontAwesomeIcon icon={faSortAmountDown} />} type="default" onClick={() => setIsSortModalVisible(true)}></Button>
            </Space>
            {/* Filtering Modal here */}
            <Modal visible={isFilterModalVisible} onOk={() => setIsFilterModalVisible(false)} onCancel={() => setIsFilterModalVisible(false)} footer={null}>
                <div style={{ padding: "40px" }}>

                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Row justify="end">
                            <Button onClick={() => {
                                setQueryParams(new ShopQueryParameters({ ...initQueryParams }));
                            }}>清除</Button>
                        </Row>
                        <Row justify="space-between">
                            <div>
                                消費紀錄
                            </div>
                            <Select
                                style={{ width: "120px" }}
                                dropdownMatchSelectWidth
                                defaultValue={queryParams.boughtBefore == undefined ? undefined : (queryParams.boughtBefore ? 1 : 0)}
                                allowClear={true}
                                value={queryParams.boughtBefore == null ? undefined : queryParams.boughtBefore ? 1 : 0}
                                onChange={(v) =>
                                    setQueryParams((prev) =>
                                        new ShopQueryParameters({
                                            ...prev,
                                            boughtBefore: v == undefined ? undefined : !!v,
                                        }))
                                }>
                                <Select.Option value={1}>有消費過</Select.Option>
                                <Select.Option value={0}>無消費過</Select.Option>
                            </Select>
                        </Row>
                    </Space>
                </div>
            </Modal>

            {/* Sorting Modal here */}
            <Modal visible={isSortModalVisible} onOk={() => setIsSortModalVisible(false)} onCancel={() => setIsSortModalVisible(false)} footer={null}>
                <div style={{ padding: "40px" }}>
                    <Row justify="space-between">
                        <div>排序</div>
                        {/* Solve the porblem of select */}
                        <Select
                            style={{ width: "120px" }}
                            value={queryParams.orderBy.desc ? 1 : 0}
                            onChange={(v) => {
                                setQueryParams((prev) => new ShopQueryParameters({
                                    ...prev,
                                    orderBy: {
                                        ...prev.orderBy,
                                        desc: !!v
                                    }
                                }))
                            }}>
                            <Select.Option value={1}>降序</Select.Option>
                            <Select.Option value={0}>升序</Select.Option>
                        </Select>
                    </Row>
                    <Radio.Group value={queryParams.orderBy.option} onChange={(e) => {
                        setQueryParams((prev) =>
                            new ShopQueryParameters({
                                ...prev,
                                orderBy: {
                                    ...prev.orderBy,
                                    option: e.target.value
                                }
                            })
                        )
                    }}>
                        <Space direction="vertical">
                            <Radio value={ShopSortingOptions.CreatedAt}>創建時間</Radio>
                            <Radio value={ShopSortingOptions.UpdatedAt}>更新時間</Radio>
                            <Radio value={ShopSortingOptions.Spent}>花費金額</Radio>
                            <Radio value={ShopSortingOptions.NumProductBought}>購買場品數量</Radio>
                        </Space>
                    </Radio.Group>
                </div>
            </Modal>
        </div>
    )
}
