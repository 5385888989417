import { faFilter, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, Modal, Radio, Row, Select, Space } from 'antd';
import React, { useState } from 'react'
import ProductQueryParameters, { ProductSortingOptions } from '../../queryParameters/ProductQueryParameters'

export interface ProductSearchingBarProps {
    setQueryParams: React.Dispatch<React.SetStateAction<ProductQueryParameters>>;
    initQueryParams: ProductQueryParameters;
    queryParams: ProductQueryParameters;
}

export default function ProductSearchingBar(props: ProductSearchingBarProps) {

    const { setQueryParams, queryParams, initQueryParams } = props;

    const [isFilterModalVisible, setIsFilterModalVisible] = useState<boolean>(false);
    const [isSortModalVisible, setIsSortModalVisible] = useState<boolean>(false);

    return (
        <div>
            {/* Searching bar here */}
            {/* Filtering and sorting options */}
            <Space direction="horizontal">
                <Input style={{ maxWidth: "800px", }} placeholder="Search..." onChange={(e) => {
                    setQueryParams(prev =>
                        new ProductQueryParameters({
                            ...prev,
                            searchingString: e.target.value,
                        })
                    )
                }}></Input>
                <Button icon={<FontAwesomeIcon icon={faFilter} />} type="default" onClick={() => setIsFilterModalVisible(true)}></Button>
                <Button icon={<FontAwesomeIcon icon={faSortAmountDown} />} type="default" onClick={() => setIsSortModalVisible(true)}></Button>
            </Space>
            {/* Filtering Modal here */}
            <Modal visible={isFilterModalVisible} onOk={() => setIsFilterModalVisible(false)} onCancel={() => setIsFilterModalVisible(false)} footer={null}>
                <div style={{ padding: "40px" }}>

                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Row justify="end">
                            <Button onClick={() => {
                                setQueryParams(new ProductQueryParameters({ ...initQueryParams }));
                            }}>清除</Button>
                        </Row>

                        <Row justify="space-between">
                            <div>
                                有缺額
                            </div>
                            <Select
                                style={{ width: "120px" }}
                                dropdownMatchSelectWidth
                                defaultValue={queryParams.needToBuy == undefined ? undefined : (queryParams.needToBuy ? 1 : 0)}
                                allowClear={true}
                                value={queryParams.needToBuy == null ? undefined : queryParams.needToBuy ? 1 : 0}
                                onChange={(v) =>
                                    setQueryParams((prev) =>
                                        new ProductQueryParameters({
                                            ...prev,
                                            needToBuy: v == undefined ? undefined : !!v,
                                        }))
                                }>
                                <Select.Option value={1}>有缺額</Select.Option>
                                <Select.Option value={0}>無缺額</Select.Option>
                            </Select>
                        </Row>
                        <Row justify="space-between">
                            <div>
                                有存貨
                            </div>
                            <Select
                                style={{ width: "120px" }}
                                dropdownMatchSelectWidth
                                defaultValue={queryParams.inStock == undefined ? undefined : (queryParams.inStock ? 1 : 0)}
                                allowClear={true}
                                value={queryParams.inStock != null ? queryParams.inStock ? 1 : 0 : undefined}
                                onChange={(v) => {
                                    console.log(v)
                                    console.log("Update working");
                                    setQueryParams((prev) =>
                                        new ProductQueryParameters({
                                            ...prev,
                                            inStock: v == undefined ? undefined : !!v,
                                        }))
                                }}
                            >
                                <Select.Option value={1}>有存貨</Select.Option>
                                <Select.Option value={0}>無存貨</Select.Option>
                            </Select>
                        </Row>
                    </Space>
                </div>
            </Modal>

            {/* Sorting Modal here */}
            <Modal visible={isSortModalVisible} onOk={() => setIsSortModalVisible(false)} onCancel={() => setIsSortModalVisible(false)} footer={null}>
                <div style={{ padding: "40px" }}>
                    <Row justify="space-between">
                        <div>排序</div>
                        {/* Solve the porblem of select */}
                        <Select
                            style={{ width: "120px" }}
                            value={queryParams.orderBy.desc ? 1 : 0}
                            onChange={(v) => {
                                setQueryParams((prev) => new ProductQueryParameters({
                                    ...prev,
                                    orderBy: {
                                        ...prev.orderBy,
                                        desc: !!v
                                    }
                                }))
                            }}>
                            <Select.Option value={1}>降序</Select.Option>
                            <Select.Option value={0}>升序</Select.Option>
                        </Select>
                    </Row>
                    <Radio.Group value={queryParams.orderBy.option} onChange={(e) => {
                        setQueryParams((prev) =>
                            new ProductQueryParameters({
                                ...prev,
                                orderBy: {
                                    ...prev.orderBy,
                                    option: e.target.value
                                }
                            })
                        )
                    }}>
                        <Space direction="vertical">
                            <Radio value={ProductSortingOptions.CreatedAt}>創建時間</Radio>
                            <Radio value={ProductSortingOptions.NeedToBuyAmount}>缺額數量</Radio>
                            <Radio value={ProductSortingOptions.UpdatedAt}>更新時間</Radio>
                            <Radio value={ProductSortingOptions.InStockNum}>存貨數量</Radio>
                            <Radio value={ProductSortingOptions.SellAmount}>銷售數量</Radio>
                            <Radio value={ProductSortingOptions.PurchaseAmount}>購買數量</Radio>
                            <Radio value={ProductSortingOptions.OrderAmount}>訂單數量</Radio>
                        </Space>
                    </Radio.Group>
                </div>
            </Modal>
        </div>
    )
}
