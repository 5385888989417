import { GetOrdersResult, OrderCreatingDto, OrderOutputDto, OrdersCreatingDto, OrdersInfo, OrderStatusUpdateDto, OrderUpdatingDto } from './../models/order';
import { UserAuthDto } from './../models/user';
import { getAppRequestConfig } from "../utils/auth";
import { apiUrl } from "./apiHandler";
import axios, { AxiosResponse } from 'axios';
import HttpException from '../exceptions/HttpException';
import OrderQueryParameters from '../queryParameters/OrderQueryParameters';
import { Pagination } from '../models/pagination';

const orderApiUrl = apiUrl + "/order"


export const createOrder = async (userAuth: UserAuthDto, orderCreating: OrderCreatingDto): Promise<OrderOutputDto> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.post(orderApiUrl, orderCreating, config);

    if (res.status > 400) {
        throw new HttpException(res.status, "無法創立訂單", res.data);
    }

    return res.data as OrderOutputDto
}

export const createOrders = async (userAuth: UserAuthDto, ordersCreating: OrdersCreatingDto): Promise<OrderOutputDto[]> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.post(`${orderApiUrl}/multiple`, ordersCreating, config);

    if (res.status > 400) {
        throw new HttpException(res.status, "無法創立訂單", res.data);
    }

    return res.data as OrderOutputDto[];
}

export const updateOrder = async (userAuth: UserAuthDto, orderId: string, orderUpdating: OrderUpdatingDto): Promise<OrderOutputDto> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.patch(`${orderApiUrl}/${orderId}`, orderUpdating, config);

    if (res.status > 400) {
        throw new HttpException(res.status, `無法更新訂單: ${orderId}`, res.data);
    }

    return res.data as OrderOutputDto
}

export const updateOrderStatus = async (userAuth: UserAuthDto, orderId: string, statusUpdating: OrderStatusUpdateDto): Promise<OrderOutputDto> => {
    const config = getAppRequestConfig(userAuth);

    const res: AxiosResponse = await axios.patch(`${orderApiUrl}/status/${orderId}`, statusUpdating, config);

    if (res.status > 400) {
        throw new HttpException(res.status, `無法更新訂單狀態: ${orderId}`, res.data);
    }

    return res.data as OrderOutputDto;
}


export const deleteOrder = async (userAuth: UserAuthDto, orderId: string): Promise<boolean> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.delete(`${orderApiUrl}/${orderId}`, config);

    if (res.status > 400) {
        throw new HttpException(res.status, `無法刪除訂單: ${orderId}`, res.data);
    }

    return res.data as boolean
}

export const getOrder = async (userAuth: UserAuthDto, orderId: string): Promise<OrderOutputDto> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.get(`${orderApiUrl}/${orderId}`, config);

    if (res.status > 400) {
        throw new HttpException(res.status, `查詢不到該訂單: ${orderId}`, res.data);
    }

    return res.data as OrderOutputDto
}


export const getOrders = async (userAuth: UserAuthDto, queryParams: OrderQueryParameters, pageNumber: number, pageSize: number): Promise<GetOrdersResult> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.get(`${orderApiUrl}?${queryParams.generateQuery(pageNumber, pageSize)}`, config);

    if (res.status > 400) {
        throw new HttpException(res.status, `檢索錯誤`, res.data);
    }
    return {
        orders: res.data as OrderOutputDto[],
        pagination: JSON.parse(res.headers['pagination']) as Pagination,
    } as GetOrdersResult;
}


export const getOrdersInfo = async (userAuth: UserAuthDto, queryParams: OrderQueryParameters): Promise<OrdersInfo> => {

    try {
        const config = getAppRequestConfig(userAuth);
        const res: AxiosResponse = await axios.get(`${orderApiUrl}/info?${queryParams.generateQuery()}`, config);
        return res.data as OrdersInfo
    } catch (e: any) {
        if (e && e.response) {
            throw new HttpException(e.response.status, "無法取得訂單信息", e.response.data);
        }

        throw new HttpException("UNKNOWN", "無法取得訂單信息", "無細節");
    }
}

