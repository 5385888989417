import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import OrderCreatingPage from './OrderCreatingPage';
import OrderDetailPage from './OrderDetailPage';
import OrdersPage from './OrdersPage';
import OrderUpdatingPage from './OrderUpdatingPage';

export default function OrderRoutingPage() {
	let { path } = useRouteMatch();

	return (
		<Switch>
			<Route exact path={path}>
				<OrdersPage />
			</Route>
			<Route exact path={`${path}/new`}>
				<OrderCreatingPage />
			</Route>
			<Route exact path={`${path}/edit/:id`}>
				<OrderUpdatingPage />
			</Route>
			<Route path={`${path}/:id`}>
				<OrderDetailPage />
			</Route>
		</Switch>
	);
}
