export enum CustomerSortingOptions {
	CreatedAt = 'CreatedAt',
	UpdatedAt = 'UpdatedAt',
	Profit = 'Profit',
	Spent = 'Spent',
	Unpaid = 'Unpaid',
}

export interface CustomerSortingSet {
	option: CustomerSortingOptions;
	desc: boolean;
}

export default class CustomerQueryParameters {
	public orderBy: CustomerSortingSet = {
		option: CustomerSortingOptions.CreatedAt,
		desc: true,
	};
	public hasUnpaid?: boolean;
	public hasUndelivered?: boolean;
	public hasUnprepared?: boolean;
	public searchingString?: string = '';

	constructor(init: Partial<CustomerQueryParameters>) {
		Object.assign(this, init);
	}

	getOrderString(): string {
		return `${
			this.orderBy.option != CustomerSortingOptions.CreatedAt
				? 'createdAt desc,'
				: ''
		}${this.orderBy.option.toString()}${this.orderBy.desc ? ' desc' : ''}`;
	}

	generateQuery(pageNumber?: number, pageSize?: number): string {
		// Dealing with pagination
		let query = '';

		if (pageNumber) {
			query += `&pageNumber=${pageNumber}`;
		}

		if (pageSize) {
			query += `&pageSize=${pageSize}`;
		}

		if (this.orderBy) {
			query += `&orderBy=${this.getOrderString()}`;
		}

		// Filtering
		if (
			this.searchingString != null &&
			this.searchingString?.trim() !== ''
		) {
			query += `&searchingString=${this.searchingString}`;
		}

		if (this.hasUnpaid != null) {
			query += `&hasUnpaid=${this.hasUnpaid}`;
		}

		if (this.hasUndelivered != null) {
			query += `&hasUndelivered=${this.hasUndelivered}`;
		}
		console.log('Unprepared value!');
		console.log(this.hasUnprepared);
		if (this.hasUnprepared != null) {
			console.log('Unprepared conducted');
			query += `&hasUnprepared=${this.hasUnprepared}`;
		}

		console.log('The query is');
		console.log(query);

		return query;
	}
}
