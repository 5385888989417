import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Input, Button, Spin, Space, Avatar, Row } from 'antd';
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import ErrorMessageAlert from '../../components/common/ErrorMessageAlert';
import { useAppDispatch } from '../../hooks/app';
import { UserRegisterDto } from '../../models/user';
import { registerUserAsync } from '../../store/user';
import { passwordRuleReg } from '../../utils/auth';


export default function RegisterPage() {

    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const dispatch = useAppDispatch();

    async function onFinish(userRegister: UserRegisterDto): Promise<void> {
        setErrorMessage(undefined);
        setIsSubmitting(true);

        try {
            await (dispatch(registerUserAsync(userRegister)));
        } catch (e: any) {
            console.log(e.detail);
            setErrorMessage(e.message);
        }

        setIsSubmitting(false)
    }


    return (
        <div style={{ margin: "auto", textAlign: "center", padding: "40px 20px" }}>
            <div style={{ margin: "40px 0" }}>
                <Avatar size={125} style={{ backgroundColor: "#63acc7" }}><FontAwesomeIcon icon={faUnlockAlt} size={"lg"} color={"#ffffff"} style={{ margin: "auto" }} /></Avatar>
            </div>

            <div style={{ maxWidth: "100%" }}>
                <Space direction="vertical">
                    <ErrorMessageAlert errorMessage={errorMessage} />
                </Space>
            </div>
            <p />
            <p />

            <div style={{ maxWidth: "600px", textAlign: "center", margin: "auto" }}>
                <Form
                    name="basic"
                    labelCol={{ offset: 0, span: 6 }}
                    wrapperCol={{ offset: 0, span: 18 }}
                    labelAlign='left'
                    onFinish={onFinish}
                    autoComplete="off"
                >

                    <Form.Item
                        label="信箱"
                        name="email"
                        rules={[
                            { required: true, message: '請填寫您的郵箱!' },
                            { type: "email", message: "請輸入有效的信箱!" }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="密碼"
                        name="password"
                        rules={[
                            { required: true, message: '請填寫密碼!' },
                            {
                                pattern: passwordRuleReg,
                                message:
                                    "密碼至少要含有8個字元, 並至少含有一個大寫和小寫英文字母, 以及一個特殊字元(!@#$)",
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label='驗證密碼'
                        name='confirmPassword'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "Please input your password again!",
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue("password") === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject("兩次輸入的密碼不相同, 請重新確認");
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Row justify="end">
                            {
                                isSubmitting ?
                                    <Spin /> :
                                    <Button type="primary" htmlType="submit">
                                        註冊
                                    </Button>
                            }
                        </Row>
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Row justify="end">
                            <NavLink to="/login">
                                返回登入
                            </NavLink>
                        </Row>
                    </Form.Item>
                </Form>
            </div>

        </div>

    )
}
