import {
	faBoxOpen,
	faCashRegister,
	faClipboardList,
	faCog,
	faShoppingBasket,
	faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from 'antd';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export interface LayoutMenuProps {
	afterPush?: (visible: boolean) => void;
}

export default function LayoutMenu(props: LayoutMenuProps) {
	const { afterPush } = props;

	const history = useHistory();
	const location = useLocation();
	return (
		<Menu
			theme="dark"
			mode="inline"
			style={{ paddingTop: '40px' }}
			onClick={
				((item: any) => {
					history.push(item.key);
					afterPush && afterPush(false);
					if (location.pathname.startsWith(item.key)) {
						history.go(0);
					}
				}) as any
			}
		>
			<Menu.Item
				key="/orders"
				icon={<FontAwesomeIcon icon={faClipboardList} />}
			>
				訂單
			</Menu.Item>
			<Menu.Item
				key="/products"
				icon={<FontAwesomeIcon icon={faBoxOpen} />}
			>
				商品
			</Menu.Item>
			<Menu.Item
				key="/customers"
				icon={<FontAwesomeIcon icon={faUsers} />}
			>
				買家
			</Menu.Item>
			<Menu.Item
				key="/purchases"
				icon={<FontAwesomeIcon icon={faShoppingBasket} />}
			>
				採購
			</Menu.Item>
			<Menu.Item
				key="/shops"
				icon={<FontAwesomeIcon icon={faCashRegister} />}
			>
				<div>店鋪</div>
			</Menu.Item>
			<Menu.Item
				key="/settings"
				icon={
					<FontAwesomeIcon icon={faCog} style={{ width: '10px' }} />
				}
			>
				設定
			</Menu.Item>
		</Menu>
	);
}
