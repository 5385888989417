import { EmailVerifyDto, UserAuthDto, UserLoginDto, UserOutputDto, UserPasswordForgetDto, UserPasswordResetDto, UserRegisterDto, UserUpdatingDto } from './../models/user';
import axios, { AxiosResponse } from "axios";
import { apiUrl } from "./apiHandler";
import HttpException from '../exceptions/HttpException';
import { getAppRequestConfig } from '../utils/auth';

const userApiUrl = apiUrl + '/user'

export const registerUser = async (userRegistering: UserRegisterDto): Promise<UserAuthDto> => {
    const res: AxiosResponse = await axios.post(userApiUrl + '/register', userRegistering);

    if (res.status > 400) {
        throw new HttpException(res.status, "註冊失敗", res.data);
    }

    return res.data as UserAuthDto;
}

export const loginUser = async (userLoginDto: UserLoginDto): Promise<UserAuthDto> => {

    try {
        const res: AxiosResponse = await axios.post(userApiUrl + '/login', userLoginDto);
        return res.data as UserAuthDto;
    } catch (e: any) {
        if (e && e.response) {
            throw new HttpException(e.response.status, "登入失敗", e.response.data);
        }

        throw new HttpException("UNKNOWN", "登入失敗", "無細節");
    }
}

export const updateUser = async (userAuth: UserAuthDto, userUpdating: UserUpdatingDto): Promise<UserOutputDto> => {
    try {
        const config = getAppRequestConfig(userAuth);
        const res: AxiosResponse = await axios.patch(userApiUrl, userUpdating, config);
        
        return res.data as UserOutputDto
    } catch(e: any) {
        if (e && e.response) {
            throw new HttpException(e.response.status, "登入失敗", e.response.data);
        }

        throw new HttpException("UNKNOWN", "登入失敗", "無細節");
    }

}


export const verifiyEmailTest = async (): Promise<string> => {
    const res: AxiosResponse = await axios.post(`${userApiUrl}/verify-email/test`);

    if (res.status > 400) {
        throw new HttpException(res.status, "登入失敗", res.data);
    }

    return res.data;
}

/// User need to login to request verification email.
export const sendRegisisteringVerificationEmailToUser = async (userAuth: UserAuthDto): Promise<string> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.get(`${userApiUrl}/email/send`, config);

    if (res.status > 400) {
        throw new HttpException(res.status, "驗證信發送失敗", res.data);
    }

    return res.data;
}

// Fogetting password process.

export const sendingPasswordForgetEmailToUser = async (email: string) => {
    const res: AxiosResponse = await axios.get(`${userApiUrl}/password/forget/email/send/${email}`);

    if (res.status > 400) {
        throw new HttpException(res.status, `驗證信發送失敗至 ${email}`, res.data);
    }

    return res.data as string; // the return is email.
}


export const userForgetPasswordRequestReset = async (userPasswordForget: UserPasswordForgetDto) => {
    const res: AxiosResponse = await axios.post(`${userApiUrl}/password/forget/reset`, userPasswordForget);

    if (res.status > 400) {
        throw new HttpException(res.status, `無法重置您的密碼, 請重新確認`, res.data);
    }

    return res.data as UserAuthDto;
}


// Login to reset the password.
export const userResetPassword = async (userAuth: UserAuthDto, UserPasswordReset: UserPasswordResetDto) => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.post(`${userApiUrl}/password/reset`, UserPasswordReset, config);

    if (res.status > 400) {
        throw new HttpException(res.status, `無法重置您的密碼, 請重新確認`, res.data);
    }

    return res.data as UserAuthDto;
}

export const userVerifyEmail = async (userAuth: UserAuthDto, emailVerify: EmailVerifyDto): Promise<UserAuthDto> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.post(`${userApiUrl}/email/verify/`, emailVerify, config);

    if (res.status > 400) {
        throw new HttpException(res.status, "無法驗證信箱", res.data);
    }

    return res.data as UserAuthDto;
}