import React, { useEffect, useState } from 'react'
import { getCustomer } from '../../api/customer'
import { CustomerOutputDto } from '../../models/customer'
import { UserAuthDto } from '../../models/user'

export interface UseCustomerLoadReturn {
    customer: CustomerOutputDto | undefined;
    errorMessage: string | undefined;
    isInit: boolean;
    isLoading: boolean;
}

export default function useCustomerLoad(userAuth: UserAuthDto | undefined, id: string) {
    const [customer, setCustomer] = useState<CustomerOutputDto | undefined>()
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [isInit, setisInit] = useState<boolean>(false)
    const [isLoading, setisLoading] = useState<boolean>()

    useEffect(() => {
        if (!userAuth) {
            setErrorMessage("必須先登入才可執行此操作!")
            return;
        }


        (async () => {
            try {
                setisLoading(true)
                let loadedCusteomr: CustomerOutputDto = await getCustomer(userAuth, id);
                setCustomer(loadedCusteomr);
                setisInit(true);
                setisLoading(false);
            } catch (error) {
                setErrorMessage((error as Error).message);
            }
        })();
    }, [id])

    return { customer, errorMessage, isInit, isLoading } as UseCustomerLoadReturn;
}
