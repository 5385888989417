export enum ProductSortingOptions {
	CreatedAt = 'CreatedAt',
	UpdatedAt = 'UpdatedAt',
	InStockNum = 'InStockNum',
	OrderAmount = 'OrderAmount',
	PurchaseAmount = 'PurchaseAmount',
	SellAmount = 'SellAmount',
	NeedToBuyAmount = 'NeedToBuyAmount',
}

export interface ProductSortingSet {
	option: ProductSortingOptions;
	desc: boolean;
}

export default class ProductQueryParameters {
	public orderBy: ProductSortingSet = {
		option: ProductSortingOptions.CreatedAt,
		desc: true,
	};
	public searchingString?: string;
	public inStock?: boolean;
	public needToBuy?: boolean;

	constructor(init: Partial<ProductQueryParameters>) {
		Object.assign(this, init);
	}

	getOrderString(): string {
		return `${
			this.orderBy.option != ProductSortingOptions.CreatedAt
				? 'createdAt desc,'
				: ''
		}${this.orderBy.option.toString()}${this.orderBy.desc ? ' desc' : ''}`;
	}

	generateQuery(pageNumber?: number, pageSize?: number): string {
		let query = '';

		if (pageNumber) {
			query += `&pageNumber=${pageNumber}`;
		}

		if (pageSize) {
			query += `&pageSize=${pageSize}`;
		}

		if (this.orderBy) {
			query += `&orderBy=${this.getOrderString()}`;
		}

		if (
			this.searchingString != null &&
			this.searchingString?.trim() !== ''
		) {
			query += `&searchingString=${this.searchingString}`;
		}

		if (this.inStock != null) {
			query += `&inStock=${this.inStock}`;
		}

		if (this.needToBuy != null) {
			query += `&needToBuy=${this.needToBuy}`;
		}

		return query;
	}
}
