import { faClipboardList, faEdit, faShoppingBasket, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Collapse, List, Popover, Row, Skeleton, Space } from 'antd';
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { deleteProduct } from '../../api/product';
import AuthShow from '../../components/auth/AuthShow';
import ErrorMessageAlert from '../../components/common/ErrorMessageAlert';
import LoadingContainerShowingWrapper from '../../components/common/LoadingContainerShowingWrapper';
import LoadingShowSpinWrap from '../../components/common/LoadingShowSpinWrap';
import { useAppSelector } from '../../hooks/app';
import useProductLoad from '../../hooks/product/useProductLoad';
import OrderQueryParameters, { OrderSortingOptions, OrderSortingSet } from '../../queryParameters/OrderQueryParameters';
import PurchaseQueryParameters, { PurchaseSortingOptions, PurchaseSortingSet } from '../../queryParameters/PurchaseQueryParameters';
import { selectUserAuth } from '../../store/user';
import { OrderCreatingPageLocationState } from '../order/OrderCreatingPage';
import { PurchaseCreatingPageLocationState } from '../purchase/PurchaseCreatingPage';


export interface ProductDetailParams {
    id: string
}

export default function ProductDetailPage() {

    const { id } = useParams<ProductDetailParams>();

    const history = useHistory();

    const userAuth = useAppSelector(selectUserAuth);

    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [deletingErrorMessage, setDeletingErrorMessage] = useState<string | undefined>();
    const productResult = useProductLoad(userAuth, id);
    const { product } = productResult;

    const deleteThisProduct = async () => {

        if (userAuth && productResult.product?.id) {
            try {
                setIsDeleting(true);
                await deleteProduct(userAuth, productResult.product.id);
                setIsDeleting(false);
                history.push("/products");
            } catch (e) {
                setDeletingErrorMessage((e as Error).message);
                setIsDeleting(false);
            }
        }
    }
    return (
        <div style={{ padding: "16px" }}>
            <LoadingContainerShowingWrapper
                isInit={productResult.isInit}
                isLoading={productResult.isLoading}
                errorMessage={productResult.errorMessage}
                loadingComponent={<Skeleton active paragraph={{ rows: 5, }} title={{ width: "80%" }} />}
            >
                <div >
                    <AuthShow>
                        <div >
                            <Row justify="end">
                                <Button icon={<FontAwesomeIcon icon={faEdit} />} type="default" onClick={() => history.push(`/products/edit/${product!.id}`)} >編輯</Button>
                                <LoadingShowSpinWrap isLoading={isDeleting}>
                                    <Popover title="刪除此商品?" trigger="click" placement="bottomLeft" content={<Button onClick={deleteThisProduct} >Yes</Button>} >
                                        <Button danger style={{ margin: "0 20px" }} icon={<FontAwesomeIcon icon={faTrash} />}>刪除</Button>
                                    </Popover>
                                </LoadingShowSpinWrap>
                            </Row>
                        </div>
                    </AuthShow>
                    <ErrorMessageAlert errorMessage={deletingErrorMessage} />

                    <div style={{ paddingTop: "12px" }}>
                        <div style={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}>
                            {product?.name}
                        </div>
                    </div>

                    <Collapse style={{ marginTop: "14px" }}>
                        <Collapse.Panel header="備註" key="1">
                            <p>{product?.note}</p>
                        </Collapse.Panel>
                    </Collapse>

                    <List
                        style={{ marginTop: "16px", backgroundColor: "#ffffff" }}
                        bordered
                    >
                        <List.Item>
                            <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                <Col>
                                    <div>
                                        庫存數量
                                    </div>
                                </Col>
                                <Col>
                                    <Button
                                        onClick={() => {
                                            history.push({
                                                pathname: "/purchases",
                                                state: {
                                                    belongProductId: product.id,
                                                    orderBy: {
                                                        desc: true,
                                                        option: PurchaseSortingOptions.CreatedAt,
                                                    } as PurchaseSortingSet
                                                } as PurchaseQueryParameters
                                            });
                                        }}
                                    >
                                        {product?.inStockNum}
                                    </Button>
                                </Col>
                            </Row>
                        </List.Item>
                        <List.Item>
                            <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                <Col>
                                    <div>
                                        缺額
                                    </div>
                                </Col>
                                <Col>
                                    <Button
                                        onClick={() => {
                                            history.push({
                                                pathname: `/orders`,
                                                state: {
                                                    belongProductId: product.id,
                                                    orderBy: {
                                                        desc: true,
                                                        option: OrderSortingOptions.Quantity,
                                                    } as OrderSortingSet
                                                } as OrderQueryParameters,
                                            })
                                        }}
                                    >
                                        {product?.needToBuyAmount}
                                    </Button>
                                </Col>
                            </Row>
                        </List.Item>
                        <List.Item>
                            <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                <Col>
                                    <div>
                                        {`預設買入價 (${userAuth?.user.buyCurrency})`}
                                    </div>
                                </Col>
                                <Col>
                                    <div>
                                        {product?.defaultBuyPrice}
                                    </div>
                                </Col>
                            </Row>
                        </List.Item>
                        <List.Item>
                            <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                    <Col>
                                        <div>
                                            {`預設售出價 (${userAuth?.user.sellCurrency})`}
                                        </div>
                                    </Col>
                                    <Col>
                                        <div>
                                            {product?.defaultSellPrice}
                                        </div>
                                    </Col>
                                </Row>
                            </Row>
                        </List.Item>
                        <List.Item>
                            <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                <Col>
                                    <div>
                                        創建
                                    </div>
                                </Col>
                                <Col>
                                    <Space direction="horizontal">
                                        <Button
                                            onClick={() => {
                                                history.push({
                                                    pathname: "/orders/new",
                                                    state: {
                                                        product: product,
                                                    } as OrderCreatingPageLocationState,
                                                })
                                            }}
                                            icon={<FontAwesomeIcon icon={faClipboardList} />}
                                        />
                                        <Button
                                            onClick={() => {
                                                history.push({
                                                    pathname: "/purchases/new",
                                                    state: {
                                                        product: product,
                                                        qty: product.needToBuyAmount > 0 ? product.needToBuyAmount : 1
                                                    } as PurchaseCreatingPageLocationState
                                                })
                                            }}
                                            icon={<FontAwesomeIcon icon={faShoppingBasket} />}
                                        />
                                    </Space>
                                </Col>
                            </Row>
                        </List.Item>
                    </List>
                </div>
            </LoadingContainerShowingWrapper>
        </div>
    )
}
