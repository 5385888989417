import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Form, InputNumber, Row, Skeleton, Space, Spin } from 'antd';
import TextArea from 'rc-textarea';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { updateOrder } from '../../api/order';
import ErrorMessageAlert from '../../components/common/ErrorMessageAlert';
import LoadingContainerShowingWrapper from '../../components/common/LoadingContainerShowingWrapper';
import { useAppSelector } from '../../hooks/app';
import useOrderLoad from '../../hooks/order/useOrderLoad';
import { OrderUpdatingDto } from '../../models/order';
import { selectUserAuth } from '../../store/user';

export interface OrderUpdatingPageParams {
    id: string;
}


export default function OrderUpdatingPage() {
    const { id } = useParams<OrderUpdatingPageParams>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const userAuth = useAppSelector(selectUserAuth);
    const history = useHistory();
    const orderResult = useOrderLoad(userAuth, id);
    const { order } = orderResult;

    const [sellPrice, setSellPrice] = useState<number>(0);

    async function onFinish(updatingDto: OrderUpdatingDto): Promise<void> {
        setErrorMessage(undefined);
        setIsSubmitting(true);

        if (!userAuth) {
            setErrorMessage("必須登入才可執行此操作");
            return;
        }

        if (!(order?.id)) {
            setErrorMessage("查無此訂單");
            return;
        }

        updatingDto.sellPrice = sellPrice;

        try {
            const updatedOrder = await updateOrder(userAuth, order.id, updatingDto);
            history.push(`/orders/${updatedOrder.id}`)

        } catch (e: any) {
            console.log(e.detail);
            setErrorMessage(e.message);
        }

        setIsSubmitting(false)
    }

    useEffect(() => {
        if (order) {
            console.log(order)
            setSellPrice(order.sellPrice);
        }

    }, [orderResult.isInit])


    return (
        <div style={{ margin: "auto", textAlign: "center", padding: "40px 20px" }}>
            <div style={{ margin: "40px 0" }}>
                <Avatar size={125} style={{ backgroundColor: "#63acc7" }}><FontAwesomeIcon icon={faClipboardList} size={"lg"} color={"#ffffff"} style={{ margin: "auto" }} /></Avatar>
            </div>

            <div style={{ maxWidth: "100%" }}>
                <Space direction="vertical">
                    <ErrorMessageAlert errorMessage={errorMessage} />
                </Space>
            </div>
            <LoadingContainerShowingWrapper
                isInit={orderResult.isInit}
                isLoading={orderResult.isLoading}
                errorMessage={orderResult.errorMessage}
                loadingComponent={<Skeleton active paragraph={{ rows: 5, }} title={{ width: "80%" }} />}
            >
                <div style={{ maxWidth: "600px", textAlign: "center", margin: "auto" }}>
                    <Form
                        name="basic"
                        labelCol={{ offset: 0, span: 6 }}
                        wrapperCol={{ offset: 0, span: 18 }}
                        labelAlign='left'
                        onFinish={onFinish}
                        autoComplete="off"
                    >

                        <Form.Item
                            label="訂單 (期)"
                            name="orderNum"
                            rules={[
                                { required: true, message: '請輸入訂單期數!' },
                                { type: "number", message: "請輸入有效的數字!" },
                            ]}
                            style={{ textAlign: "right" }}
                            initialValue={order?.orderNum}
                        >
                            <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item
                            required
                            label="買家"
                        >
                            <div style={{ borderRadius: "16px", backgroundColor: "#ffffff", boxShadow: "2px 2px #eeeeee", padding: "10px" }}>
                                <Link to={`/customers/${order?.belongCustomerId}`} >
                                    {order?.belongCustomer?.name ?? "未知買家"}
                                </Link>
                            </div>
                        </Form.Item>

                        <Form.Item
                            required
                            label="商品"
                            name="belongShopId"
                        >
                            <div style={{ borderRadius: "16px", backgroundColor: "#ffffff", boxShadow: "2px 2px #eeeeee", padding: "10px" }}>
                                <Link to={`/products/${order?.belongCustomerId}`} >
                                    {order?.belongProduct?.name ?? "未知商品"} 
                                </Link>
                            </div>
                        </Form.Item>
                        <Form.Item
                            label={`售出價 (${userAuth?.user.sellCurrency})`}
                            required
                        >
                            <Form.Item
                                rules={[{ required: true, message: '請輸入售出價格!' }]}
                            >
                                <InputNumber style={{ width: "100%" }} value={sellPrice} onChange={(v) => setSellPrice(v)} />
                            </Form.Item>
                            <div style={{ borderRadius: "16px", marginTop: "12px", padding: "6px", backgroundColor: "#ffffff", fontWeight: "bold", boxShadow: "3px 3px #eeeeee" }}>
                                {userAuth?.user.buyToSellExchangeRate == null ? <div></div> : `${(sellPrice / userAuth?.user.buyToSellExchangeRate).toFixed(2)} ${userAuth.user.buyCurrency}`}
                            </div>
                        </Form.Item>
                        <Form.Item
                            label={`數量`}
                            name="quantity"
                            required
                            initialValue={order?.quantity}
                            rules={[{ required: true, message: '請輸入數量數量!' }]}
                        >
                            <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item
                            label="備註"
                            name="note"
                            rules={[
                                {
                                    max: 1000,
                                    message: "最多字數為1000字"
                                }
                            ]}
                            initialValue={order?.note}
                        >
                            <TextArea rows={5} style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item wrapperCol={{ span: 24 }}>
                            <Row justify="end">
                                {
                                    isSubmitting ?
                                        <Spin /> :
                                        <Button type="primary" htmlType="submit">
                                            更新
                                        </Button>
                                }
                            </Row>
                        </Form.Item>
                    </Form>
                </div>
            </LoadingContainerShowingWrapper>
        </div>
    )
}
