import { Spin } from 'antd';
import React from 'react'
import ErrorMessageAlert from './ErrorMessageAlert';

export interface LoadingContainerShowingWrapperProps {
    errorMessage?: string;
    isLoading: boolean;
    isInit: boolean;
    children?: React.ReactNode;
    loadingComponent?: React.ReactNode;
}


export default function LoadingContainerShowingWrapper(props: LoadingContainerShowingWrapperProps) {

    const { errorMessage, isLoading, isInit, loadingComponent } = props;

    if (errorMessage) {
        return <ErrorMessageAlert errorMessage={errorMessage} ></ErrorMessageAlert>
    }

    if (isLoading || !isInit) {
        if (loadingComponent) {
            return <div style={{padding: "40px"}}>{loadingComponent}</div>
        }

        return <Spin style={{ paddingTop: "80px" }} size="large" />;
    }

    return <>{props.children}</>;
}
