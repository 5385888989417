import { faCopy, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, List, message, Popover, Row, Skeleton, Space } from 'antd';
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom';
import { deletePurchase } from '../../api/purchase';
import AuthShow from '../../components/auth/AuthShow';
import ErrorMessageAlert from '../../components/common/ErrorMessageAlert';
import LoadingContainerShowingWrapper from '../../components/common/LoadingContainerShowingWrapper';
import LoadingShowSpinWrap from '../../components/common/LoadingShowSpinWrap';
import { useAppSelector } from '../../hooks/app';
import usePurchaseLoad from '../../hooks/purchase/usePurchaseLoad';
import { selectUserAuth } from '../../store/user';


export interface PurchaseDetailParams {
    id: string;
}


export default function PurchaseDetailPage() {

    const { id } = useParams<PurchaseDetailParams>();

    const history = useHistory();

    const userAuth = useAppSelector(selectUserAuth);

    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [deletingErrorMessage, setDeletingErrorMessage] = useState<string | undefined>();
    const purchaseResult = usePurchaseLoad(userAuth, id);
    const { purchase } = purchaseResult;

    const deleteThisPurchase = async () => {

        if (userAuth && purchaseResult.purchase?.id) {
            try {
                setIsDeleting(true);
                await deletePurchase(userAuth, purchaseResult.purchase.id);
                setIsDeleting(false);
                history.push("/purchases");
            } catch (e) {
                setDeletingErrorMessage((e as Error).message);
                setIsDeleting(false);
            }
        }
    }
    return (
        <div style={{ padding: "16px" }}>
            <LoadingContainerShowingWrapper
                isInit={purchaseResult.isInit}
                isLoading={purchaseResult.isLoading}
                errorMessage={purchaseResult.errorMessage}
                loadingComponent={<Skeleton active paragraph={{ rows: 5, }} title={{ width: "80%" }} />}
            >
                <div >
                    <AuthShow>
                        <div >
                            <Row justify="end">
                                <Button icon={<FontAwesomeIcon icon={faEdit} />} type="default" onClick={() => history.push(`/purchases/edit/${purchase!.id}`)} >編輯</Button>
                                <LoadingShowSpinWrap isLoading={isDeleting}>
                                    <Popover title="刪除此採購?" trigger="click" placement="bottomLeft" content={<Button onClick={deleteThisPurchase} >Yes</Button>} >
                                        <Button danger style={{ margin: "0 20px" }} icon={<FontAwesomeIcon icon={faTrash} />}>刪除</Button>
                                    </Popover>
                                </LoadingShowSpinWrap>
                            </Row>
                        </div>
                    </AuthShow>

                    <ErrorMessageAlert errorMessage={deletingErrorMessage} />
                    {
                        purchase &&
                        <List
                            style={{ marginTop: "16px", backgroundColor: "#ffffff" }}
                            bordered

                        >
                            <List.Item>
                                <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                    <Col>
                                        <div>期數</div>
                                    </Col>
                                    <Col>
                                        <div>
                                            {purchase?.orderNum}
                                        </div>
                                    </Col>
                                </Row>
                            </List.Item>

                            <List.Item>
                                <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                    <Col>
                                        <div>採購編號</div>
                                    </Col>
                                    <Col>
                                        <Space direction="horizontal" >
                                            <div>
                                                {purchase?.id}
                                            </div>
                                            <Button onClick={() => {
                                                navigator.clipboard.writeText(purchase!.id);
                                                message.success("複製到剪貼簿!")
                                            }} icon={<FontAwesomeIcon icon={faCopy} />} />
                                        </Space>
                                    </Col>
                                </Row>
                            </List.Item>
                            <List.Item>
                                <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                    <Col>
                                        <div>商店</div>
                                    </Col>
                                    <Col>
                                        <Link to={`/shops/${purchase?.belongShop}`}>
                                            {purchase?.belongShop?.name?? "未知商店"}
                                        </Link>
                                    </Col>
                                </Row>
                            </List.Item>

                            <List.Item>
                                <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                    <Col>
                                        <div>商品</div>
                                    </Col>
                                    <Col>
                                        <Link to={`/products/${purchase?.belongProductId}`}>
                                            {purchase?.belongProduct?.name ?? "未知商品"}
                                        </Link>
                                    </Col>
                                </Row>
                            </List.Item>

                            <List.Item>
                                <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                    <Col>
                                        <div>{`買入價 (${userAuth?.user.buyCurrency})`}</div>
                                    </Col>
                                    <Col>
                                        {purchase?.buyPrice}
                                    </Col>
                                </Row>
                            </List.Item>
                            <List.Item>
                                <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                    <Col>
                                        <div>數量</div>
                                    </Col>
                                    <Col>
                                        {purchase?.quantity}
                                    </Col>
                                </Row>
                            </List.Item>

                            <List.Item>
                                <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                    <Col>
                                        <div>{`採購金額 (${userAuth?.user.buyCurrency})`}</div>
                                    </Col>
                                    <Col>
                                        {(purchase!.quantity * purchase!.buyPrice)}
                                    </Col>
                                </Row>
                            </List.Item>

                            <List.Item>
                                <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                    <Col>
                                        <div>創建時間</div>
                                    </Col>
                                    <Col>
                                        {
                                            `${new Date(purchase?.createdAt).toLocaleString()}`
                                        }
                                    </Col>
                                </Row>
                            </List.Item>

                            <List.Item>
                                <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                    <Col>
                                        <div>更新時間</div>
                                    </Col>
                                    <Col>
                                        {
                                            `${new Date(purchase?.updatedAt).toLocaleString()}`
                                        }
                                    </Col>
                                </Row>
                            </List.Item>
                        </List>
                    }
                </div>
            </LoadingContainerShowingWrapper>
        </div>
    )
}
