import React, { useState } from 'react'
import { Form, Input, Button, Spin, Space, Avatar, Row } from 'antd';
import { loginUserAsync } from '../../store/user';
import { UserLoginDto } from '../../models/user';
import { useAppDispatch } from '../../hooks/app';
import { NavLink } from 'react-router-dom';
import ErrorMessageAlert from '../../components/common/ErrorMessageAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons';

export default function LoginPage() {

    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const dispatch = useAppDispatch()

    async function onFinish(userLogin: UserLoginDto): Promise<void> {
        setErrorMessage(undefined);
        setIsSubmitting(true);

        try {
            await dispatch(loginUserAsync(userLogin));
        } catch (e: any) {
            console.log(e.detail);
            setErrorMessage(e.message);
        }

        setIsSubmitting(false)
    }

    return (
        <div style={{ margin: "auto", textAlign: "center", padding: "40px 20px"}}>
            <div style={{ margin: "40px 0" }}>
                <Avatar size={125} style={{ backgroundColor: "#63acc7" }}><FontAwesomeIcon icon={faUnlockAlt} size={"lg"} color={"#ffffff"} style={{ margin: "auto" }} /></Avatar>
            </div>

            <div style={{ maxWidth: "100%" }}>
                <Space direction="vertical">
                    <ErrorMessageAlert errorMessage={errorMessage} />
                </Space>
            </div>

            <div style={{ maxWidth: "600px", textAlign: "center", margin: "auto" }}>
                <Form
                    name="basic"
                    labelCol={{ offset: 0, span: 6 }}
                    wrapperCol={{ offset: 0, span: 18 }}
                    labelAlign='left'
                    onFinish={onFinish}
                    autoComplete="off"
                >

                    <Form.Item
                        label="信箱"
                        name="email"
                        rules={[{ required: true, message: '請填寫您的郵箱!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="密碼"
                        name="password"
                        rules={[{ required: true, message: '請填寫密碼!' }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Row justify="end">
                            {
                                isSubmitting ?
                                    <Spin /> :
                                    <Button type="primary" htmlType="submit">
                                        登入
                                    </Button>
                            }
                        </Row>
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Row justify="end">
                            <NavLink to="/register">
                                註冊帳號
                            </NavLink>
                        </Row>
                    </Form.Item>
                </Form>

            </div>
        </div>
    )
}
