import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Form, Input, InputNumber, Row, Skeleton, Space, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { updateProduct } from '../../api/product';
import ErrorMessageAlert from '../../components/common/ErrorMessageAlert';
import LoadingContainerShowingWrapper from '../../components/common/LoadingContainerShowingWrapper';
import { useAppSelector } from '../../hooks/app';
import useProductLoad from '../../hooks/product/useProductLoad';
import { ProductUpdatingDto } from '../../models/product';
import { selectUserAuth } from '../../store/user';

export interface ProductUpdatingPageParams {
    id: string;
}


export default function ProductUpdatingPage() {

    const { id } = useParams<ProductUpdatingPageParams>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const userAuth = useAppSelector(selectUserAuth);
    const [defaultSellPrice, setDefaultSellPrice] = useState<number>(0);
    const [defaultBuyPrice, setDefaultBuyPrice] = useState<number>(0);
    const history = useHistory();
    const productResult = useProductLoad(userAuth, id);
    const { product } = productResult;

    async function onFinish(productUpdating: ProductUpdatingDto): Promise<void> {
        setErrorMessage(undefined);
        setIsSubmitting(true);

        console.log("Prodcut updating")
        console.log(productUpdating)

        if (!userAuth) {
            setErrorMessage("必須登入才可執行此操作");
            return;
        }

        if (!(product?.id)) {
            setErrorMessage("查無此商品");
            return;
        }

        try {
            const updatedProduct = await updateProduct(userAuth, product.id, productUpdating);
            history.push(`/products/${updatedProduct.id}`)

        } catch (e: any) {
            console.log(e.detail);
            setErrorMessage(e.message);
        }

        setIsSubmitting(false)
    }

    useEffect(() => {

        if (productResult.product) {
            setDefaultSellPrice(productResult.product.defaultSellPrice);
            setDefaultBuyPrice(productResult.product.defaultBuyPrice);
        }
    }, [productResult.isInit])


    return (
        <div style={{ margin: "auto", textAlign: "center", padding: "40px 20px" }}>
            <div style={{ margin: "40px 0" }}>
                <Avatar size={125} style={{ backgroundColor: "#63acc7" }}><FontAwesomeIcon icon={faBoxOpen} size={"lg"} color={"#ffffff"} style={{ margin: "auto" }} /></Avatar>
            </div>

            <div style={{ maxWidth: "100%" }}>
                <Space direction="vertical">
                    <ErrorMessageAlert errorMessage={errorMessage} />
                </Space>
            </div>
            <LoadingContainerShowingWrapper
                isInit={productResult.isInit}
                isLoading={productResult.isLoading}
                errorMessage={productResult.errorMessage}
                loadingComponent={<Skeleton active paragraph={{ rows: 5, }} title={{ width: "80%" }} />}
            >
                <div style={{ maxWidth: "600px", textAlign: "center", margin: "auto" }}>
                    <Form
                        name="basic"
                        labelCol={{ offset: 0, span: 6 }}
                        wrapperCol={{ offset: 0, span: 18 }}
                        labelAlign='left'
                        onFinish={onFinish}
                        autoComplete="off"
                    >

                        <Form.Item
                            label="商品名稱"
                            name="name"
                            initialValue={product?.name}
                            rules={[{ required: true, message: '請輸入商品名稱!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={`購買價 (${userAuth?.user.buyCurrency})`}
                            required
                        >
                            <Form.Item
                                name="defaultBuyPrice"
                                initialValue={product?.defaultBuyPrice}
                                rules={[
                                    { required: true, message: '請輸入預設購買價!' },
                                    { type: "number", message: "請輸入有效的數字!" },
                                ]}
                            >

                                <InputNumber style={{ width: "100%" }} min={0} onChange={(v) => setDefaultBuyPrice(v)} />
                            </Form.Item>
                            <div style={{ borderRadius: "16px", marginTop: "12px", padding: "6px", backgroundColor: "#ffffff", fontWeight: "bold" }}>
                                {userAuth?.user.buyToSellExchangeRate == null ? <div></div> : `${(defaultBuyPrice * userAuth?.user.buyToSellExchangeRate).toFixed(2)} ${userAuth.user.sellCurrency}`}
                            </div>
                        </Form.Item>
                        <Form.Item
                            label={`售出價 (${userAuth?.user.sellCurrency})`}
                            required
                        >
                            <Form.Item
                                name="defaultSellPrice"
                                initialValue={product?.defaultSellPrice}
                                rules={[
                                    { required: true, message: '請輸入預設售出價!' },
                                    { type: "number", message: "請輸入有效的數字!" },
                                ]}
                            >
                                <InputNumber style={{ width: "100%" }} min={0} onChange={(v) => setDefaultSellPrice(v)} />
                            </Form.Item>
                            <div style={{ borderRadius: "16px", marginTop: "12px", padding: "6px", backgroundColor: "#ffffff", fontWeight: "bold" }}>
                                {userAuth?.user.buyToSellExchangeRate == null ? <div></div> : `${(defaultSellPrice / userAuth?.user.buyToSellExchangeRate).toFixed(2)} ${userAuth.user.buyCurrency}`}
                            </div>
                        </Form.Item>


                        <Form.Item
                            label="庫存數量"
                            name="inStockNum"
                            initialValue={product?.inStockNum}
                            rules={[
                                { required: true, message: '請輸入庫存數量!' },
                                { type: "number", message: "請輸入有效的數字!" },
                            ]}
                            style={{ textAlign: "right" }}
                        >
                            <InputNumber style={{ width: "100%" }} min={0} />
                        </Form.Item>

                        <Form.Item
                            initialValue={product?.note}
                            label="備註"
                            name="note"
                            rules={[
                                {
                                    max: 1000,
                                    message: "最多字數為1000字"
                                }
                            ]}
                        >
                            <TextArea rows={5} style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item wrapperCol={{ span: 24 }}>
                            <Row justify="end">
                                {
                                    isSubmitting ?
                                        <Spin /> :
                                        <Button type="primary" htmlType="submit">
                                            更新
                                        </Button>
                                }
                            </Row>
                        </Form.Item>
                    </Form>
                </div>
            </LoadingContainerShowingWrapper>
        </div>
    )
}
