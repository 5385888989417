import axios, { AxiosResponse } from 'axios';
import { getAppRequestConfig } from './../utils/auth';
import { UserAuthDto } from './../models/user';
import { CustomerCreatingDto, CustomerOutputDto, CustomerUpdatingDto, GetCustomersResult } from './../models/customer';
import { apiUrl } from './apiHandler';
import HttpException from '../exceptions/HttpException';
import CustomerQueryParameters from '../queryParameters/CustomerQueryParameters';
import { Pagination } from '../models/pagination';


const customerApiUrl = apiUrl + "/customer"

export const createCustomer = async (userAuth: UserAuthDto, customerCreating: CustomerCreatingDto): Promise<CustomerOutputDto> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.post(customerApiUrl, customerCreating, config);

    if (res.status > 400) {
        throw new HttpException(res.status, "無法創立買家", res.data);
    }

    return res.data as CustomerOutputDto
}

export const updateCustomer = async (userAuth: UserAuthDto, customerId: string, customerUpdating: CustomerUpdatingDto): Promise<CustomerOutputDto> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.patch(`${customerApiUrl}/${customerId}`, customerUpdating, config);

    if (res.status > 400) {
        throw new HttpException(res.status, `無法更新買家: ${customerId}`, res.data);
    }

    return res.data as CustomerOutputDto
}


export const deleteCustomer = async (userAuth: UserAuthDto, customerId: string): Promise<boolean> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.delete(`${customerApiUrl}/${customerId}`, config);

    if (res.status > 400) {
        throw new HttpException(res.status, `無法刪除買家: ${customerId
            }`, res.data);
    }

    return res.data as boolean
}

export const getCustomer = async (userAuth: UserAuthDto, customerId: string): Promise<CustomerOutputDto> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.get(`${customerApiUrl}/${customerId}`, config);

    if (res.status > 400) {
        throw new HttpException(res.status, `查詢不到該買家: ${customerId}`, res.data);
    }

    return res.data as CustomerOutputDto
}


export const getCustomers = async (userAuth: UserAuthDto, queryParams: CustomerQueryParameters, pageNumber: number, pageSize: number): Promise<GetCustomersResult> => {

    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.get(`${customerApiUrl}?${queryParams.generateQuery(pageNumber, pageSize  )}`, config);

    // Pagination is in the header;

    if (res.status > 400) {
        throw new HttpException(res.status, `檢索錯誤`, res.data);
    }

    return {
        customers: res.data as CustomerOutputDto[],
        pagination: JSON.parse(res.headers['pagination']) as Pagination
    } as GetCustomersResult;

    // return res.data as GetCustomersResult;
}




