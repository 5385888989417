import React, { useEffect, useState } from 'react'
import { getPurchasesInfo } from '../../api/purchase';
import { PurchasesInfo } from '../../models/purchase'
import { UserAuthDto } from '../../models/user'
import PurchaseQueryParameters from '../../queryParameters/PurchaseQueryParameters'

export interface UsePurchasesInfoLoadReturn {
    purchasesInfo?: PurchasesInfo;
    errorMessage?: string;
    isInit: boolean;
    isLoading: boolean;
}

export default function usePurchasesInfoLoad(
    userAuth: UserAuthDto | undefined,
    queryParams: PurchaseQueryParameters,
) {
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [purchasesInfo, setPurchasesInfo] = useState<PurchasesInfo | undefined>()
    const [isInit, setIsInit] = useState<boolean>(false);

    useEffect(() => {
        setPurchasesInfo(undefined);
    }, [queryParams]);


    useEffect(() => {
        // Need the user to login to perform this.
        if (!userAuth) {
            setErrorMessage("必須先登入才可執行此操作!")
            return;
        }

        (async () => {
            try {
                setIsLoading(true);
                setErrorMessage(undefined);

                const retrievedPurchasesInfo: PurchasesInfo = await getPurchasesInfo(
                    userAuth,
                    queryParams
                );

                setPurchasesInfo(retrievedPurchasesInfo);
                setIsLoading(false);
                setIsInit(true);
            } catch (e) {
                setErrorMessage((e as Error).message);
            }
        })();

    }, [queryParams]); // should I keep it for pageNumber only listener?

    // Q: should we pass the has more to outside?
    return { isLoading, errorMessage, purchasesInfo, isInit } as UsePurchasesInfoLoadReturn;
}
