import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import UserCurrencySettingPage from './UserCurrencySettingPage';
import SettingPage from './SettingPage';

export default function SettingRoutingPage() {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <SettingPage />
            </Route>
            <Route exact path={`${path}/currency`}>
                <UserCurrencySettingPage />
            </Route>
        </Switch>
    )
}
