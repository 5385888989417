import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, List, Row } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';

export default function SettingPage() {
	const history = useHistory();

	return (
		<div style={{ margin: '16px' }}>
			<div
				style={{
					fontFamily: 'Noto Sans TC',
					fontWeight: 'bold',
					fontSize: '2em',
					textAlign: 'center',
					margin: '8px',
				}}
			>
				設定
			</div>
			<List bordered style={{ backgroundColor: '#ffffff' }}>
				<List.Item onClick={() => history.push('/settings/currency')}>
					<Row
						style={{ width: '100%' }}
						align="middle"
						justify="space-between"
					>
						<Col>
							<div>貨幣設置</div>
						</Col>
						<Col>
							<FontAwesomeIcon icon={faChevronRight} />
						</Col>
					</Row>
				</List.Item>
			</List>
		</div>
	);
}
