import { Spin } from 'antd';
import React from 'react'

export interface LoadingShowSpinWrapProps {
    isLoading?: boolean
    children: React.ReactNode
    size?: "large" | "small" | "default"
}

export default function LoadingShowSpinWrap(props: LoadingShowSpinWrapProps) {
    
    const { isLoading, children, size } = props;

    if (isLoading !== false) {
        return <Spin style={{ paddingTop: "80px" }} size={size ?? "large"}></Spin>
    }

    return (
        <>
            {children}
        </>
    )
}
