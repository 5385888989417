import { faEdit, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Collapse, List, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router';
import ErrorMessageAlert from '../../components/common/ErrorMessageAlert';
import PurchaseSearchingBar from '../../components/purchase/PurchaseSearchingBar';
import { useAppSelector } from '../../hooks/app';
import usePurchasesInfoLoad from '../../hooks/purchase/usePurchasesInfoLoad';
import usePurchasesSearching from '../../hooks/purchase/usePurchasesSearching';
import { PurchaseOutputDto } from '../../models/purchase';
import PurchaseQueryParameters, {
	PurchaseSortingOptions,
} from '../../queryParameters/PurchaseQueryParameters';
import { selectUserAuth } from '../../store/user';

const initQueryParams = new PurchaseQueryParameters({
	orderBy: { option: PurchaseSortingOptions.OrderNum, desc: true },
});

export default function PurchasesPage() {
	const location = useLocation();
	const screen = useBreakpoint();

	const locationState = location.state as PurchaseQueryParameters;

	const [queryParams, setQueryParams] = useState<PurchaseQueryParameters>(
		new PurchaseQueryParameters({
			...initQueryParams,
			...locationState,
		}),
	);

	const history = useHistory();

	const userAtuh = useAppSelector(selectUserAuth);

	const purchasesResult = usePurchasesSearching(userAtuh, queryParams);
	const purchasesInfo = usePurchasesInfoLoad(userAtuh, queryParams);

	// User need to login to see this page. (Before the userAuth has been used.)
	if (!userAtuh) {
		return <Redirect to={'/login'} />;
	}

	const tableColumns: ColumnsType<PurchaseOutputDto> = [
		{
			title: '期',
			dataIndex: 'orderNum',
			key: 'orderNum',
			render: (text: string, record: PurchaseOutputDto, idx: number) => {
				return (
					<div
						ref={
							idx + 1 === purchasesResult.purchases.length
								? purchasesResult.lastRef
								: undefined
						}
					>
						{text}
					</div>
				);
			},
			ellipsis: true,
			width: '60px',
			align: 'center',
		},

		{
			title: '商品',
			key: 'belongProduct.name',
			render: (text: string, record: PurchaseOutputDto, idx: number) => {
				return (
					<a
						onClick={() => {
							history.push({
								pathname: `/products/${record.belongProductId}`,
							});
						}}
					>
						{record.belongProduct?.name ?? '未知商品'}
					</a>
				);
			},
			ellipsis: true,
			// width: "200px",
			align: 'center',
		},
		{
			title: '商店',
			render: (text: string, record: PurchaseOutputDto, idx: number) => {
				return (
					<a
						onClick={() => {
							history.push({
								pathname: `/shops/${record.belongShopId}`,
							});
						}}
					>
						{record?.belongShop?.name ?? '未知商店'}
					</a>
				);
			},
			key: 'belongShop.name',
			ellipsis: true,
			width: '140px',
			align: 'center',
		},
		{
			title: `價格 (${userAtuh.user.buyCurrency})`,
			dataIndex: 'buyPrice',
			key: 'buyPrice',
			ellipsis: true,
			width: '120px',
			align: 'center',
		},
		{
			title: '數量',
			dataIndex: 'quantity',
			key: 'quantity',
			ellipsis: true,
			width: '80px',
			align: 'center',
		},
		{
			title: `總價 (${userAtuh.user.buyCurrency})`,
			ellipsis: true,
			width: '120px',
			align: 'center',
			render: (
				text: string,
				record: PurchaseOutputDto,
				index: number,
			) => {
				return (
					<div>{(record.buyPrice * record.quantity).toFixed(2)}</div>
				);
			},
		},
		{
			title: '詳',
			render: (text: string, record: PurchaseOutputDto, idx: number) => {
				return (
					<Button
						icon={
							<FontAwesomeIcon
								icon={faInfoCircle}
								color="#5ac8db"
							/>
						}
						onClick={() => {
							history.push(`/purchases/${record.id}`);
						}}
					/>
				);
			},
			width: '60px',
			align: 'center',
		},
	];

	return (
		<div
			style={{
				margin: 'auto',
				width: screen.md ? '95%' : '95vw',
				marginTop: '8px',
			}}
		>
			<div
				style={{
					fontFamily: 'Noto Sans TC',
					fontWeight: 'bold',
					fontSize: '2em',
					textAlign: 'center',
					margin: '8px',
				}}
			>
				採購
			</div>
			<div style={{ marginBottom: '8px' }}>
				<Button
					style={{ fontWeight: 'bold', marginBottom: '8px' }}
					onClick={() => history.push('/purchases/new')}
					icon={
						<FontAwesomeIcon
							icon={faEdit}
							style={{ paddingRight: '2px' }}
						/>
					}
				>
					創建
				</Button>
				<Row justify="space-between" align="middle">
					<div>
						<PurchaseSearchingBar
							setQueryParams={setQueryParams}
							initQueryParams={initQueryParams}
							queryParams={queryParams}
						/>
					</div>
					<div></div>
				</Row>
			</div>

			<ErrorMessageAlert errorMessage={purchasesResult.errorMessage} />
			<div>
				<Collapse style={{ marginBottom: '8px' }}>
					<Collapse.Panel header="資訊" key="1">
						<List>
							<List.Item>
								<Row
									style={{ width: '100%' }}
									justify="space-between"
									align="middle"
								>
									<Col>
										<div>
											{`總花費 (${userAtuh.user.buyCurrency})`}
										</div>
									</Col>
									<Col>
										<div>
											{purchasesInfo?.purchasesInfo?.totalSpent?.toFixed(
												2,
											)}
										</div>
									</Col>
								</Row>
							</List.Item>
							<List.Item>
								<Row
									style={{ width: '100%' }}
									justify="space-between"
									align="middle"
								>
									<Col>
										<div>
											{`總花費 (${userAtuh.user.sellCurrency})`}
										</div>
									</Col>
									<Col>
										<div>
											{(
												(purchasesInfo?.purchasesInfo
													?.totalSpent ?? 0) *
												(userAtuh?.user
													?.buyToSellExchangeRate ??
													0)
											).toFixed(2)}
										</div>
									</Col>
								</Row>
							</List.Item>
							<List.Item>
								<Row
									style={{ width: '100%' }}
									justify="space-between"
									align="middle"
								>
									<Col>
										<div>總商品數量</div>
									</Col>
									<Col>
										<div>
											{
												purchasesInfo?.purchasesInfo
													?.totalProductCount
											}
										</div>
									</Col>
								</Row>
							</List.Item>
							<List.Item>
								<Row
									style={{ width: '100%' }}
									justify="space-between"
									align="middle"
								>
									<Col>
										<div>總採購次數</div>
									</Col>
									<Col>
										<div>
											{
												purchasesInfo?.purchasesInfo
													?.totalPurchaseCount
											}
										</div>
									</Col>
								</Row>
							</List.Item>
						</List>
					</Collapse.Panel>
				</Collapse>
				<Table
					scroll={{ x: 800 }}
					columns={tableColumns}
					dataSource={purchasesResult.purchases}
					pagination={false}
					loading={!purchasesResult.isInit}
					rowKey={(o) => o.id}
				/>
			</div>
		</div>
	);
}
