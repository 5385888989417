import {
	faClipboardList,
	faEdit,
	faInfoCircle,
	faShoppingBasket,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Space, Table } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Redirect, useLocation } from 'react-router-dom';
import ProductSearchingBar from '../../components/product/ProductSearchingBar';
import { useAppSelector } from '../../hooks/app';
import useProductsSearching from '../../hooks/product/useProductsSearching';
import { ProductOutputDto } from '../../models/product';
import { PurchaseOutputDto } from '../../models/purchase';
import OrderQueryParameters, {
	OrderSortingOptions,
	OrderSortingSet,
} from '../../queryParameters/OrderQueryParameters';
import ProductQueryParameters, {
	ProductSortingOptions,
} from '../../queryParameters/ProductQueryParameters';
import PurchaseQueryParameters, {
	PurchaseSortingOptions,
	PurchaseSortingSet,
} from '../../queryParameters/PurchaseQueryParameters';
import { selectUserAuth } from '../../store/user';
import { OrderCreatingPageLocationState } from '../order/OrderCreatingPage';
import { PurchaseCreatingPageLocationState } from '../purchase/PurchaseCreatingPage';

const initQueryParams = new ProductQueryParameters({
	orderBy: { option: ProductSortingOptions.CreatedAt, desc: true },
});

export default function ProductsPage() {
	const location = useLocation();
	const screen = useBreakpoint();

	const locationState = location.state as ProductQueryParameters;

	const [queryParams, setQueryParams] = useState<ProductQueryParameters>(
		new ProductQueryParameters({
			...initQueryParams,
			...locationState,
		}),
	);

	const history = useHistory();

	const userAtuh = useAppSelector(selectUserAuth);

	const productsResult = useProductsSearching(userAtuh, queryParams);

	useEffect(() => {
		console.log(queryParams);
	}, [queryParams]);

	// User need to login to see this page. (Before the userAuth has been used.)
	if (!userAtuh) {
		return <Redirect to={'/login'} />;
	}

	const tableColumns: ColumnsType<ProductOutputDto> = [
		{
			title: '商品',
			dataIndex: 'name',
			key: 'name',
			render: (text: string, record: ProductOutputDto, idx: number) => {
				return (
					<div
						ref={
							idx + 1 === productsResult.products.length
								? productsResult.lastRef
								: undefined
						}
					>
						{/* <Link to={`/products/${record.id}`}>{text}</Link> */}
						<a
							onClick={() => {
								history.push(`/products/${record.id}`);
							}}
						>
							{text}
						</a>
					</div>
				);
			},
		},
		// {
		//     title: "詳",
		//     render: (text: string, record: ProductOutputDto, idx: number) => {
		//         return <Button
		//             icon={<FontAwesomeIcon icon={faInfoCircle} color="#5ac8db" />}
		//             onClick={() => {
		//                 history.push(`/products/${record.id}`)
		//             }}
		//         />
		//     },
		//     width: "60px",
		//     align: "center"
		// },

		{
			title: '缺額',
			dataIndex: 'needToBuyAmount',
			key: 'needToBuyAmount',
			render: (text: string, record: ProductOutputDto, idx: number) => {
				return (
					<Button
						onClick={() => {
							history.push({
								pathname: '/orders',
								state: {
									belongProductId: record.id,
									orderBy: {
										desc: true,
										option: OrderSortingOptions.Quantity,
									} as OrderSortingSet,
								} as OrderQueryParameters,
							});
						}}
					>
						{record.needToBuyAmount}
					</Button>
				);
			},
			align: 'center',
			// Can link to the orders.
			// Can link to create purchase.
		},
		{
			title: '庫存',
			key: 'inStockNum',
			render: (text: string, record: ProductOutputDto, idx: number) => {
				return (
					<Button
						onClick={() => {
							history.push({
								pathname: '/purchases',
								state: {
									belongProductId: record.id,
									orderBy: {
										desc: true,
										option: PurchaseSortingOptions.CreatedAt,
									} as PurchaseSortingSet,
								} as PurchaseQueryParameters,
							});
						}}
					>
						{record.inStockNum}
					</Button>
				);
			},
			align: 'center',
		},
		{
			title: '創建',
			key: 'action',
			render: (text: string, record: ProductOutputDto, idx: number) => {
				return (
					<Space direction="horizontal">
						<Button
							onClick={() => {
								history.push({
									pathname: '/orders/new',
									state: {
										product: record,
										nextAddress: '/products',
									} as OrderCreatingPageLocationState,
								});
							}}
							icon={<FontAwesomeIcon icon={faClipboardList} />}
						/>
						<Button
							onClick={() => {
								history.push({
									pathname: '/purchases/new',
									state: {
										product: record,
										qty:
											record.needToBuyAmount > 0
												? record.needToBuyAmount
												: 1,
										nextAddress: '/products',
									} as PurchaseCreatingPageLocationState,
								});
							}}
							icon={<FontAwesomeIcon icon={faShoppingBasket} />}
						/>
					</Space>
				);
			},
			align: 'center',
		},
	];

	return (
		<div
			style={{
				margin: 'auto',
				width: screen.md ? '95%' : '95vw',
				marginTop: '8px',
			}}
		>
			<div
				style={{
					fontFamily: 'Noto Sans TC',
					fontWeight: 'bold',
					fontSize: '2em',
					textAlign: 'center',
                    margin: "8px"
				}}
			>
				商品
			</div>
			<div style={{ marginBottom: '8px' }}>
				<Button
					style={{ fontWeight: 'bold', marginBottom: '8px' }}
					onClick={() => history.push('/products/new')}
					icon={
						<FontAwesomeIcon
							icon={faEdit}
							style={{ paddingRight: '2px' }}
						/>
					}
				>
					創建
				</Button>
				<ProductSearchingBar
					setQueryParams={setQueryParams}
					initQueryParams={initQueryParams}
					queryParams={queryParams}
				/>
			</div>

			<div>
				<Table
					scroll={{ x: 600 }}
					columns={tableColumns}
					dataSource={productsResult.products}
					pagination={false}
					loading={!productsResult.isInit}
					rowKey={(o) => o.id}
				/>
			</div>
		</div>
	);
}
