import { faEdit, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import React, { useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router';
import { useAppSelector } from '../../hooks/app';
import CustomerQueryParameters, {
	CustomerSortingOptions,
} from '../../queryParameters/CustomerQueryParameters';
import { selectUserAuth } from '../../store/user';
import CustomerSearchingBar from '../../components/customer/CustomerSearchingBar';
import Table, { ColumnsType } from 'antd/lib/table';
import { CustomerOutputDto } from '../../models/customer';
import ErrorMessageAlert from '../../components/common/ErrorMessageAlert';
import OrderQueryParameters, { OrderSortingOptions } from '../../queryParameters/OrderQueryParameters';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useCustomersSearching from '../../hooks/customer/useCustomersSearching';

const initQueryParams = new CustomerQueryParameters({
	orderBy: { option: CustomerSortingOptions.CreatedAt, desc: true },
});

export default function CustomersPage() {
	const location = useLocation();
	const screen = useBreakpoint();

	const locationState = location.state as CustomerQueryParameters;

	const [queryParams, setQueryParams] = useState<CustomerQueryParameters>(
		new CustomerQueryParameters({
			...initQueryParams,
			...locationState,
		}),
	);

	// const [isFilterModalVisible, setIsFilterModalVisible] = useState<boolean>(false);
	// const [isSortModalVisible, setIsSortModalVisible] = useState<boolean>(false);

	const history = useHistory();

	const userAuth = useAppSelector(selectUserAuth);

	const customersResult = useCustomersSearching(userAuth, queryParams);

	const tableColumns: ColumnsType<CustomerOutputDto> = [
		{
			title: '買家',
			dataIndex: 'name',
			key: 'name',
			render: (text: string, record: CustomerOutputDto, idx: number) => {
				return (
					<div
						ref={
							idx + 1 === customersResult.customers.length
								? customersResult.lastRef
								: undefined
						}
					>
						<a
							onClick={() => {
								history.push(`/customers/${record.id}`);
							}}
						>
							{text}
						</a>
					</div>
				);
			},
			align: 'center',
		},
		{
			title: `消費金額 (${userAuth?.user.sellCurrency})`,
			dataIndex: 'spent',
			key: 'spent',
			render: (text: string, record: CustomerOutputDto, idx: number) => {
				return (
					<Button
						onClick={() => {
							history.push({
								pathname: '/orders',
								state: new OrderQueryParameters({
									belongCustomerId: record?.id,
								}),
							});
						}}
					>
						{record?.spent}
					</Button>
				);
			},
			align: 'center',
		},
		{
			title: `未付金額 (${userAuth?.user.sellCurrency})`,
			dataIndex: 'unpaid',
			key: 'unpaid',
			render: (text: string, record: CustomerOutputDto, idx: number) => {
				return (
					<Button
						onClick={() => {
							history.push({
								pathname: '/orders',
								state: new OrderQueryParameters({
									belongCustomerId: record?.id,
									paid: false,
								}),
							});
						}}
					>
						{record?.unpaid}
					</Button>
				);
			},
			align: 'center',
		},
		{
			title: '訂單數量',
			dataIndex: 'orderAmount',
			key: 'orderAmount',
			render: (text: string, record: CustomerOutputDto, idx: number) => {
				return (
					<Button
						onClick={() => {
							history.push({
								pathname: '/orders',
								state: new OrderQueryParameters({
									belongCustomerId: record?.id,
								}),
							});
						}}
					>
						{record?.orderAmount}
					</Button>
				);
			},
			align: 'center',
		},
	];

	// User need to login to see this page. (Before the userAuth has been used.)
	if (!userAuth) {
		return <Redirect to={'/login'} />;
	}

	return (
		<div
			style={{
				margin: 'auto',
				width: screen.md ? '95%' : '95vw',
				marginTop: '8px',
			}}
		>
			<div
				style={{
					fontFamily: 'Noto Sans TC',
					fontWeight: 'bold',
					fontSize: '2em',
					textAlign: 'center',
					margin: '8px',
				}}
			>
				買家
			</div>
			<div style={{ marginBottom: '8px' }}>
				<Button
					style={{ fontWeight: 'bold', marginBottom: '8px' }}
					onClick={() => history.push('/customers/new')}
					icon={
						<FontAwesomeIcon
							icon={faEdit}
							style={{ paddingRight: '2px' }}
						/>
					}
				>
					創建
				</Button>
				<CustomerSearchingBar
					setQueryParams={setQueryParams}
					initQueryParams={initQueryParams}
					queryParams={queryParams}
				/>
			</div>
			<ErrorMessageAlert errorMessage={customersResult.errorMessage} />

			<div>
				<Table
					scroll={{ x: 500 }}
					columns={tableColumns}
					dataSource={customersResult.customers}
					pagination={false}
					loading={!customersResult.isInit}
					rowKey={(o) => o.id}
				/>
			</div>
		</div>
	);
}
