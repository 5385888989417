import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PurchaseCreatingPage from './PurchaseCreatingPage';
import PurchaseDetailPage from './PurchaseDetailPage';
import PurchasesPage from './PurchasesPage';
import PurchaseUpdatingPage from './PurchaseUpdatingPage';

export default function PurchaseRoutingPage() {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <PurchasesPage />
            </Route>
            <Route exact path={`${path}/new`}>
                <PurchaseCreatingPage />
            </Route>
            <Route exact path={`${path}/edit/:id`}>
                <PurchaseUpdatingPage />
            </Route>
            <Route path={`${path}/:id`}>
                <PurchaseDetailPage />
            </Route>
        </Switch>
    )
}
