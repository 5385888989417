export enum UserRole {
    Normal = "Normal",
    Admin = "Admin",
    Banned = "Banned",
    EmailNotVerified = "EmailNotVerified",
    Vip = "Admin",
}

export interface UserRegisterDto {
    email: string;
    password: string;
}

export interface UserPasswordResetDto {
    oldPassword: string;
    newPassword: string;
}

export interface UserOutputDto {
    id: string;
    userRole: UserRole;
    createdAt: Date;
    lastSeen: Date;
    sellCurrency: string;
    buyCurrency: string;
    buyToSellExchangeRate: number;
}

export interface UserPasswordForgetDto {
    email: string;
    code: string;
    newPassword: string;
}

export interface UserLoginDto {
    email: string;
    password: string;
}

export interface UserAuthDto {
    user: UserOutputDto;
    token: string;
}

export interface EmailVerifyDto {
    code: string;
}

export interface UserUpdatingDto {
    sellCurrency: string;
    buyCurrency: string;
    buyToSellExchangeRate: number;
}



