import React, { useEffect, useState } from 'react'
import { getOrder } from '../../api/order'
import { OrderOutputDto } from '../../models/order'
import { UserAuthDto } from '../../models/user'


export interface UseOrderLoadReturn {
    order?: OrderOutputDto;
    errorMessage?: string;
    isInit: boolean;
    isLoading: boolean;
    setOrder: (order: OrderOutputDto) => void;
}

export default function useOrderLoad(userAuth: UserAuthDto | undefined, id: string) {

    const [order, setOrder] = useState<OrderOutputDto | undefined>()
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [isInit, setisInit] = useState<boolean>(false)
    const [isLoading, setisLoading] = useState<boolean>()

    useEffect(() => {
        if (!userAuth) {
            setErrorMessage("必須先登入才可執行此操作!")
            return;
        }

        (async () => {
            try {
                setisLoading(true)
                let loadedShop: OrderOutputDto = await getOrder(userAuth, id);
                setOrder(loadedShop);
                setisInit(true);
                setisLoading(false);
            } catch (error) {
                setErrorMessage((error as Error).message);
            }
        })();
    }, [id])


    return { order, errorMessage, isInit, isLoading, setOrder } as UseOrderLoadReturn;
}
