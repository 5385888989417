import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Space, Form, Input, Row, Spin, Button } from 'antd'
import TextArea from 'rc-textarea'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { createCustomer } from '../../api/customer'
import ErrorMessageAlert from '../../components/common/ErrorMessageAlert'
import { useAppSelector } from '../../hooks/app'
import { CustomerCreatingDto } from '../../models/customer'
import { selectUserAuth } from '../../store/user'

export default function CustomerCreatingPage() {

    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const userAuth = useAppSelector(selectUserAuth);
    const history = useHistory();

    async function onFinish(customerCreating: CustomerCreatingDto): Promise<void> {
        setErrorMessage(undefined);
        setIsSubmitting(true);

        if (!userAuth) {
            setErrorMessage("必須登入才可執行此操作");
            return;
        }

        try {
            // Call api to create customer
            const createdCustomer = await createCustomer(userAuth, customerCreating);
            history.push(`/customers/${createdCustomer.id}`)

        } catch (e: any) {
            console.log(e.detail);
            setErrorMessage(e.message);
        }

        setIsSubmitting(false)
    }

    return (
        <div style={{ margin: "auto", textAlign: "center", padding: "40px 20px" }}>
            <div style={{ margin: "40px 0" }}>
                <Avatar size={125} style={{ backgroundColor: "#63acc7" }}><FontAwesomeIcon icon={faUser} size={"lg"} color={"#ffffff"} style={{ margin: "auto" }} /></Avatar>
            </div>

            <div style={{ maxWidth: "100%" }}>
                <Space direction="vertical">
                    <ErrorMessageAlert errorMessage={errorMessage} />
                </Space>
            </div>

            <div style={{ maxWidth: "600px", textAlign: "center", margin: "auto" }}>
                <Form
                    name="basic"
                    labelCol={{ offset: 0, span: 6 }}
                    wrapperCol={{ offset: 0, span: 18 }}
                    labelAlign='left'
                    onFinish={onFinish}
                    autoComplete="off"
                >

                    <Form.Item
                        label="姓名"
                        name="name"
                        rules={[{ required: true, message: '請填寫買家姓名!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="備註"
                        name="note"
                        rules={[
                            {
                                max: 1000,
                                message: "最多字數為1000字"
                            }
                        ]}
                    >
                        <TextArea rows={5} style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Row justify="end">
                            {
                                isSubmitting ?
                                    <Spin /> :
                                    <Button type="primary" htmlType="submit">
                                        創建
                                    </Button>
                            }
                        </Row>
                    </Form.Item>
                </Form>
            </div>

        </div>
    )
}
