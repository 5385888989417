import { PurchaseOutputDto, PurchaseUpdatingDto, PurchaseCreatingDto, PurchasesInfo, GetPurchasesResult } from './../models/purchase';
import axios, { AxiosResponse } from 'axios';
import HttpException from '../exceptions/HttpException';
import { getAppRequestConfig } from '../utils/auth';
import { UserAuthDto } from './../models/user';
import { apiUrl } from "./apiHandler";
import PurchaseQueryParameters from '../queryParameters/PurchaseQueryParameters';
import { Pagination } from '../models/pagination';


const purchaseApiUrl = apiUrl + "/purchase"


export const createPurchase = async (userAuth: UserAuthDto, purchaseCreating: PurchaseCreatingDto): Promise<PurchaseOutputDto> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.post(purchaseApiUrl, purchaseCreating, config);

    if (res.status > 400) {
        throw new HttpException(res.status, "無法創立購買", res.data);
    }

    return res.data as PurchaseOutputDto
}

export const updatePurchase = async (userAuth: UserAuthDto, purchaseId: string, purchaseUpdating: PurchaseUpdatingDto): Promise<PurchaseOutputDto> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.patch(`${purchaseApiUrl}/${purchaseId}`, purchaseUpdating, config);

    if (res.status > 400) {
        throw new HttpException(res.status, `無法更新購買: ${purchaseId}`, res.data);
    }

    return res.data as PurchaseOutputDto
}


export const deletePurchase = async (userAuth: UserAuthDto, purchaseId: string): Promise<boolean> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.delete(`${purchaseApiUrl}/${purchaseId}`, config);

    if (res.status > 400) {
        throw new HttpException(res.status, `無法刪除購買: ${purchaseId}`, res.data);
    }

    return res.data as boolean
}

export const getPurchase = async (userAuth: UserAuthDto, purchaseId: string): Promise<PurchaseOutputDto> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.get(`${purchaseApiUrl}/${purchaseId}`, config);

    if (res.status > 400) {
        throw new HttpException(res.status, `查詢不到該購買: ${purchaseId}`, res.data);
    }

    return res.data as PurchaseOutputDto
}


export const getPurchases = async (userAuth: UserAuthDto, queryParams: PurchaseQueryParameters, pageNumber: number, pageSize: number): Promise<GetPurchasesResult> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.get(`${purchaseApiUrl}?${queryParams.generateQuery(pageNumber, pageSize)}`, config);

    if (res.status > 400) {
        throw new HttpException(res.status, `檢索錯誤`, res.data);
    }

    return {
        purchases: res.data as PurchaseOutputDto[],
        pagination: JSON.parse(res.headers['pagination']) as Pagination,
    } as GetPurchasesResult;
}

export const getPurchasesInfo = async (userAuth: UserAuthDto, queryParams: PurchaseQueryParameters): Promise<PurchasesInfo> => {
    try {
        const config = getAppRequestConfig(userAuth);
        const res: AxiosResponse = await axios.get(`${purchaseApiUrl}/info?${queryParams.generateQuery()}`, config);
        return res.data as PurchasesInfo
    } catch (e: any) {
        if (e && e.response) {
            throw new HttpException(e.response.status, "無法取得採買信息", e.response.data);
        }

        throw new HttpException("UNKNOWN", "無法取得採買信息", "無細節");
    }
}