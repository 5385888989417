import { Alert } from 'antd';
import React from 'react'


export interface ErrorMessageAlertProps {
    errorMessage?: string;
}

export default function ErrorMessageAlert(props: ErrorMessageAlertProps) {
    const { errorMessage } = props;
    return (
        errorMessage ?
            (<div style={{ textAlign: "center" }}>
                <Alert
                    message={errorMessage}
                    type='error'
                    showIcon={true}
                    closable
                    style={{ width: "80%", margin: "auto" }}
                />
                <br />
                <br />
            </div>)
            : (<></>)
    )
}
