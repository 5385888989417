import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Collapse, List, Popover, Row, Skeleton } from 'antd';
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router';
import { deleteShop } from '../../api/shop';
import AuthShow from '../../components/auth/AuthShow';
import ErrorMessageAlert from '../../components/common/ErrorMessageAlert';
import LoadingContainerShowingWrapper from '../../components/common/LoadingContainerShowingWrapper';
import LoadingShowSpinWrap from '../../components/common/LoadingShowSpinWrap';
import { useAppSelector } from '../../hooks/app';
import useShopLoad from '../../hooks/shop/useShopLoad';
import { selectUserAuth } from '../../store/user';


export interface ShopDetailParams {
    id: string
}

export default function ShopDetailPage() {
    const { id } = useParams<ShopDetailParams>();

    const history = useHistory();

    const userAuth = useAppSelector(selectUserAuth);

    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [deletingErrorMessage, setDeletingErrorMessage] = useState<string | undefined>();
    const shopResult = useShopLoad(userAuth, id);
    const { shop } = shopResult;

    const deleteThisShop = async () => {

        if (userAuth && shopResult.shop?.id) {
            try {
                setIsDeleting(true);
                await deleteShop(userAuth, shopResult.shop.id);
                setIsDeleting(false);
                history.push("/shops");
            } catch (e) {
                setDeletingErrorMessage((e as Error).message);
                setIsDeleting(false);
            }
        }
    }
    return (
        <div style={{ padding: "16px" }}>
            <LoadingContainerShowingWrapper
                isInit={shopResult.isInit}
                isLoading={shopResult.isLoading}
                errorMessage={shopResult.errorMessage}
                loadingComponent={<Skeleton active paragraph={{ rows: 5, }} title={{ width: "80%" }} />}
            >
                <div >
                    <AuthShow>
                        <div >
                            <Row justify="end">
                                <Button icon={<FontAwesomeIcon icon={faEdit} />} type="default" onClick={() => history.push(`/shops/edit/${shop!.id}`)} >編輯</Button>
                                <LoadingShowSpinWrap isLoading={isDeleting}>
                                    <Popover title="刪除此店鋪?" trigger="click" placement="bottomLeft" content={<Button onClick={deleteThisShop} >Yes</Button>} >
                                        <Button danger style={{ margin: "0 20px" }} icon={<FontAwesomeIcon icon={faTrash} />}>刪除</Button>
                                    </Popover>
                                </LoadingShowSpinWrap>
                            </Row>
                        </div>
                    </AuthShow>
                    <ErrorMessageAlert errorMessage={deletingErrorMessage} />

                    <div style={{ paddingTop: "12px" }}>
                        <div style={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}>
                            {shop?.name}
                        </div>
                    </div>

                    <Collapse style={{ marginTop: "14px" }}>
                        <Collapse.Panel header="備註" key="1">
                            <p>{shop?.note}</p>
                        </Collapse.Panel>
                    </Collapse>

                    <List
                        style={{ marginTop: "16px", backgroundColor: "#ffffff" }}
                        bordered
                    >
                        <List.Item>
                            <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                <Col>
                                    <div>
                                        消費金額
                                    </div>
                                </Col>
                                <Col>
                                    <div>
                                        {shop?.spent.toFixed(2)}
                                    </div>
                                </Col>
                            </Row>
                        </List.Item>
                    </List>
                </div>
            </LoadingContainerShowingWrapper>
        </div>
    )
}
