import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Form, Input, InputNumber, Row, Space, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { createProduct } from '../../api/product';
import ErrorMessageAlert from '../../components/common/ErrorMessageAlert';
import { useAppSelector } from '../../hooks/app';
import { ProductCreatingDto } from '../../models/product';
import { selectUserAuth } from '../../store/user';

export default function ProductCreatingPage() {

    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [defaultSellPrice, setDefaultSellPrice] = useState<number>(0);
    const [defaultBuyPrice, setDefaultBuyPrice] = useState<number>(0);
    const userAuth = useAppSelector(selectUserAuth);
    const history = useHistory();

    async function onFinish(productCreating: ProductCreatingDto): Promise<void> {
        setErrorMessage(undefined);
        setIsSubmitting(true);

        if (!userAuth) {
            setErrorMessage("必須登入才可執行此操作");
            return;
        }

        productCreating.defaultSellPrice = defaultSellPrice;
        productCreating.defaultBuyPrice = defaultBuyPrice;

        try {
            // Call api to create customer
            const createdProduct = await createProduct(userAuth, productCreating);
            history.push(`/products/${createdProduct.id}`)

        } catch (e: any) {
            console.log(e.detail);
            setErrorMessage(e.message);
        }

        setIsSubmitting(false)
    }

    return (
        <div style={{ margin: "auto", textAlign: "center", padding: "40px 20px" }}>
            <div style={{ margin: "40px 0" }}>
                <Avatar size={125} style={{ backgroundColor: "#63acc7" }}><FontAwesomeIcon icon={faBoxOpen} size={"lg"} color={"#ffffff"} style={{ margin: "auto" }} /></Avatar>
            </div>

            <div style={{ maxWidth: "100%" }}>
                <Space direction="vertical">
                    <ErrorMessageAlert errorMessage={errorMessage} />
                </Space>
            </div>

            <div style={{ maxWidth: "600px", textAlign: "center", margin: "auto" }}>
                <Form
                    name="basic"
                    labelCol={{ offset: 0, span: 6 }}
                    wrapperCol={{ offset: 0, span: 18 }}
                    labelAlign='left'
                    onFinish={onFinish}
                    autoComplete="off"
                >

                    <Form.Item
                        label="商品名稱"
                        name="name"
                        rules={[{ required: true, message: '請輸入商品名稱!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={`購買價 (${userAuth?.user.buyCurrency})`}
                        required
                    >
                        <Form.Item
                            initialValue={0}
                            rules={[
                                { required: true, message: '請輸入預設購買價!' },
                                { type: "number", message: "請輸入有效的數字!" },
                            ]}
                        >
                            <InputNumber style={{ width: "100%" }} min={0} onChange={(v) => setDefaultBuyPrice(v)} />
                        </Form.Item>
                        <div style={{ borderRadius: "16px", marginTop: "12px", padding: "6px", backgroundColor: "#ffffff", fontWeight: "bold" }}>
                            {userAuth?.user.buyToSellExchangeRate == null ? <div></div> : `${(defaultBuyPrice * userAuth?.user.buyToSellExchangeRate).toFixed(2)} ${userAuth.user.sellCurrency}`}
                        </div>
                    </Form.Item>
                    <Form.Item
                        label={`售出價 (${userAuth?.user.sellCurrency})`}
                        required
                    >
                        <Form.Item
                            initialValue={0}
                            rules={[
                                { required: true, message: '請輸入預設售出價!' },
                                { type: "number", message: "請輸入有效的數字!" },
                            ]}
                        >
                            <InputNumber style={{ width: "100%" }} min={0} onChange={(v) => setDefaultSellPrice(v)} />
                        </Form.Item>
                        <div style={{ borderRadius: "16px", marginTop: "12px", padding: "6px", backgroundColor: "#ffffff", fontWeight: "bold", }}>
                            {userAuth?.user.buyToSellExchangeRate == null ? <div></div> : `${(defaultSellPrice / userAuth?.user.buyToSellExchangeRate).toFixed(2)} ${userAuth.user.buyCurrency}`}
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="庫存數量"
                        name="inStockNum"
                        initialValue={0}
                        rules={[
                            { required: true, message: '請輸入庫存數量!' },
                            { type: "number", message: "請輸入有效的數字!" },
                        ]}
                    >
                        <InputNumber style={{ width: "100%" }} min={0} />
                    </Form.Item>
                    <Form.Item
                        label="備註"
                        name="note"
                        rules={[
                            {
                                max: 1000,
                                message: "最多字數為1000字"
                            }
                        ]}
                    >
                        <TextArea rows={5} style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Row justify="end">
                            {
                                isSubmitting ?
                                    <Spin /> :
                                    <Button type="primary" htmlType="submit">
                                        創建
                                    </Button>
                            }
                        </Row>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
