import React, { useEffect, useState } from 'react'
import { getOrdersInfo } from '../../api/order'
import { OrdersInfo } from '../../models/order'
import { UserAuthDto } from '../../models/user'
import OrderQueryParameters from '../../queryParameters/OrderQueryParameters'

export interface UseOrdersInfoLoadReturn {
    ordersInfo?: OrdersInfo;
    errorMessage?: string;
    isInit: boolean;
    isLoading: boolean;
}

export default function useOrdersInfoLoad(
    userAuth: UserAuthDto | undefined,
    queryParams: OrderQueryParameters,
) {
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [ordersInfo, setOrdersInfo] = useState<OrdersInfo| undefined>()
    const [isInit, setIsInit] = useState<boolean>(false);

    useEffect(() => {
        // Need the user to login to perform this.
        setOrdersInfo(undefined);

        if (!userAuth) {
            setErrorMessage("必須先登入才可執行此操作!")
            return;
        }

        (async () => {
            try {
                setIsLoading(true);
                setErrorMessage(undefined);

                const retrievedOrdersInfo: OrdersInfo = await getOrdersInfo(
                    userAuth,
                    queryParams
                );

                setOrdersInfo(retrievedOrdersInfo);
                setIsLoading(false);
                setIsInit(true);
            } catch (e) {
                setErrorMessage((e as Error).message);
            }
        })();

    }, [queryParams]); // should I keep it for pageNumber only listener?

    // Q: should we pass the has more to outside?
    return { isLoading, errorMessage, ordersInfo, isInit } as UseOrdersInfoLoadReturn;
}
