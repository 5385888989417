import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Form, InputNumber, Row, Skeleton, Space, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { updatePurchase } from '../../api/purchase';
import ErrorMessageAlert from '../../components/common/ErrorMessageAlert';
import LoadingContainerShowingWrapper from '../../components/common/LoadingContainerShowingWrapper';
import { useAppSelector } from '../../hooks/app';
import usePurchaseLoad from '../../hooks/purchase/usePurchaseLoad';
import { PurchaseUpdatingDto } from '../../models/purchase';
import { selectUserAuth } from '../../store/user';

export interface PurchaseUpdatingPageParams {
    id: string;
}

export default function PurchaseUpdatingPage() {

    const { id } = useParams<PurchaseUpdatingPageParams>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const userAuth = useAppSelector(selectUserAuth);
    const history = useHistory();
    const purchaseResult = usePurchaseLoad(userAuth, id);
    const { purchase } = purchaseResult;

    const [buyPrice, setBuyPrice] = useState<number>(0);

    async function onFinish(updatingDto: PurchaseUpdatingDto): Promise<void> {
        setErrorMessage(undefined);
        setIsSubmitting(true);

        if (!userAuth) {
            setErrorMessage("必須登入才可執行此操作");
            return;
        }

        if (!(purchase?.id)) {
            setErrorMessage("查無此採購");
            return;
        }

        updatingDto.buyPrice = buyPrice;

        try {
            const updatedShop = await updatePurchase(userAuth, purchase.id, updatingDto);
            history.push(`/purchases/${updatedShop.id}`)

        } catch (e: any) {
            console.log(e.detail);
            setErrorMessage(e.message);
        }

        setIsSubmitting(false)
    }

    useEffect(() => {
        if (purchase) {
            setBuyPrice(purchase.buyPrice);
        }
    }, [purchaseResult.isInit])


    return (
        <div style={{ margin: "auto", textAlign: "center", padding: "40px 20px" }}>
            <div style={{ margin: "40px 0" }}>
                <Avatar size={125} style={{ backgroundColor: "#63acc7" }}><FontAwesomeIcon icon={faShoppingBasket} size={"lg"} color={"#ffffff"} style={{ margin: "auto" }} /></Avatar>
            </div>

            <div style={{ maxWidth: "100%" }}>
                <Space direction="vertical">
                    <ErrorMessageAlert errorMessage={errorMessage} />
                </Space>
            </div>
            <LoadingContainerShowingWrapper
                isInit={purchaseResult.isInit}
                isLoading={purchaseResult.isLoading}
                errorMessage={purchaseResult.errorMessage}
                loadingComponent={<Skeleton active paragraph={{ rows: 5, }} title={{ width: "80%" }} />}
            >
                <div style={{ maxWidth: "600px", textAlign: "center", margin: "auto" }}>
                    <Form
                        name="basic"
                        labelCol={{ offset: 0, span: 6 }}
                        wrapperCol={{ offset: 0, span: 18 }}
                        labelAlign='left'
                        onFinish={onFinish}
                        autoComplete="off"
                    >

                        <Form.Item
                            label="訂單 (期)"
                            name="orderNum"
                            rules={[
                                { required: true, message: '請輸入訂單期數!' },
                                { type: "number", message: "請輸入有效的數字!" },
                            ]}
                            style={{ textAlign: "right" }}
                            initialValue={purchase?.orderNum}
                        >
                            <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item
                            required
                            label="店鋪"
                        >
                            <div style={{ borderRadius: "16px", backgroundColor: "#ffffff", boxShadow: "2px 2px #eeeeee", padding: "10px" }}>
                                <Link to={`/shops/${purchase?.belongShopId}`} >
                                    {purchase?.belongShop?.name ?? "未知商店"}
                                </Link>
                            </div>
                        </Form.Item>

                        <Form.Item
                            required
                            label="商品"
                            name="belongProductId"
                        >
                            <div style={{ borderRadius: "16px", backgroundColor: "#ffffff", boxShadow: "2px 2px #eeeeee", padding: "10px" }}>
                                <Link to={`/products/${purchase?.belongProductId}`} >
                                    {purchase?.belongProduct?.name ?? "未知商品"}
                                </Link>
                            </div>
                        </Form.Item>
                        <Form.Item
                            label={`買入價 (${userAuth?.user.buyCurrency})`}
                            required
                        >
                            <Form.Item
                                rules={[{ required: true, message: '請輸入買入價格!' }]}
                            >
                                <InputNumber style={{ width: "100%" }} value={buyPrice} onChange={(v) => setBuyPrice(v)} />
                            </Form.Item>
                            <div style={{ borderRadius: "16px", marginTop: "12px", padding: "6px", backgroundColor: "#ffffff", fontWeight: "bold", boxShadow: "3px 3px #eeeeee" }}>
                                {userAuth?.user.buyToSellExchangeRate == null ? <div></div> : `${(buyPrice * userAuth?.user.buyToSellExchangeRate).toFixed(2)} ${userAuth.user.sellCurrency}`}
                            </div>
                        </Form.Item>
                        <Form.Item
                            label={`數量`}
                            name="quantity"
                            required
                            initialValue={purchase?.quantity}
                            rules={[{ required: true, message: '請輸入數量數量!' }]}
                        >
                            <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item
                            label="備註"
                            name="note"
                            rules={[
                                {
                                    max: 1000,
                                    message: "最多字數為1000字"
                                }
                            ]}
                            initialValue={purchase?.note}
                        >
                            <TextArea rows={5} style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item wrapperCol={{ span: 24 }}>
                            <Row justify="end">
                                {
                                    isSubmitting ?
                                        <Spin /> :
                                        <Button type="primary" htmlType="submit">
                                            更新
                                        </Button>
                                }
                            </Row>
                        </Form.Item>
                    </Form>
                </div>
            </LoadingContainerShowingWrapper>
        </div>
    )
}
    