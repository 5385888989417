import { UserAuthDto } from './../models/user';
import { AxiosRequestConfig } from "axios";

export const passwordRuleReg = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
);


export const getAppHeaders = (userAuth: UserAuthDto) => ({ authorization: `Bearer ${userAuth!.token}` })

export const getAppRequestConfig = (userAuth: UserAuthDto): AxiosRequestConfig => {
    const headers = getAppHeaders(userAuth)

    let config: AxiosRequestConfig = {
        headers: headers
    }


    return config;
}

