export enum ShopSortingOptions {
	CreatedAt = 'CreatedAt',
	UpdatedAt = 'UpdatedAt',
	Spent = 'Spent',
	NumProductBought = 'NumProductBought',
}

export interface ShopSortingSet {
	option: ShopSortingOptions;
	desc: boolean;
}

export default class ShopQueryParameters {
	public orderBy: ShopSortingSet = {
		option: ShopSortingOptions.CreatedAt,
		desc: true,
	};
	public searchingString?: string;
	public boughtBefore?: boolean;

	constructor(init: Partial<ShopQueryParameters>) {
		Object.assign(this, init);
	}

	getOrderString(): string {
		return `${
			this.orderBy.option != ShopSortingOptions.CreatedAt
				? 'createdAt desc,'
				: ''
		}${this.orderBy.option.toString()}${this.orderBy.desc ? ' desc' : ''}`;
	}

	generateQuery(pageNumber?: number, pageSize?: number): string {
		let query = '';

		if (pageNumber) {
			query += `&pageNumber=${pageNumber}`;
		}

		if (pageSize) {
			query += `&pageSize=${pageSize}`;
		}

		if (this.orderBy) {
			query += `&orderBy=${this.getOrderString()}`;
		}

		if (
			this.searchingString != null &&
			this.searchingString.trim() !== ''
		) {
			query += `&searchingString=${this.searchingString}`;
		}

		if (this.boughtBefore != null) {
			query += `&boughtBefore=${this.boughtBefore}`;
		}

		return query;
	}
}
