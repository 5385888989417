import { faFilter, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, Modal, Radio, Row, Select, Space } from 'antd';
import React, { useState } from 'react'
import CustomerQueryParameters, { CustomerSortingOptions } from '../../queryParameters/CustomerQueryParameters';

export interface CustomerSearchingBarProps {
    setQueryParams: React.Dispatch<React.SetStateAction<CustomerQueryParameters>>;
    initQueryParams: CustomerQueryParameters;
    queryParams: CustomerQueryParameters;
}

export default function CustomerSearchingBar(props: CustomerSearchingBarProps) {

    const { setQueryParams, queryParams, initQueryParams } = props;

    const [isFilterModalVisible, setIsFilterModalVisible] = useState<boolean>(false);
    const [isSortModalVisible, setIsSortModalVisible] = useState<boolean>(false);

    return (
        <div>
            {/* Searching bar here */}
            {/* Filtering and sorting options */}
            <Space direction="horizontal">
                <Input style={{ maxWidth: "800px", }} placeholder="Search..." onChange={(e) => {
                    setQueryParams(prev =>
                        new CustomerQueryParameters({
                            ...prev,
                            searchingString: e.target.value,
                        })
                    )
                }}></Input>
                <Button icon={<FontAwesomeIcon icon={faFilter} />} type="default" onClick={() => setIsFilterModalVisible(true)}></Button>
                <Button icon={<FontAwesomeIcon icon={faSortAmountDown} />} type="default" onClick={() => setIsSortModalVisible(true)}></Button>
            </Space>
            {/* Filtering Modal here */}
            <Modal visible={isFilterModalVisible} onOk={() => setIsFilterModalVisible(false)} onCancel={() => setIsFilterModalVisible(false)} footer={null}>
                <div style={{ padding: "40px" }}>

                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Row justify="end">
                            <Button onClick={() => {
                                setQueryParams(initQueryParams);
                            }}>清除</Button>
                        </Row>
                        <Row justify="space-between">
                            <div>
                                付款
                            </div>
                            <Select
                                style={{ width: "120px" }}
                                dropdownMatchSelectWidth
                                defaultValue={queryParams.hasUnpaid == undefined ? undefined : (queryParams.hasUnpaid ? 1 : 0)}
                                allowClear={true}
                                value={queryParams.hasUnpaid == null ? undefined : queryParams.hasUnpaid ? 1 : 0}
                                onChange={(v) =>
                                    setQueryParams((prev) =>
                                        new CustomerQueryParameters({
                                            ...prev,
                                            hasUnpaid: v == undefined ? undefined : !!v,
                                        }))
                                }
                            >
                                <Select.Option value={1}>有未付</Select.Option>
                                <Select.Option value={0}>無未付</Select.Option>
                            </Select>
                        </Row>

                        <Row justify="space-between">
                            <div>
                                送貨
                            </div>
                            <Select
                                style={{ width: "120px" }}
                                dropdownMatchSelectWidth
                                defaultValue={queryParams.hasUndelivered == undefined ? undefined : (queryParams.hasUndelivered ? 1 : 0)}
                                allowClear={true}
                                value={queryParams.hasUndelivered == null ? undefined : queryParams.hasUndelivered ? 1 : 0}
                                onChange={(v) =>
                                    setQueryParams((prev) =>
                                        new CustomerQueryParameters({
                                            ...prev,
                                            hasUndelivered: v == undefined ? undefined : !!v,
                                        }))
                                }>
                                <Select.Option value={1}>有未送</Select.Option>
                                <Select.Option value={0}>無未送</Select.Option>
                            </Select>
                        </Row>
                        <Row justify="space-between">
                            <div>
                                打包
                            </div>
                            <Select
                                style={{ width: "120px" }}
                                dropdownMatchSelectWidth
                                defaultValue={queryParams.hasUnprepared == undefined ? undefined : (queryParams.hasUnprepared ? 1 : 0)}
                                value={queryParams.hasUnprepared == null ? undefined : queryParams.hasUnprepared ? 1 : 0}
                                allowClear={true}
                                onChange={(v) => {
                                    setQueryParams((prev) =>
                                        new CustomerQueryParameters({
                                            ...prev,
                                            hasUnprepared: v === undefined ? undefined : !!v,
                                        })
                                    );
                                }}>
                                <Select.Option value={1}>有未打包</Select.Option>
                                <Select.Option value={0}>無未打包</Select.Option>
                            </Select>
                        </Row>
                    </Space>
                </div>
            </Modal>

            {/* Sorting Modal here */}
            <Modal visible={isSortModalVisible} onOk={() => setIsSortModalVisible(false)} onCancel={() => setIsSortModalVisible(false)} footer={null}>
                <div style={{ padding: "40px" }}>
                    <Row justify="space-between">
                        <div>排序</div>
                        {/* Solve the porblem of select */}
                        <Select
                            style={{ width: "120px" }}
                            value={queryParams.orderBy.desc ? 1 : 0}
                            onChange={(v) => {
                                setQueryParams((prev) => new CustomerQueryParameters({
                                    ...prev,
                                    orderBy: {
                                        ...prev.orderBy,
                                        desc: !!v
                                    }
                                }))
                            }}>
                            <Select.Option value={1}>降序</Select.Option>
                            <Select.Option value={0}>升序</Select.Option>
                        </Select>
                    </Row>
                    <Radio.Group value={queryParams.orderBy.option} onChange={(e) => {
                        setQueryParams((prev) =>
                            new CustomerQueryParameters({
                                ...prev,
                                orderBy: {
                                    ...prev.orderBy,
                                    option: e.target.value
                                }
                            })
                        )
                    }}>
                        <Space direction="vertical">
                            <Radio value={CustomerSortingOptions.CreatedAt}>創建時間</Radio>
                            <Radio value={CustomerSortingOptions.UpdatedAt}>更新時間</Radio>
                            <Radio value={CustomerSortingOptions.Profit}>利潤</Radio>
                            <Radio value={CustomerSortingOptions.Spent}>消費金額</Radio>
                            <Radio value={CustomerSortingOptions.Unpaid}>未付金額</Radio>
                        </Space>
                    </Radio.Group>
                </div>
            </Modal>
        </div>
    )
}
