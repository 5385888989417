import axios, { AxiosResponse } from 'axios';
import { getAppRequestConfig } from './../utils/auth';
import { GetProductsResult, ProductCreatingDto, ProductOutputDto, ProductUpdatingDto } from './../models/product';
import { UserAuthDto } from './../models/user';
import { apiUrl } from './apiHandler';
import HttpException from '../exceptions/HttpException';
import ProductQueryParameters from '../queryParameters/ProductQueryParameters';
import { Pagination } from '../models/pagination';

const productApiUrl = apiUrl + "/product"


export const createProduct = async (userAuth: UserAuthDto, productCreating: ProductCreatingDto): Promise<ProductOutputDto> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.post(productApiUrl, productCreating, config);

    if (res.status > 400) {
        throw new HttpException(res.status, "無法創立商品", res.data);
    }

    return res.data as ProductOutputDto
}

export const updateProduct = async (userAuth: UserAuthDto, productId: string, productUpdating: ProductUpdatingDto): Promise<ProductOutputDto> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.patch(`${productApiUrl}/${productId}`, productUpdating, config);

    if (res.status > 400) {
        throw new HttpException(res.status, `無法更新商品: ${productId}`, res.data);
    }

    return res.data as ProductOutputDto
}


export const deleteProduct = async (userAuth: UserAuthDto, productId: string): Promise<boolean> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.delete(`${productApiUrl}/${productId}`, config);

    if (res.status > 400) {
        throw new HttpException(res.status, `無法刪除商品: ${productId}`, res.data);
    }

    return res.data as boolean
}

export const getProduct = async (userAuth: UserAuthDto, productId: string): Promise<ProductOutputDto> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.get(`${productApiUrl}/${productId}`, config);

    if (res.status > 400) {
        throw new HttpException(res.status, `查詢不到該商品: ${productId}`, res.data);
    }

    return res.data as ProductOutputDto
}


export const getProducts = async (userAuth: UserAuthDto, queryParams: ProductQueryParameters, pageNumber: number, pageSize: number): Promise<GetProductsResult> => {
    const config = getAppRequestConfig(userAuth);
    const res: AxiosResponse = await axios.get(`${productApiUrl}?${queryParams.generateQuery(pageNumber, pageSize)}`, config);

    if (res.status > 400) {
        throw new HttpException(res.status, `檢索錯誤`, res.data);
    }

    return {
        products: res.data as ProductOutputDto[],
        pagination: JSON.parse(res.headers['pagination']) as Pagination
    } as GetProductsResult;
}