import { faCashRegister } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Form, Input, Row, Skeleton, Space, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router';
import { updateShop } from '../../api/shop';
import ErrorMessageAlert from '../../components/common/ErrorMessageAlert';
import LoadingContainerShowingWrapper from '../../components/common/LoadingContainerShowingWrapper';
import { useAppSelector } from '../../hooks/app';
import useShopLoad from '../../hooks/shop/useShopLoad';
import { ShopUpdatingDto } from '../../models/shop';
import { selectUserAuth } from '../../store/user';

export interface ShopUpdatingPageParams {
    id: string;
}

export default function ShopUpdatingPage() {
   
    const { id } = useParams<ShopUpdatingPageParams>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const userAuth = useAppSelector(selectUserAuth);
    const history = useHistory();
    const shopResult = useShopLoad(userAuth, id);
    const { shop } = shopResult;

    async function onFinish(shopUpdating: ShopUpdatingDto): Promise<void> {
        setErrorMessage(undefined);
        setIsSubmitting(true);

        if (!userAuth) {
            setErrorMessage("必須登入才可執行此操作");
            return;
        }

        if (!(shop?.id)) {
            setErrorMessage("查無此店鋪");
            return;
        }

        try {
            const updatedShop = await updateShop(userAuth, shop.id, shopUpdating);
            history.push(`/shops/${updatedShop.id}`)

        } catch (e: any) {
            console.log(e.detail);
            setErrorMessage(e.message);
        }

        setIsSubmitting(false)
    }



    return (
        <div style={{ margin: "auto", textAlign: "center", padding: "40px 20px" }}>
            <div style={{ margin: "40px 0" }}>
                <Avatar size={125} style={{ backgroundColor: "#63acc7" }}><FontAwesomeIcon icon={faCashRegister} size={"lg"} color={"#ffffff"} style={{ margin: "auto" }} /></Avatar>
            </div>

            <div style={{ maxWidth: "100%" }}>
                <Space direction="vertical">
                    <ErrorMessageAlert errorMessage={errorMessage} />
                </Space>
            </div>
            <LoadingContainerShowingWrapper
                isInit={shopResult.isInit}
                isLoading={shopResult.isLoading}
                errorMessage={shopResult.errorMessage}
                loadingComponent={<Skeleton active paragraph={{ rows: 5, }} title={{ width: "80%" }} />}
            >
                <div style={{ maxWidth: "600px", textAlign: "center", margin: "auto" }}>
                    <Form
                        name="basic"
                        labelCol={{ offset: 0, span: 6 }}
                        wrapperCol={{ offset: 0, span: 18 }}
                        labelAlign='left'
                        onFinish={onFinish}
                        autoComplete="off"
                    >

                        <Form.Item
                            label="店鋪名稱"
                            name="name"
                            initialValue={shop?.name}
                            rules={[{ required: true, message: '請輸入店鋪名稱!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            initialValue={shop?.note}
                            label="備註"
                            name="note"
                            rules={[
                                {
                                    max: 1000,
                                    message: "最多字數為1000字"
                                }
                            ]}
                        >
                            <TextArea rows={5} style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item wrapperCol={{ span: 24 }}>
                            <Row justify="end">
                                {
                                    isSubmitting ?
                                        <Spin /> :
                                        <Button type="primary" htmlType="submit">
                                            更新
                                        </Button>
                                }
                            </Row>
                        </Form.Item>
                    </Form>
                </div>
            </LoadingContainerShowingWrapper>
        </div>
    )
}
