import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import CustomerCreatingPage from './CustomerCreatingPage';
import CustomerDetailPage from './CustomerDetailPage';
import CustomersPage from './CustomersPage';
import CustomerUpdatingPage from './CustomerUpdatingPage';

export default function CustomerRoutingPage() {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <CustomersPage />
            </Route>
            <Route exact path={`${path}/new`}>
                <CustomerCreatingPage />
            </Route>
            <Route exact path={`${path}/edit/:id`}>
                <CustomerUpdatingPage />
            </Route>
            <Route path={`${path}/:id`}>
                <CustomerDetailPage />
            </Route>
        </Switch>
    )
}
