import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import ShopCreatingPage from './ShopCreatingPage';
import ShopDetailPage from './ShopDetailPage';
import ShopsPage from './ShopsPage';
import ShopUpdatingPage from './ShopUpdatingPage';

export default function ShopRoutingPage() {

    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <ShopsPage />
            </Route>
            <Route exact path={`${path}/new`}>
                <ShopCreatingPage />
            </Route>
            <Route exact path={`${path}/edit/:id`}>
                <ShopUpdatingPage />
            </Route>
            <Route path={`${path}/:id`}>
                <ShopDetailPage />
            </Route>
        </Switch>
    )
}
