export enum OrderSortingOptions {
	CreatedAt = 'CreatedAt',
	UpdatedAt = 'UpdatedAt',
	OrderNum = 'OrderNum',
	Quantity = 'Quantity',
	SellPrice = 'SellPrice',
	TotalPrice = 'TotalPrice',
}

export interface OrderSortingSet {
	option: OrderSortingOptions;
	desc: boolean;
}

export default class OrderQueryParameters {
	public orderBy: OrderSortingSet = {
		option: OrderSortingOptions.OrderNum,
		desc: true,
	};
	public searchingString?: string;

	public belongCustomerId?: string;
	public belongProductId?: string;

	public orderNum?: number;

	public prepared?: boolean;
	public paid?: boolean;
	public delivered?: boolean;

	constructor(init: Partial<OrderQueryParameters>) {
		Object.assign(this, init);
	}

	getOrderString(): string {
		return `${
			this.orderBy.option != OrderSortingOptions.CreatedAt
				? 'createdAt desc,'
				: ''
		}${this.orderBy.option.toString()}${this.orderBy.desc ? ' desc' : ''}`;
	}

	generateQuery(pageNumber?: number, pageSize?: number): string {
		let query = '';

		if (pageNumber) {
			query += `&pageNumber=${pageNumber}`;
		}

		if (pageSize) {
			query += `&pageSize=${pageSize}`;
		}

		if (this.orderBy) {
			query += `&orderBy=${this.getOrderString()}`;
		}

		if (
			this.searchingString != null &&
			this.searchingString.trim() !== ''
		) {
			query += `&searchingString=${this.searchingString}`;
		}

		if (this.belongCustomerId != null) {
			query += `&belongCustomerId=${this.belongCustomerId}`;
		}
		if (this.belongProductId != null) {
			query += `&belongProductId=${this.belongProductId}`;
		}

		if (this.orderNum != null) {
			query += `&orderNum=${this.orderNum}`;
		}
		if (this.prepared != null) {
			query += `&prepared=${this.prepared}`;
		}
		if (this.paid != null) {
			query += `&paid=${this.paid}`;
		}
		if (this.delivered != null) {
			query += `&delivered=${this.delivered}`;
		}

		return query;
	}
}
