import React, { useEffect, useState } from 'react'
import { getProduct } from '../../api/product'
import { ProductOutputDto } from '../../models/product'
import { UserAuthDto } from '../../models/user'

export interface UseProductLoadReturn {
    product: ProductOutputDto;
    errorMessage: string;
    isInit: boolean;
    isLoading: boolean;
}

export default function useProductLoad(userAuth: UserAuthDto | undefined, id: string) {
    const [product, setProduct] = useState<ProductOutputDto | undefined>()
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [isInit, setisInit] = useState<boolean>(false)
    const [isLoading, setisLoading] = useState<boolean>()

    useEffect(() => {
        if (!userAuth) {
            setErrorMessage("必須先登入才可執行此操作!")
            return;
        }


        (async () => {
            try {
                setisLoading(true)
                let loadedProduct: ProductOutputDto = await getProduct(userAuth, id);
                setProduct(loadedProduct);
                setisInit(true);
                setisLoading(false);
            } catch (error) {
                setErrorMessage((error as Error).message);
            }
        })();
    }, [id])

    return { product, errorMessage, isInit, isLoading } as UseProductLoadReturn;
}
