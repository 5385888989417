import React, { useState } from 'react'
import { useParams } from 'react-router'
import { useAppSelector } from '../../hooks/app'
import { selectUserAuth } from '../../store/user'
import { useHistory } from 'react-router'
import LoadingContainerShowingWrapper from '../../components/common/LoadingContainerShowingWrapper'
import { Avatar, Skeleton, Space, Form, Input, Row, Spin, Button } from 'antd'
import ErrorMessageAlert from '../../components/common/ErrorMessageAlert'
import { CustomerUpdatingDto } from '../../models/customer'
import { updateCustomer } from '../../api/customer'
import TextArea from 'rc-textarea'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import useCustomerLoad from '../../hooks/customer/useCustomerLoad'

export interface CustomerUpdatingPageParms {
    id: string;
}

export default function CustomerUpdatingPage() {

    const { id } = useParams<CustomerUpdatingPageParms>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const userAuth = useAppSelector(selectUserAuth);
    const history = useHistory();
    const customerResult = useCustomerLoad(userAuth, id);
    const { customer } = customerResult;

    async function onFinish(customerUpdating: CustomerUpdatingDto): Promise<void> {
        setErrorMessage(undefined);
        setIsSubmitting(true);

        if (!userAuth) {
            setErrorMessage("必須登入才可執行此操作");
            return;
        }

        if (!(customer?.id)) {
            setErrorMessage("查無此客戶");
            return;
        }

        try {
            // Call api to create customer
            const updatedCustomer = await updateCustomer(userAuth, customer.id, customerUpdating);
            history.push(`/customers/${updatedCustomer.id}`)

        } catch (e: any) {
            console.log(e.detail);
            setErrorMessage(e.message);
        }

        setIsSubmitting(false)
    }

    return (
        <div style={{ padding: "16px" }}>
            <div style={{ margin: "40px 0" }}>
                <Avatar size={125} style={{ backgroundColor: "#63acc7" }}><FontAwesomeIcon icon={faUser} size={"lg"} color={"#ffffff"} style={{ margin: "auto" }} /></Avatar>
            </div>

            <div style={{ maxWidth: "100%" }}>
                <Space direction="vertical">
                    <ErrorMessageAlert errorMessage={errorMessage} />
                </Space>
            </div>

            <LoadingContainerShowingWrapper
                isInit={customerResult.isInit}
                isLoading={customerResult.isLoading}
                errorMessage={customerResult.errorMessage}
                loadingComponent={<Skeleton active paragraph={{ rows: 5, }} title={{ width: "80%" }} />}
            >
                <div style={{ maxWidth: "600px", textAlign: "center", margin: "auto" }}>
                    <Form
                        name="basic"
                        labelCol={{ offset: 0, span: 6 }}
                        wrapperCol={{ offset: 0, span: 18 }}
                        labelAlign='left'
                        onFinish={onFinish}
                        autoComplete="off"
                    >

                        <Form.Item
                            initialValue={customer?.name}
                            label="姓名"
                            name="name"
                            rules={[{ required: true, message: '請填寫買家姓名!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            initialValue={customer?.note}
                            label="備註"
                            name="note"
                            rules={[
                                {
                                    max: 1000,
                                    message: "最多字數為1000字"
                                }
                            ]}
                        >
                            <TextArea rows={5} style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item wrapperCol={{ span: 24 }}>
                            <Row justify="end">
                                {
                                    isSubmitting ?
                                        <Spin /> :
                                        <Button type="primary" htmlType="submit">
                                            更新
                                        </Button>
                                }
                            </Row>
                        </Form.Item>
                    </Form>
                </div>
            </LoadingContainerShowingWrapper>
        </div>
    )
}
