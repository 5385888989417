import React, { useCallback, useRef } from 'react'

export default function useInfiniteScroll(
    incrementPageNumber: () => void,
    loading: boolean,
    hasMore: boolean
) {
    const observer = useRef<any>();
    const lastRef = useCallback(
        (node) => {
            
            if (loading) return;

            if (observer.current) {
                ((observer.current as unknown) as IntersectionObserver).disconnect();
            }

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    incrementPageNumber();
                    // setPagenNumber((prev) => prev + 1);
                }
            });
            if (node) {
                ((observer.current as unknown) as IntersectionObserver).observe(node);
            }
        },
        [hasMore, loading, incrementPageNumber]
    );

    return lastRef;
}
