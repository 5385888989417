import { faEdit, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Space } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Redirect, useLocation } from 'react-router-dom';
import ErrorMessageAlert from '../../components/common/ErrorMessageAlert';
import ShopSearchingBar from '../../components/shop/ShopSearchingBar';
import { useAppSelector } from '../../hooks/app';
import useShopsSearching from '../../hooks/shop/useShopsSearching';
import { ShopOutputDto } from '../../models/shop';
import PurchaseQueryParameters from '../../queryParameters/PurchaseQueryParameters';
import ShopQueryParameters, {
	ShopSortingOptions,
} from '../../queryParameters/ShopQueryParameters';
import { selectUserAuth } from '../../store/user';

const initQueryParams = new ShopQueryParameters({
	orderBy: { option: ShopSortingOptions.CreatedAt, desc: true },
});

export default function ShopsPage() {
	const location = useLocation();
	const screen = useBreakpoint();

	const locationState = location.state as ShopQueryParameters;

	const [queryParams, setQueryParams] = useState<ShopQueryParameters>(
		new ShopQueryParameters({
			...initQueryParams,
			...locationState,
		}),
	);

	const history = useHistory();

	const userAtuh = useAppSelector(selectUserAuth);

	const shopsResult = useShopsSearching(userAtuh, queryParams);

	// User need to login to see this page. (Before the userAuth has been used.)
	if (!userAtuh) {
		return <Redirect to={'/login'} />;
	}

	const tableColumns: ColumnsType<ShopOutputDto> = [
		{
			title: '店鋪',
			dataIndex: 'name',
			key: 'name',
			render: (text: string, record: ShopOutputDto, idx: number) => {
				return (
					<div
						ref={
							idx + 1 === shopsResult.shops.length
								? shopsResult.lastRef
								: undefined
						}
					>
						<a
							onClick={() => {
								history.push(`/shops/${record.id}`);
							}}
						>
							{text}
						</a>
					</div>
				);
			},
		},
		{
			title: '消費金額',
			dataIndex: 'spent',
			key: 'spent',
			render: (text: string, record: ShopOutputDto, idx: number) => {
				return (
					<Space>
						<Button
							onClick={() => {
								history.push({
									pathname: '/purchases',
									state: {
										belongShopId: record.id,
									} as PurchaseQueryParameters,
								});
							}}
						>
							{record.spent.toFixed(2)}
						</Button>
					</Space>
				);
			},
		},
	];

	return (
		<div
			style={{
				margin: 'auto',
				width: screen.md ? '95%' : '95vw',
				marginTop: '8px',
			}}
		>
			<div
				style={{
					fontFamily: 'Noto Sans TC',
					fontWeight: 'bold',
					fontSize: '2em',
					textAlign: 'center',
					margin: '8px',
				}}
			>
				商店
			</div>
			<div style={{ marginBottom: '8px' }}>
				<Button
					style={{ fontWeight: 'bold', marginBottom: '8px' }}
					onClick={() => history.push('/shops/new')}
					icon={
						<FontAwesomeIcon
							icon={faEdit}
							style={{ paddingRight: '2px' }}
						/>
					}
				>
					創建
				</Button>
				<ShopSearchingBar
					setQueryParams={setQueryParams}
					initQueryParams={initQueryParams}
					queryParams={queryParams}
				/>
			</div>

			<ErrorMessageAlert errorMessage={shopsResult.errorMessage} />

			<div>
				<Table
					columns={tableColumns}
					dataSource={shopsResult.shops}
					pagination={false}
					loading={!shopsResult.isInit}
					rowKey={(o) => o.id}
				/>
			</div>
		</div>
	);
}
