import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Collapse, List, Popover, Row, Skeleton } from 'antd';
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { deleteCustomer } from '../../api/customer';
import AuthShow from '../../components/auth/AuthShow';
import ErrorMessageAlert from '../../components/common/ErrorMessageAlert';
import LoadingContainerShowingWrapper from '../../components/common/LoadingContainerShowingWrapper';
import LoadingShowSpinWrap from '../../components/common/LoadingShowSpinWrap';
import { useAppSelector } from '../../hooks/app';
import useCustomerLoad from '../../hooks/customer/useCustomerLoad';
import OrderQueryParameters, { OrderSortingOptions } from '../../queryParameters/OrderQueryParameters';
import { selectUserAuth } from '../../store/user';


export interface CustomerDetailPageParams {
    id: string;
}

export default function CustomerDetailPage() {

    const { id } = useParams<CustomerDetailPageParams>();

    const history = useHistory();

    const userAuth = useAppSelector(selectUserAuth);

    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [deletingErrorMessage, setDeletingErrorMessage] = useState<string | undefined>();
    const customerResult = useCustomerLoad(userAuth, id);
    const { customer } = customerResult;

    const deleteThisCustomer = async () => {

        if (userAuth && customerResult.customer?.id) {
            try {
                setIsDeleting(true);
                await deleteCustomer(userAuth, customerResult.customer.id);
                setIsDeleting(false);
                history.push("/customers");
            } catch (e) {
                setDeletingErrorMessage((e as Error).message);
                setIsDeleting(false);
            }
        }
    }

    return (
        <div style={{ padding: "16px" }}>
            <LoadingContainerShowingWrapper
                isInit={customerResult.isInit}
                isLoading={customerResult.isLoading}
                errorMessage={customerResult.errorMessage}
                loadingComponent={<Skeleton active paragraph={{ rows: 5, }} title={{ width: "80%" }} />}
            >
                <div >
                    <AuthShow>
                        <div >
                            <Row justify="end">
                                <Button icon={<FontAwesomeIcon icon={faEdit} />} type="default" onClick={() => history.push(`/customers/edit/${customer!.id}`)} >編輯</Button>
                                <LoadingShowSpinWrap isLoading={isDeleting}>
                                    <Popover title="刪除此客戶?" trigger="click" placement="bottomLeft" content={<Button onClick={deleteThisCustomer} >Yes</Button>} >
                                        <Button danger style={{ margin: "0 20px" }} icon={<FontAwesomeIcon icon={faTrash} />}>刪除</Button>
                                    </Popover>
                                </LoadingShowSpinWrap>
                            </Row>
                        </div>
                    </AuthShow>
                    <ErrorMessageAlert errorMessage={deletingErrorMessage} />

                    <div style={{ paddingTop: "12px" }}>
                        <div style={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}>
                            {customer?.name}
                        </div>
                    </div>

                    <Collapse style={{ marginTop: "14px" }}>
                        <Collapse.Panel header="備註" key="1">
                            <p>{customer?.note}</p>
                        </Collapse.Panel>
                    </Collapse>
                    <List
                        style={{ marginTop: "16px", backgroundColor: "#ffffff" }}
                        bordered
                    >
                        <List.Item>
                            <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                <Col>
                                    <div>
                                        消費金額
                                    </div>
                                </Col>
                                <Col>
                                    <Button
                                        onClick={() => {
                                            history.push({
                                                pathname: `/orders`,
                                                state: new OrderQueryParameters({
                                                    belongCustomerId: customer?.id,
                                                })
                                            });
                                        }} >
                                        {customer?.spent}
                                    </Button>
                                </Col>
                            </Row>
                        </List.Item>
                        <List.Item>
                            <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                <Col>
                                    <div>
                                        未付金額
                                    </div>
                                </Col>
                                <Col>
                                    <Button
                                        onClick={() => {
                                            history.push({
                                                pathname: `/orders`,
                                                state: new OrderQueryParameters({
                                                    belongCustomerId: customer?.id,
                                                    paid: false,
                                                })
                                            });
                                        }} >
                                        {customer?.unpaid}
                                    </Button>
                                </Col>
                            </Row>
                        </List.Item>
                        <List.Item>
                            <Row align="middle" justify="space-between" style={{ width: "100%" }}>
                                <Col>
                                    <div>
                                        訂單數量
                                    </div>
                                </Col>
                                <Col>
                                    <Button
                                        onClick={() => {
                                            history.push({
                                                pathname: `/orders`,
                                                state: new OrderQueryParameters({
                                                    belongCustomerId: customer?.id,
                                                })
                                            });
                                        }} >
                                        {customer?.orderAmount}
                                    </Button>
                                </Col>
                            </Row>
                        </List.Item>
                    </List>

                </div>
            </LoadingContainerShowingWrapper>
        </div>

    )
}
