import React from 'react'
import { useAppSelector } from '../../hooks/app';
import { UserAuthDto, UserRole } from '../../models/user'
import { selectUserAuth } from '../../store/user';

export interface AuthShowProps {
    allowedRoles?: UserRole[];
    children: JSX.Element;
}


export default function AuthShow(props: AuthShowProps) {
    const userAuth: UserAuthDto | undefined = useAppSelector(selectUserAuth);

    if (userAuth !== null && (props.allowedRoles == null || props.allowedRoles!.includes(userAuth!.user.userRole))) {
        return <div>{props?.children}</div>;
    }

    return <div></div>;
}
